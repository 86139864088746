import {
  CSSServerProperties,
  SurveyBuilderConfig,
  SurveyBuilderVersionDocumentWithName,
} from ".";
import { CardVariant } from "../../functions/setfan";
import { CardModule, ContainerModule } from "../modules";
import { SurveyModule } from "../modules/base";

export const SurveyContainer = (
  modules: SurveyModule[],
  type: "header" | "body",
  style?: CSSServerProperties,
): ContainerModule =>
  new ContainerModule({
    modules,
    style:
      type === "header"
        ? { marginBottom: 30, maxWidth: "660px", width: "100%", ...style }
        : { maxWidth: 660, width: "100%", ...style },
  });

export const SurveyCard = ({
  modules,
  variant,
  image,
  compact,
  style,
  url,
}: {
  modules: SurveyModule[];
  image?: {
    src: string;
    align: "left" | "center";
    style?: CSSServerProperties;
  };
  variant: CardVariant;
  compact: boolean;
  style?: CSSServerProperties;
  url?: string;
}): CardModule =>
  new CardModule({
    compact,
    image,
    url,
    backgroundColor: "#FAFBFB",
    variant,
    style,
    modules,
  });

export const getPageStyle = (config: SurveyBuilderVersionDocumentWithName) => {
  switch (config.fields.layout?.content) {
    case "wave":
      return { paddingTop: 0 };
    default:
      return {};
  }
};

export const getCardStyle = (
  config: SurveyBuilderVersionDocumentWithName | SurveyBuilderConfig,
  extra?: CSSServerProperties,
): CSSServerProperties => {
  switch (config.fields.layout?.content) {
    case "angular":
      return {
        border: 0,
        borderRadius: 0,
        background: "white",
        clipPath:
          "polygon(0% 0%, 100% 0%, 100% calc(100% - 30px), calc(100% - 30px) 100%, 0 100%)",
        ...extra,
      };
    case "wave":
      return {
        padding: 0,
        border: 0,
        borderRadius: 0,
        background: "none",
        ...extra,
      };
    case "boxy":
      return {
        borderRadius: 10,
        border: "2px solid #CDCECE",
        background: "#FAFBFB",
      };
    default:
      return { ...extra };
  }
};

export const getQuestionsTitleStyle = (
  config: SurveyBuilderVersionDocumentWithName,
) => {
  switch (config.fields.layout?.content) {
    case "boxy":
      return { textAlign: "center" };
    default:
      return {};
  }
};

export const getProfilingTitleStyle = (
  config: SurveyBuilderVersionDocumentWithName,
) => {
  switch (config.fields.layout?.content) {
    case "boxy":
      return { textAlign: "center" };
    default:
      return {};
  }
};

export const getButtonStyle = (
  config: SurveyBuilderVersionDocumentWithName | SurveyBuilderConfig,
): CSSServerProperties => {
  switch (config.fields.layout?.content) {
    case "angular":
      return {
        borderRadius: 0,
        clipPath:
          "polygon(14px 0%, 100% 0%, 100% calc(100% - 14px), calc(100% - 14px) 100%, 0 100%, 0% 14px)",
      };

    case "wave":
      return {
        borderRadius: 100,
      };
    default:
      return {};
  }
};

export const getQuestions = <T extends { noBorder?: boolean }>(
  modules: T[],
  config: SurveyBuilderVersionDocumentWithName | SurveyBuilderConfig,
) => {
  switch (config.fields.layout?.content) {
    case "angular":
    case "wave":
    case "boxy":
      return modules.map((mod) => {
        mod.noBorder = true;
        return mod;
      });
    case "default":
    default:
      return modules;
  }
};
