import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, Round, Cards, Boxy, Grunge } from ".";
import { useEventConfig } from "../../../Components";
import { EventTypes } from "@max/common";

export const Templates = () => {
  const navigate = useNavigate();
  const config = useEventConfig();

  useEffect(() => {
    if (config.endedAt) {
      //@ts-ignore
      if (window?.IS_BRANDED) {
        window.location.assign(`${window.location.origin}`);
      } else {
        navigate("/", { replace: true });
      }
    }
  }, [navigate, config.endedAt]);

  switch (config.type) {
    case EventTypes.Swiper:
      return <Swiper />;
    case EventTypes.Round:
      return <Round />;
    case EventTypes.Cards:
      return <Cards />;
    case EventTypes.Grunge:
      return <Grunge />;
    case EventTypes.Boxy:
      return <Boxy />;
    default:
      return <Swiper />;
  }
};
