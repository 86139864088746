import { INVALID_MODULE, SurveyModule } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import { ProgressBuilderConfigModule } from "../creator";

export class ProgressModule extends SurveyModule {
  protected _type: ModuleType = "progress";

  validate(): this is Required<ProgressModule> {
    return true;
  }

  toBuilderConfig(): ProgressBuilderConfigModule {
    if (this.validate()) {
      return {
        id: this.id,
        type: this.type,
      };
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    return this.toBuilderConfig();
  }

  editProps() {
    return undefined;
  }
}
