import React from "react";
import styled, { css } from "styled-components";
import { SvgCheck } from "../Svgs/Check";
import { colors } from "../Theme/colors";

const CheckIcon = styled(SvgCheck)`
  height: 12px;
  width: 12px;
`;

const Container = styled.div`
  height: 14px;
  width: 14px;
  border: 1px solid ${(props) => props.theme.colors.black20};
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.5);
  ${(props: any) =>
    props.isSelected &&
    css`
      border: 1px solid white;
      background-color: ${(props) => props.theme.colors.navy};
    `};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

type Props = {
  isSelected: boolean;
  icon?: any;
};
export const CheckboxInput = ({ isSelected, icon = null, ...props }: Props) => {
  return (
    <Container {...{ isSelected, ...props }} tabIndex={0}>
      {isSelected &&
        (icon || (
          <IconContainer>
            <CheckIcon />
          </IconContainer>
        ))}
    </Container>
  );
};

const IconContainer = styled.div`
  text-align: center;
  color: #fff;
  svg {
    fill: #fff;
  }
`;
