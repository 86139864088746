import { INVALID_MODULE, SurveyModule, SurveyModuleProps } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import { isValidUrl } from "./util";
import { CardVariant } from "../../functions/setfan";
import { CSSServerProperties, CardBuilderConfigModule } from "../creator";

type CardModuleProps = SurveyModuleProps & {
  backgroundColor?: string;
  compact?: boolean;
  image?: Image;
  modules?: SurveyModule[];
  variant: CardVariant;
  url?: string;
};

export class CardModule extends SurveyModule {
  protected _type: ModuleType = "card";

  backgroundColor: string;
  compact: boolean;
  image?: Image;
  modules: SurveyModule[];
  private _url?: string;
  variant: CardVariant;

  constructor({
    backgroundColor = "#FAFBFB",
    compact = false,
    image,
    modules = [],
    variant,
    url,
    ...rest
  }: CardModuleProps) {
    super(rest);
    this.backgroundColor = backgroundColor;
    this.compact = compact;
    this.modules = modules;
    this.variant = variant;

    if (url) {
      if (!isValidUrl) {
        throw new Error(`invalid url given to card module constructor: ${url}`);
      }
      this._url = url;
    }

    if (image) {
      this.image = image;
    }
  }

  get url(): string | undefined {
    return this._url;
  }

  set url(url) {
    if (!!url && !isValidUrl(url)) {
      throw new Error(`invalid url given to card module: ${url}`);
    }
    this._url = url;
  }

  validate(): this is Required<CardModule> {
    return true;
  }

  toBuilderConfig(): CardBuilderConfigModule {
    if (this.validate()) {
      const builderConfigModule: CardBuilderConfigModule = {
        compact: this.compact,
        id: this.id,
        modules: this.modules.map((mod) => mod.toBuilderConfig()),
        variant: this.variant,
        type: this.type,
      };

      if (this.image) {
        builderConfigModule.image = this.image;
      }

      if (this.url) {
        builderConfigModule.url = this.url;
      }

      return builderConfigModule;
    }
    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    if (this.validate()) {
      const surveyConfigModule: SurveyConfigModule = {
        actionRequired: this.actionRequired,
        compact: this.compact,
        id: this.id,
        modules: this.modules.flatMap((mod) => mod.toSurveyConfig()),
        variant: this.variant,
        type: this.type,
        style: this.style,
      };

      if (this.image) {
        surveyConfigModule.image = this.image;
      }

      if (this.url) {
        surveyConfigModule.url = this.url;
      }

      return surveyConfigModule;
    }
    throw new Error(INVALID_MODULE(this));
  }

  editProps() {
    return undefined;
  }
}

type Image = {
  src: string;
  align: "left" | "center";
  style?: CSSServerProperties;
};
