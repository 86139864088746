import {
  VisibilityRuleState,
  VisibilityRuleTime,
  VisibilityRules,
} from "@max/common";
import { DataState } from "@max/common/user";
import { useDataContext } from "../Components";

export function isStateRule(
  rule: VisibilityRules,
): rule is VisibilityRuleState {
  return (rule as VisibilityRuleState).states !== undefined;
}

export function isTimeRule(rule: VisibilityRules): rule is VisibilityRuleTime {
  return (rule as VisibilityRuleTime).time !== undefined;
}

export const useIsVisible = (rule: VisibilityRules | undefined) => {
  const { data } = useDataContext();
  return isVisible(rule, data);
};

export const isVisible = (
  rule: VisibilityRules | undefined,
  data: DataState,
) => {
  if (rule === undefined) {
    return true;
  }
  if (isStateRule(rule)) {
    const included = rule.states.includes(data.status);
    return rule.hide ? !included : included;
  }
  if (isTimeRule(rule)) {
    //todo: time impl
    return rule.hide;
  }
  return true;
};
