import { CustomRegister, LanguageCode, Languages } from ".";
import { FormField } from "../event";

export interface BrandCustomizations {
  questions: Questions;
}

export type Question = {
  id: string;
  internalLabel: string;
  defaultRequired: boolean;
  defaultSortOrder: number;
  type: string;
  en: Partial<FormField>;
  required?: boolean;
} & { [K in LanguageCode]?: Partial<FormField> };

export type Questions = Record<string, Question>;
type UpdatedFormField = FormField & { defaultSortOrder: number };
type Overrides = { [questionId: string]: Partial<FormField> };

const getBoolean = (
  maybeBoolean?: boolean,
  defaultBoolean = false,
): boolean => {
  if (typeof maybeBoolean === "boolean") {
    return maybeBoolean;
  }
  return defaultBoolean;
};

export const generateCustomFields = (
  questions: Questions,
  overrides?: Overrides,
): CustomRegister => {
  let updatedQuestions: CustomRegister = { en: {} };
  for (const lang in { ...Languages, en: "" }) {
    const customQuestions: UpdatedFormField[] = [];
    for (const [questionId, value] of Object.entries(questions)) {
      let langValues = value.en;
      if (lang in value) {
        const val = value[lang as LanguageCode];
        if (val) langValues = val;
      }
      customQuestions.push({
        id:
          value.id ||
          value.internalLabel
            .toLowerCase()
            .replace(/ +/g, "-")
            .replace(/[^a-z0-9\-]/g, ""),
        type: value.type,
        required: getBoolean(
          overrides?.[questionId].required,
          value.defaultRequired,
        ),
        defaultSortOrder: value.defaultSortOrder,
        ...langValues,
      } as UpdatedFormField);
    }
    customQuestions.sort((a, b) => a.defaultSortOrder - b.defaultSortOrder);
    updatedQuestions = {
      ...updatedQuestions,
      [lang]: { customFields: customQuestions },
    };
  }

  return updatedQuestions;
};
