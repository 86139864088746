import { INVALID_MODULE, InputModule, InputModuleProps } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import { addRequiredValidation } from "./util";
import { TextQuestionBuilderConfigModule } from "../creator";

type TextQuestionProps = InputModuleProps & {
  multiline?: boolean;
};
export class TextQuestionModule extends InputModule {
  protected _type: ModuleType = "TextQuestion";
  multiline: boolean;

  constructor({ multiline = false, ...rest }: TextQuestionProps) {
    super(rest);

    this.multiline = multiline;
  }

  validate(): this is Required<TextQuestionModule> {
    return true;
  }

  toBuilderConfig(): TextQuestionBuilderConfigModule {
    if (this.validate()) {
      return {
        actionRequired: this.actionRequired,
        id: this.id,
        label: this.label,
        multiline: this.multiline,
        noBorder: this.noBorder,
        type: this.type,
        required: this.required,
      };
    }
    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    const { required, label, ...base } = this.toBuilderConfig();
    const surveyConfigModule: SurveyConfigModule = {
      ...base,
      actionRequired: this.actionRequired,
      header: { children: label[this.languageCode] ?? "" },
    };
    if (required) {
      surveyConfigModule.validation = addRequiredValidation();
    }
    return surveyConfigModule;
  }

  editProps() {
    return {
      id: this.id,
      type: this.type,
      question: this.label[this.languageCode] ?? "",
      actionRequired: this.actionRequired,
    };
  }
}
