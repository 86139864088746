import styled, { createGlobalStyle, css } from "styled-components";
import {
  CalendarIcon,
  LogoKnockout,
  SocialIcons,
  useEventConfig,
} from "../../../../Components";
import { Cards } from "./Components";
import { useEffect } from "react";
import { Theme, theme as mTheme } from "../../../../melodies-source/Theme";
import { Footer } from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import { HelpIconModal } from "../../../../Components/HelpModal";
import { useTranslation } from "react-i18next";

export const Round = () => {
  const config = useEventConfig();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const theme = {
    ...mTheme,
    fonts: {
      ...mTheme.fonts,
      families: ["Lato:wght@600"],
    },
  };

  const templateConfig = {
    primary: config?.colors?.light || "#CACAE8",
    secondary: config?.colors?.dark || "#2E2E6B",
    text: "#ffffff",
    fontFamily: "'Poppins', sans-serif",
    borderRadius: "30px",
  };

  const GlobalStyle = createGlobalStyle`
  #event-root-round {
    --template-text: ${templateConfig.text};
    --template-primary: ${templateConfig.primary};
    --template-secondary: ${templateConfig.secondary};
    --template-font-family: ${templateConfig.fontFamily};
    --template-border-radius: ${templateConfig.borderRadius};
    --max-action: ${templateConfig.secondary};
  }
  body {
    background: var(--template-primary);
  }
`;

  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", templateConfig.primary);
  }, [templateConfig.primary]);

  return (
    //@ts-ignore
    <Theme theme={theme}>
      <GlobalStyle />
      <Container>
        <ThemeColorWrap>
          <HeaderWrapper>
            <Header
              url={config?.header?.image}
              count={config?.header?.artistName?.length}
            >
              <HeaderContainer>
                <LogoKnockout />
                <Text>
                  <span
                    style={{ display: "inline-block" }}
                    onClick={() => config?.handleNavigateHome()}
                  >
                    {t("Exit Show")}
                  </span>
                  <HelpIconModal style={{ marginLeft: 12 }} />
                </Text>
              </HeaderContainer>
              <DateContainer>
                <NameContainer>
                  <Text $name={true}>{config?.header?.artistName}</Text>
                  <Text>{config?.header?.venue}</Text>
                </NameContainer>
                <CalendarIcon date={config.header.date} />
              </DateContainer>
            </Header>
          </HeaderWrapper>
          <Content>
            {!!config?.cards && <Cards cards={config?.cards} />}
          </Content>
          <FooterContainer>
            <SocialIcons />
            <Footer style={{ color: "#ababab" }} />
          </FooterContainer>
        </ThemeColorWrap>
      </Container>
    </Theme>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  color: #fff;
  padding: 10px;
  background: var(--template-primary);
`;
const ThemeColorWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
  flex-grow: 1;
`;
const Content = styled.div`
  display: flex;
  flex-grow: 1;

  background-color: #000;
  z-index: 10;
  position: relative;
  margin-top: -1px;
`;

const HeaderWrapper = styled.div`
  padding-bottom: 100%;
  position: relative;
`;

const Header = styled.div<{ url?: string; count?: number }>`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: space-between;
  font-family: var(--template-font-family);
  border-radius: 24px 24px 0 0;

  ${(p) =>
    !!p.count && p.count > 18
      ? css`
          background-image: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0.4) 0%,
              rgba(0, 0, 0, 0.15) 10%,
              rgba(0, 0, 0, 0) 21%,
              rgba(0, 0, 0, 0) 60%,
              rgba(0, 0, 0, 0.1) 62%,
              rgba(0, 0, 0, 0.25) 64%,
              rgba(0, 0, 0, 1) 100%
            ),
            url(${p.url});
        `
      : css`
          background-image: linear-gradient(
              180deg,
              rgba(0, 0, 0, 0.4) 0%,
              rgba(0, 0, 0, 0.15) 10%,
              rgba(0, 0, 0, 0) 21%,
              rgba(0, 0, 0, 0) 74%,
              rgba(0, 0, 0, 1) 100%
            ),
            url(${p.url});
        `};
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div<{ $name?: boolean }>`
  color: var(--template-text);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  text-shadow: 1px 1px 3px #666;
  z-index: 10;
  font-family: var(--template-font-family);
  ${({ $name }) =>
    $name &&
    `
    font-size: 23px;
    margin-bottom: 5px;
  `};
  svg {
    margin-left: 10px;
  }
`;
const DateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 5px;
`;
const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-left: 5px;
`;
const FooterContainer = styled.div`
  padding-top: 16px;
  background-color: #000;
  border-radius: 0 0 24px 24px;
  margin-top: -1px;
`;
