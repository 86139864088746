import { TextureContent } from ".";

const TEXTURES: Record<TextureContent, string> = {
  none: "",
  distressed: "Distressed.jpg",
  lights: "Lights.jpg",
  watercolor: "Watercolor.jpg",
  waves: "Waves.jpg",
  paper: "Paper.jpg",
  water: "Water.jpg",
  geometric: "Geometric.jpg",
};

const getUrl = (file: string) =>
  new URL(
    `setfan/assets/${file}`,
    (
      process.env.REACT_APP_ASSETS_PATH ||
      process.env.ASSETS_URL_BASE ||
      ""
    ).replace(/([^\/])$/, "$1/"),
  ).toString();

/**
 * Get a fully qualified texture URL.  If the appropriate env variable cannot
 * be found this call with throw an exception.
 */
export const getTextureUrl = (texture: TextureContent): string =>
  texture === "none" ? TEXTURES[texture] : getUrl(TEXTURES[texture]);
