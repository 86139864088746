import parse from "html-react-parser";
import {
  CardIcon,
  currencyFormatter,
  useActionContext,
  useDataContext,
  useIsVisible,
  useScript,
  useScrollGradient,
  useUserActionTrackingContext,
} from "../../../../../Components";
import styled, { css } from "styled-components";
import { Button as ButtonBase } from "../../../../../melodies-source/Button";
import { Merch } from ".";
import { Body1, Body2, H2 } from "../../../../../melodies-source/Text";
import { SvgForward } from "../../../../../melodies-source/Svgs/Forward";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardType } from "@max/common";

export const Card = ({
  visible,
  headline,
  subtitle,
  icon,
  customIcon,
  body,
  cta,
  ctalabel,
  background,
  color,
  image,
  merch,
  songs,
  script,
  html,
  label,
}: CardType): JSX.Element | null => {
  const { handleAction } = useActionContext();
  const { setValue, total } = useDataContext();

  const isVisible = useIsVisible(visible);
  const [srollRef, handleScroll, hasScroll, atBottom, atTop] =
    useScrollGradient();
  const { track } = useUserActionTrackingContext();
  const { t } = useTranslation();

  const labelString = label?.custom || label?.auto || "";

  useScript(script);

  useEffect(() => {
    if (isVisible) {
      track({
        event: "card",
        context: { label: labelString },
      });
    }
  }, [isVisible, labelString, track]);

  if (!isVisible) {
    return null;
  }

  if (html) {
    return <>{parse(html)}</>;
  }

  if (merch) {
    return (
      <Container>
        <Merch merch={merch} />
      </Container>
    );
  }

  const isOutlined = ["social"].includes(cta || "");
  const hasSongScroll = !atBottom && hasScroll;
  return (
    <Box style={{ background, color }}>
      {(icon || customIcon) && (
        <Icon>
          <CardIcon icon={icon} customIcon={customIcon} />
        </Icon>
      )}
      {headline && (
        <Header hasBody={!!body || !!subtitle} count={headline.length}>
          {headline}
        </Header>
      )}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {body && <Body count={body.length}>{body}</Body>}
      {image && <Image style={{ marginBottom: "20px" }} src={image} />}
      {!!songs?.length && (
        <>
          {!atTop && <TopGradient />}
          <ScrollContainer ref={srollRef} onScroll={handleScroll}>
            {songs.map((song) => (
              <Song>
                <Container>
                  <Body1>{song.title}</Body1>
                  {song.album && <Body2>{song.album}</Body2>}
                </Container>
                <Button
                  onClick={() => {
                    setValue({ songId: song.id });
                    handleAction("register");
                  }}
                >
                  {t("Vote")}
                </Button>
              </Song>
            ))}
          </ScrollContainer>
        </>
      )}
      {!!hasSongScroll && !!songs?.length && (
        <ScrollMore atBottom={atBottom}>
          Scroll
          <SvgForward />
        </ScrollMore>
      )}
      {!!hasSongScroll && !!songs?.length && (
        <BottomGradient atBottom={atBottom} />
      )}
      {cta && ctalabel && (
        <Button outlined={isOutlined} onClick={() => handleAction(cta)}>
          {ctalabel}
        </Button>
      )}
      {cta === "payment" && !!total && (
        <Donate>
          {t("You have donated")} <Amount> {currencyFormatter(total)}</Amount>
        </Donate>
      )}
    </Box>
  );
};

const Subtitle = styled(Body1)`
  font-family: var(--template-font-family);
  color: var(--template-text);
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  margin: -12px 24px 12px;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 16px 4px 20px;
  margin: 0 4px -32px 0;
  overflow: hidden;
  overflow-y: auto;
  max-height: 280px;
  scrollbar-width: thin;
  scrollbar-color: #ffffff40 transparent;
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ffffff40;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const ScrollMore = styled.div<{ atBottom?: boolean }>`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  border: 1px solid var(--template-secondary);
  border-radius: 8px;
  height: 16px;
  position: absolute;
  padding: 0px 3px 0 5px;
  bottom: 4px;
  transition: opacity ease-out 0.2s;
  opacity: ${(p) => (p.atBottom ? 0 : 0.65)};
  z-index: 1;
  pointer-events: none;
  font-size: 8px;
  line-height: 13px;
  font-family: "Poppins";
  text-transform: uppercase;
  color: var(--template-secondary);
  font-weight: 600;
  svg {
    color: var(--template-secondary);
    flex-shrink: 0;
    width: 12px;
    height: 12px;
    margin-left: 1px;
    transform: rotateZ(90deg);
  }
`;

const TopGradient = styled.div`
  height: 10px;
  display: flex;
  margin-bottom: -10px;
  position: relative;
  z-index: 10;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const BottomGradient = styled.div<{ atBottom?: boolean }>`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 56px;
  transition: opacity ease-out 0.2s;
  opacity: ${(p) => (p.atBottom ? 0 : 1)};
  pointer-events: none;
  z-index: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

const Song = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  padding: 12px 0;
  & + & {
    border-top: 2px solid var(--template-secondary);
  }
  ${Body1}, ${Body2} {
    color: #ffffff;
    font-family: var(--template-font-family);
  }
  ${Body1} {
    font-weight: 700;
  }
  ${Body2} {
    font-weight: 300;
  }
  button {
    height: auto;
    min-width: auto;
    padding: 5px 16px;
    font-size: 12px;
    line-height: 18px;
    color: var(--template-primary);
    margin-left: 8px;
  }
`;

const Icon = styled.div`
  display: flex;
  align-self: center;
  flex: 0 0 32px;
  color: var(--template-text);
  margin-bottom: 12px;
  svg {
    width: 32px;
    height: 32px;
  }
`;

const Image = styled.img`
  height: 120px;
  flex-shrink: 0;
  width: auto;
  object-fit: contain;
  overflow: hidden;
  align-self: center;
`;

const Box = styled(Container)`
  background: var(--template-primary);
  border-radius: 12px;
  overflow: hidden;
  margin: 0 20px;
  padding-top: 20px;
  padding-bottom: 32px;
  flex: 1 1 auto;
  min-height: 0;
  position: relative;
  z-index: 0;
`;

const Button = styled(ButtonBase)<{ outlined?: boolean }>`
  background: var(--template-secondary);
  align-self: center;
  color: var(--template-primary);
  font-family: var(--template-font-family);
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
  border: none;
  border-radius: 8px;
  width: auto;
  min-width: 160px;
  &:hover {
    background: var(--template-secondary);
    color: var(--template-primary);
  }

  ${(p) =>
    p.outlined &&
    css`
      background: transparent;
      border-width: 2px;
      border-style: solid;
      border-color: var(--template-secondary);
      color: var(--template-secondary);
      font-family: var(--template-font-family);
    `};
`;

const Header = styled(H2)<{ hasBody: boolean; count?: number }>`
  color: #ffffff;
  margin: 0 24px;
  text-align: center;
  color: var(--template-text);
  font-family: var(--template-font-family);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  align-self: center;
  margin-bottom: 16px;
  ${(p) =>
    p.count &&
    p.count > 40 &&
    css`
      font-size: 20px;
      line-height: 24px;
    `};
  ${({ hasBody }) =>
    !hasBody &&
    css`
      margin-bottom: 24px;
    `}
`;

const Body = styled(Body1)<{ count?: number }>`
  color: var(--template-text);
  margin: 0 24px 24px;
  text-align: center;
  font-family: var(--template-font-family);
  align-self: center;
  font-size: 18px;
  line-height: 26px;
`;
const Donate = styled.div`
  text-align: center;
  font-size: 18px;
  padding: 5px 0 10px;
`;

const Amount = styled.div`
  text-align: center;
  display: inline-block;
  font-size: 18px;
  padding-top: 10px;
`;
