import { SurveyModule, SurveyModuleProps } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import { SocialMediaBuilderConfigModule } from "../creator";

type SocialMediaProps = SurveyModuleProps & {
  // the color of social anchor tags
  color?: string;
  facebook?: string;
  instagram?: string;
  snapchat?: string;
  tiktok?: string;
  twitter?: string;
};
export class SocialMediaModule extends SurveyModule {
  protected _type: ModuleType = "socialmedia";
  color?: string;
  facebook?: string;
  instagram?: string;
  snapchat?: string;
  tiktok?: string;
  twitter?: string;

  constructor({
    color,
    facebook,
    instagram,
    snapchat,
    tiktok,
    twitter,
    ...rest
  }: SocialMediaProps) {
    super(rest);

    this.color = color;
    this.facebook = facebook;
    this.instagram = instagram;
    this.snapchat = snapchat;
    this.tiktok = tiktok;
    this.twitter = twitter;
  }

  validate(): boolean {
    return true;
  }

  toBuilderConfig(): SocialMediaBuilderConfigModule {
    return { ...this };
  }

  toSurveyConfig(): SurveyConfigModule {
    return { ...this };
  }

  editProps() {
    return undefined;
  }
}
