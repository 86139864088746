import { serverTimestamp } from "firebase/firestore";
import {
  SurveyConfig,
  SurveyConfigModule,
  Config,
  defaultProfiling,
  ProfilingCategoryId,
  DefaultProfilingCategory,
} from "../fanApp";
import {
  ContainerModule,
  HTMLModule,
  ImageModule,
  PageModule,
  ProfilingModule,
  SubmitModule,
  TextModule,
} from "../modules";
import { SurveyModule } from "../modules/base";
import { builderToClass } from "../modules/resolve";
import {
  SurveyCard,
  SurveyContainer,
  getButtonStyle,
  getCardStyle,
  getPageStyle,
  getProfilingTitleStyle,
  getQuestions,
  getQuestionsTitleStyle,
} from "./helpers";
import {
  CATEGORIES_OPTIONS_IDS,
  CSSServerProperties,
  LayoutContent,
  SurveyBuilderOptIn,
  SurveyBuilderVersionDocumentWithName,
  TextureContent,
} from "./types";
import { getTextureUrl } from "./textures";
import { buildSurveyConfigOptIns } from "./optIns";

export function generateSurveyConfig(
  builderConfig: SurveyBuilderVersionDocumentWithName,
): SurveyConfig {
  const config = buildConfig({
    actionColor: builderConfig.fields.buttonColor.content,
    backgroundColor: builderConfig.fields.backgroundColor?.content,
    backgroundImage: builderConfig.fields.backgroundImage?.content.en,
    optIns: builderConfig.optIns ?? [],
    profilingBlocks: parseInt(builderConfig.fields.numberOfCategories.content),
    layout: builderConfig.fields.layout?.content,
    texture: builderConfig.fields.texture?.content,
  });

  const surveyConfig: SurveyConfig = {
    artistGroupId: builderConfig.artistGroupId,
    artistName: builderConfig.artistName,
    config,
    createdAt: builderConfig.createdAt ?? serverTimestamp(),
    disclaimers: builderConfig.disclaimers ?? [],
    meta: [
      { title: { value: builderConfig.fields.header.content.en } },
      {
        meta: {
          props: {
            property: "og:image",
            content: builderConfig.fields.image.content.en,
          },
        },
      },
    ],
    optIns: buildSurveyConfigOptIns(builderConfig),
    pages: buildSurveyPages(builderConfig),
    profiling: buildProfilingOverrides(builderConfig),
    profilingExclusions: CATEGORIES_OPTIONS_IDS.filter(
      (displayedCategory) =>
        !builderConfig.fields.displayedCategories.content.includes(
          displayedCategory,
        ),
    ).map((categoryKey) => `profilecategory.${categoryKey}`),
  };

  if (builderConfig.virtualOptIns) {
    surveyConfig.virtualOptIns = builderConfig.virtualOptIns;
  }

  return surveyConfig;
}

const buildConfig = ({
  actionColor,
  backgroundColor,
  backgroundImage,
  optIns,
  profilingBlocks,
  layout,
  texture,
}: {
  actionColor: string;
  backgroundColor?: string;
  backgroundImage?: string;
  optIns?: SurveyBuilderOptIn[];
  profilingBlocks?: number;
  layout?: LayoutContent;
  texture?: TextureContent;
}): Config => {
  let globalStyles = `
  body {
    ${
      backgroundImage
        ? `background-image: url("${backgroundImage}");backdrop-filter: blur(8px);`
        : ""
    }
    ${
      !backgroundImage
        ? `background-color: ${layout === "wave" ? "white" : backgroundColor};`
        : ""
    }
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    color: var(--max-text);
    min-height: 100vh;
  }
  #root {
    margin: 0 33px;

    @media screen and (max-width: 599px) {
      margin: 0 21px;
    }

    display: flex;
    flex-direction: column;
    min-height: 100vh;

    a {
      font-family: "Poppins";
      color: inherit;
    }

    a:visited {
      color: inherit;
    }
  }
  :root {
    --max-action: ${actionColor};
    --main-color: ${actionColor};
    --max-text: #333;
  }
`;

  if (texture && layout !== "wave") {
    const textureUrl = getTextureUrl(texture);

    if (textureUrl) {
      globalStyles += `
        body {
          background-image: url("${textureUrl}");
          background-blend-mode: multiply;
        }
      `;
    }
  }

  if (layout === "wave") {
    globalStyles += `#root > div:first-child > div:first-child {
      position: absolute;
      left: 50%;
      top: 0;
      width: 100%;
      max-width: calc(660px + 21px + 21px);
      transform: translate(-50%, 0);
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
      padding-left: 20px;
      padding-right: 20px;
      margin: 0;
    }`;

    globalStyles += `#root > div:first-child > div:first-child svg rect {
      fill: white !important;
    }`;
  }

  const config: Config = {
    globalStyles,
  };
  if (backgroundImage) {
    config.backgroundImage = backgroundImage;
  }
  if (backgroundColor) {
    config.backgroundColor = backgroundColor;
  }
  if (optIns) {
    config.optIns = optIns;
  }
  if (profilingBlocks) {
    config.profilingBlocks = profilingBlocks;
  }

  return config;
};

const buildSurveyPages = (
  builderConfig: SurveyBuilderVersionDocumentWithName,
): SurveyConfigModule[] => {
  const questionsPage = buildQuestionsPage(builderConfig);
  const profilingPages = buildProfilingPages(builderConfig);
  const endPage = buildEndPage(builderConfig);

  return [questionsPage, ...profilingPages, endPage];
};

const buildWaveHero = (config: SurveyBuilderVersionDocumentWithName) => {
  let textureStyles: CSSServerProperties = {};

  if (config.fields.texture.content) {
    const textureUrl = getTextureUrl(config.fields.texture.content);

    if (textureUrl) {
      textureStyles = {
        backgroundImage: `url(${textureUrl})`,
        backgroundBlendMode: "multiply",
      };
    }
  }

  return new ContainerModule({
    modules: [
      new ImageModule({
        src: config.fields.image.content,
        style: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
          clipPath:
            "polygon(100% 0%, 0% 0% , 0.00% 96.03%, 2.00% 96.27%, 4.00% 96.47%, 6.00% 96.62%, 8.00% 96.74%, 10.00% 96.80%, 12.00% 96.83%, 14.00% 96.81%, 16.00% 96.74%, 18.00% 96.63%, 20.00% 96.48%, 22.00% 96.29%, 24.00% 96.05%, 26.00% 95.78%, 28.00% 95.45%, 30.00% 95.09%, 32.00% 94.68%, 34.00% 94.23%, 36.00% 93.74%, 38.00% 93.21%, 40.00% 92.64%, 42.00% 92.03%, 44.00% 91.38%, 46.00% 90.69%, 48.00% 89.97%, 50.00% 89.20%, 52.00% 88.40%, 54.00% 87.56%, 56.00% 86.68%, 58.00% 85.78%, 60.00% 84.83%, 62.00% 83.85%, 64.00% 82.84%, 66.00% 81.80%, 68.00% 80.73%, 70.00% 79.63%, 72.00% 78.49%, 74.00% 77.33%, 76.00% 76.15%, 78.00% 74.93%, 80.00% 73.69%, 82.00% 72.43%, 84.00% 71.15%, 86.00% 69.84%, 88.00% 68.51%, 90.00% 67.16%, 92.00% 65.79%, 94.00% 64.41%, 96.00% 63.01%, 98.00% 61.59%, 100.00% 60.16%)",
        },
      }),
    ],
    style: {
      width: "calc(100% + 21px + 21px)",
      aspectRatio: "26/21",
      backgroundColor: config.fields.backgroundColor?.content,
      clipPath:
        "polygon(100% 0%, 0% 0% , 0.00% 96.54%, 2.00% 96.97%, 4.00% 97.38%, 6.00% 97.75%, 8.00% 98.08%, 10.00% 98.39%, 12.00% 98.66%, 14.00% 98.90%, 16.00% 99.11%, 18.00% 99.29%, 20.00% 99.43%, 22.00% 99.55%, 24.00% 99.62%, 26.00% 99.67%, 28.00% 99.68%, 30.00% 99.66%, 32.00% 99.61%, 34.00% 99.52%, 36.00% 99.40%, 38.00% 99.25%, 40.00% 99.07%, 42.00% 98.85%, 44.00% 98.60%, 46.00% 98.32%, 48.00% 98.01%, 50.00% 97.67%, 52.00% 97.29%, 54.00% 96.88%, 56.00% 96.44%, 58.00% 95.97%, 60.00% 95.47%, 62.00% 94.94%, 64.00% 94.38%, 66.00% 93.79%, 68.00% 93.17%, 70.00% 92.52%, 72.00% 91.85%, 74.00% 91.14%, 76.00% 90.41%, 78.00% 89.65%, 80.00% 88.87%, 82.00% 88.06%, 84.00% 87.22%, 86.00% 86.36%, 88.00% 85.47%, 90.00% 84.56%, 92.00% 83.63%, 94.00% 82.67%, 96.00% 81.69%, 98.00% 80.69%, 100.00% 79.67%)",
      zIndex: -1,
      marginLeft: -21,
      marginBottom: 30,
      ...textureStyles,
    },
  });
};

const buildQuestionsPage = (
  builderConfig: SurveyBuilderVersionDocumentWithName,
): SurveyConfigModule => {
  const surveyModules = getQuestions(
    builderConfig.surveyQuestions.map(builderToClass),
    builderConfig,
  );

  const titleTextModule = new TextModule({
    label: builderConfig.fields.header.content,
    variants: { mobile: "Headline3", desktop: "Headline2" },
    tag: "title",
    style: getQuestionsTitleStyle(builderConfig),
  });

  const bodyTextModule = new TextModule({
    label: builderConfig.fields.body.content,
    variants: { mobile: "Body1", desktop: "Body1" },
    tag: "introText",
  });

  let rulesLinkComponent: ContainerModule | undefined = undefined;
  if (builderConfig.fields.isContest.content) {
    // we set the contestCustomRulesUrl to the default if no custom rules are given so this will
    // always be the "correct" rules
    const rules = builderConfig.fields.contestCustomRulesUrl.content.en || "";
    rulesLinkComponent = new ContainerModule({
      style: { width: "100%" },
      modules: [
        new HTMLModule({
          label: { en: contestRulesLink(rules) },
        }),
      ],
    });
  }

  const headerCardModules: SurveyModule[] = [
    titleTextModule,
    new ContainerModule({ style: { height: 14 } }),
    bodyTextModule,
  ];

  if (rulesLinkComponent) {
    headerCardModules.push(rulesLinkComponent);
  }

  const profilingModule = new ProfilingModule({
    index: 0,
    force: "profilecategory.email",
  });

  const submitModule = new SubmitModule({
    label: { en: "Continue" },
    noBorder: true,
    style: getButtonStyle(builderConfig),
  });

  // Angular Layout
  if (builderConfig.fields.layout?.content === "angular") {
    const surveyHeaderCard = SurveyContainer(
      [
        new ImageModule({
          src: builderConfig.fields.image.content,
        }),
        SurveyCard({
          compact: false,
          variant: "body",
          style: getCardStyle(builderConfig),
          modules: headerCardModules,
        }),
      ],
      "header",
    );

    const surveyBodyCard = SurveyContainer(
      [
        SurveyCard({
          compact: false,
          variant: "body",
          style: getCardStyle(builderConfig),
          modules: [...surveyModules, profilingModule, submitModule],
        }),
      ],
      "body",
    );

    return new PageModule({
      compact: true,
      style: getPageStyle(builderConfig),
      modules: [surveyHeaderCard, surveyBodyCard],
    }).toSurveyConfig();
  }

  // Wave Layout
  if (builderConfig.fields.layout?.content === "wave") {
    headerCardModules.push(
      new ContainerModule({
        style: { height: 1, marginTop: 30, background: "#CDCDCD" },
      }),
    );

    const surveyHeaderCard = SurveyContainer(
      [
        buildWaveHero(builderConfig),
        SurveyCard({
          compact: false,
          variant: "body",
          style: getCardStyle(builderConfig),
          modules: headerCardModules,
        }),
      ],
      "header",
    );

    const surveyBodyCard = SurveyContainer(
      [
        SurveyCard({
          compact: false,
          variant: "body",
          style: getCardStyle(builderConfig),
          modules: [...surveyModules, profilingModule, submitModule],
        }),
      ],
      "body",
    );

    return new PageModule({
      compact: true,
      style: getPageStyle(builderConfig),
      modules: [surveyHeaderCard, surveyBodyCard],
    }).toSurveyConfig();
  }

  // Boxy Layout
  if (builderConfig.fields.layout?.content === "boxy") {
    const surveyHeaderCard = SurveyContainer(
      [
        SurveyCard({
          compact: false,
          variant: "header",
          style: getCardStyle(builderConfig),
          modules: headerCardModules,
          image: {
            src: builderConfig.fields.image.content.en,
            align: "center",
            style: { borderRadius: 10, border: 0 },
          },
        }),
      ],
      "header",
    );

    const surveyBodyCard = SurveyContainer(
      [
        SurveyCard({
          compact: false,
          variant: "body",
          style: getCardStyle(builderConfig),
          modules: [...surveyModules, profilingModule, submitModule],
        }),
      ],
      "body",
    );

    return new PageModule({
      style: getPageStyle(builderConfig),
      modules: [surveyHeaderCard, surveyBodyCard],
    }).toSurveyConfig();
  }

  // Default Layout
  const surveyHeaderCard = SurveyContainer(
    [
      SurveyCard({
        compact: false,
        variant: "header",
        modules: headerCardModules,
        image: { src: builderConfig.fields.image.content.en, align: "left" },
      }),
    ],
    "header",
  );

  const surveyBodyCard = SurveyContainer(
    [
      SurveyCard({
        compact: false,
        variant: "body",
        modules: [...surveyModules, profilingModule, submitModule],
      }),
    ],
    "body",
  );

  const questionsPage = new PageModule({
    modules: [surveyHeaderCard, surveyBodyCard],
  });

  return questionsPage.toSurveyConfig();
};

const buildProfilingPages = (
  builderConfig: SurveyBuilderVersionDocumentWithName,
): SurveyConfigModule[] => {
  const profilingPages: PageModule[] = [];
  // profiling modules are paginated, 2 per page. break them up into pages and let the
  // useSurvey hook in set-fan remove modules/empty pages
  // NOTE: the email profiling block on page 1 is index 0. start at index 1 for remainder
  const numProfilingBlocks = parseInt(
    builderConfig.fields.numberOfCategories.content,
  );
  for (let i = 0; i < numProfilingBlocks; i += 2) {
    let profilingModules = [];
    // handle if last page has a single profiling block
    const finalPage = numProfilingBlocks - i <= 1;
    if (finalPage) {
      profilingModules.push(new ProfilingModule({ index: i + 1 }));
    } else {
      profilingModules.push(new ProfilingModule({ index: i + 1 }));
      profilingModules.push(new ProfilingModule({ index: i + 2 }));
    }

    const titleTextModule = new TextModule({
      label: { en: builderConfig.fields.header.content.en },
      tag: "title",
      variants:
        builderConfig.fields.header.content.en.length < 25
          ? { mobile: "Headline3", desktop: "Headline2" }
          : { mobile: "Headline4", desktop: "Headline3" },
      style: getProfilingTitleStyle(builderConfig),
    });

    const submitModule = new SubmitModule({
      complete: finalPage,
      label: { en: finalPage ? "Submit" : "Continue" },
      style: getButtonStyle(builderConfig),
    });

    profilingModules = getQuestions(profilingModules, builderConfig);

    // Angular Layout
    if (builderConfig.fields.layout?.content === "angular") {
      profilingPages.push(
        new PageModule({
          compact: true,
          style: getPageStyle(builderConfig),
          modules: [
            SurveyContainer(
              [
                SurveyCard({
                  compact: false,
                  variant: "body",
                  style: getCardStyle(builderConfig, {
                    padding: 0,
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                  }),
                  modules: [
                    new ImageModule({
                      src: builderConfig.fields.image.content,
                      style: { width: 64, height: 64, objectFit: "cover" },
                    }),
                    titleTextModule,
                  ],
                }),
              ],
              "header",
            ),
            SurveyContainer(
              [
                SurveyCard({
                  compact: false,
                  variant: "body",
                  style: getCardStyle(builderConfig),
                  modules: [...profilingModules, submitModule],
                }),
              ],
              "body",
            ),
          ],
        }),
      );
    } else if (builderConfig.fields.layout?.content === "wave") {
      const hero = new ContainerModule({
        modules: [],
        style: {
          width: "calc(100% + 21px + 21px)",
          height: 86,
          background: builderConfig.fields.backgroundColor?.content,
          clipPath:
            "polygon(100% 0%, 0% 0% , 0.00% 69.40%, 2.00% 71.70%, 4.00% 73.92%, 6.00% 76.05%, 8.00% 78.09%, 10.00% 80.05%, 12.00% 81.91%, 14.00% 83.68%, 16.00% 85.36%, 18.00% 86.94%, 20.00% 88.43%, 22.00% 89.82%, 24.00% 91.11%, 26.00% 92.31%, 28.00% 93.41%, 30.00% 94.41%, 32.00% 95.30%, 34.00% 96.10%, 36.00% 96.80%, 38.00% 97.39%, 40.00% 97.89%, 42.00% 98.28%, 44.00% 98.56%, 46.00% 98.75%, 48.00% 98.83%, 50.00% 98.81%, 52.00% 98.69%, 54.00% 98.46%, 56.00% 98.14%, 58.00% 97.71%, 60.00% 97.17%, 62.00% 96.54%, 64.00% 95.80%, 66.00% 94.97%, 68.00% 94.03%, 70.00% 92.99%, 72.00% 91.86%, 74.00% 90.62%, 76.00% 89.29%, 78.00% 87.86%, 80.00% 86.34%, 82.00% 84.72%, 84.00% 83.00%, 86.00% 81.20%, 88.00% 79.30%, 90.00% 77.31%, 92.00% 75.23%, 94.00% 73.07%, 96.00% 70.82%, 98.00% 68.48%, 100.00% 66.06%)",
          zIndex: -1,
          marginLeft: -21,
          marginBottom: 30,
        },
      });

      profilingPages.push(
        new PageModule({
          compact: true,
          style: getPageStyle(builderConfig),
          modules: [
            SurveyContainer(
              [
                hero,
                SurveyCard({
                  compact: false,
                  variant: "body",
                  style: getCardStyle(builderConfig),
                  modules: [titleTextModule],
                }),
              ],
              "header",
            ),
            SurveyContainer(
              [
                SurveyCard({
                  compact: false,
                  variant: "body",
                  style: getCardStyle(builderConfig),
                  modules: [...profilingModules, submitModule],
                }),
              ],
              "body",
            ),
          ],
        }),
      );
    } else if (builderConfig.fields.layout?.content === "boxy") {
      profilingPages.push(
        new PageModule({
          style: getPageStyle(builderConfig),
          modules: [
            SurveyContainer(
              [
                SurveyCard({
                  compact: false,
                  variant: "header",
                  style: getCardStyle(builderConfig),
                  modules: [titleTextModule],
                  image: {
                    src: builderConfig.fields.image.content.en,
                    align: "center",
                    style: { borderRadius: 10, border: 0 },
                  },
                }),
              ],
              "header",
            ),
            SurveyContainer(
              [
                SurveyCard({
                  compact: false,
                  variant: "body",
                  style: getCardStyle(builderConfig),
                  modules: [...profilingModules, submitModule],
                }),
              ],
              "body",
            ),
          ],
        }),
      );
    } else {
      profilingPages.push(
        new PageModule({
          modules: [
            SurveyContainer(
              [
                SurveyCard({
                  variant: "header",
                  modules: [titleTextModule],
                  image: {
                    src: builderConfig.fields.image.content.en,
                    align: "left",
                  },
                  compact: true,
                }),
              ],
              "header",
            ),
            SurveyContainer(
              [
                SurveyCard({
                  compact: false,
                  variant: "body",
                  modules: [...profilingModules, submitModule],
                }),
              ],
              "body",
            ),
          ],
          compact: true,
        }),
      );
    }
  }

  return profilingPages.map((page) => page.toSurveyConfig());
};

const buildEndPage = (
  builderConfig: SurveyBuilderVersionDocumentWithName,
): SurveyConfigModule => {
  const titleTextModule = new TextModule({
    label: builderConfig.fields.thankYouHeader.content,
    variants: { mobile: "Headline3", desktop: "Headline2" },
    tag: "closingTitle",
    style: { textAlign: "center" },
  });

  const bodyTextModule = new TextModule({
    label: builderConfig.fields.thankYouBody.content,
    variants: { mobile: "Body1", desktop: "Body1" },
    tag: "closingBody",
    style: { textAlign: "center" },
  });

  const ctaModules = builderConfig.fields.hasFeaturedCta?.content
    ? [
        new ContainerModule({ style: { height: 14 } }),
        new ContainerModule({
          style: {
            height: 1,
            width: "100%",
            background: "#CDCDCD",
          },
        }),
        new ContainerModule({ style: { height: 14 } }),
        new TextModule({
          label: builderConfig.fields.featuredCtaHeaderText?.content,
          variants: { mobile: "Headline4", desktop: "Headline3" },
          style: { textAlign: "center" },
        }),
        new ContainerModule({ style: { height: 14 } }),
        new SubmitModule({
          label: builderConfig.fields.featuredCtaButtonText?.content,
          href: builderConfig.fields.featuredCtaUrl?.content,
          style: getButtonStyle(builderConfig),
        }),
      ]
    : [];

  if (builderConfig.fields.layout?.content === "angular") {
    return new PageModule({
      compact: true,
      style: getPageStyle(builderConfig),
      modules: [
        SurveyContainer(
          [
            SurveyCard({
              compact: false,
              variant: "body",
              style: getCardStyle(builderConfig),
              modules: [
                new ImageModule({
                  src: builderConfig.fields.image.content,
                  style: { margin: "-20px -20px 20px -20px" },
                }),
                titleTextModule,
                bodyTextModule,
                ...ctaModules,
              ],
            }),
          ],
          "header",
        ),
      ],
    }).toSurveyConfig();
  }

  if (builderConfig.fields.layout?.content === "wave") {
    return new PageModule({
      compact: true,
      style: getPageStyle(builderConfig),
      modules: [
        SurveyContainer(
          [
            SurveyCard({
              compact: false,
              variant: "body",
              style: getCardStyle(builderConfig),
              modules: [
                buildWaveHero(builderConfig),
                titleTextModule,
                bodyTextModule,
                ...ctaModules,
              ],
            }),
          ],
          "header",
        ),
      ],
    }).toSurveyConfig();
  }

  if (builderConfig.fields.layout?.content === "boxy") {
    return new PageModule({
      style: getPageStyle(builderConfig),
      modules: [
        SurveyContainer(
          [
            SurveyCard({
              compact: false,
              variant: "header",
              style: getCardStyle(builderConfig),
              modules: [titleTextModule, bodyTextModule, ...ctaModules],
              image: {
                src: builderConfig.fields.image.content.en,
                align: "center",
                style: { borderRadius: 10, border: 0 },
              },
            }),
          ],
          "header",
        ),
      ],
    }).toSurveyConfig();
  }

  const finalPage = new PageModule({
    modules: [
      SurveyContainer(
        [
          SurveyCard({
            compact: false,
            variant: "header",
            image: {
              src: builderConfig.fields.image.content.en,
              align: "center",
            },
            style: { alignItems: "center" },
            modules: [
              titleTextModule,
              new ContainerModule({ style: { height: 14 } }),
              bodyTextModule,
              ...ctaModules,
            ],
          }),
        ],
        "header",
      ),
    ],
  });
  return finalPage.toSurveyConfig();
};

const buildProfilingOverrides = (
  builderConfig: SurveyBuilderVersionDocumentWithName,
): DefaultProfilingCategory[] => {
  const profilingOverrides: DefaultProfilingCategory[] = [];

  CATEGORIES_OPTIONS_IDS.forEach((categoryOptionId) => {
    const categoryId: ProfilingCategoryId = `profilecategory.${categoryOptionId}`;
    const prefaceText =
      builderConfig.fields[`${categoryOptionId}IntroText`].content.en;

    // could be optimized to only loop through defaultProfiling once but
    // the difference is minimal with the size of defaultProfiling and this
    // is easier to read (imo)
    if (prefaceText) {
      const categoryDefaults = (() => {
        let matchedCategory: DefaultProfilingCategory | undefined;
        defaultProfiling.forEach((profilingGroup) => {
          const matched = profilingGroup.find(
            (profilingCategory) => profilingCategory.id === categoryId,
          );
          if (matched) {
            matchedCategory = matched;
          }
        });
        return matchedCategory;
      })();

      if (!categoryDefaults) {
        throw new Error(
          `could not retrieve category defaults for ${categoryId}`,
        );
      }

      const preface = new TextModule({
        variants: { mobile: "Headline4", desktop: "Headline3" },
        label: { en: prefaceText },
      }).toSurveyConfig();
      categoryDefaults.preface = [
        {
          type: "text",
          style: preface.style || {},
          content: preface.content || "",
        },
      ];

      profilingOverrides.push(categoryDefaults);
    }
  });

  return [...profilingOverrides, ...buildPhoneNumberOverrides(builderConfig)];
};

const buildPhoneNumberOverrides = (
  builderConfig: SurveyBuilderVersionDocumentWithName,
): DefaultProfilingCategory[] => {
  const phoneNumberOverrides: DefaultProfilingCategory[] = [];

  if (builderConfig.fields.phoneNumberValidation?.content === "optional") {
    phoneNumberOverrides.push({
      id: "profilecategory.core",
      questions: [
        {
          type: "NameQuestion",
          id: "name",
          header: { children: "Please enter your name" },
          validation: {
            type: "name",
            message: "Please enter your name",
          },
        },
        {
          type: "CountryQuestion",
          id: "countryCode",
          header: { children: "Your Country" },
          validation: {
            type: "required",
            message: "Please enter your country",
          },
        },
        {
          type: "PhoneQuestion",
          id: "phone",
          variant: "no-country",
          header: { children: "Your Phone (optional)" },
        },
        {
          type: "ZipQuestion",
          id: "zip",
          validation: {
            type: "zip",
            message: "Please enter your zip code",
          },
        },
      ],
    });
  } else if (
    builderConfig.fields.phoneNumberValidation?.content === "no-phone"
  ) {
    phoneNumberOverrides.push({
      id: "profilecategory.core",
      questions: [
        {
          type: "NameQuestion",
          id: "name",
          header: { children: "Please enter your name" },
          validation: {
            type: "name",
            message: "Please enter your name",
          },
        },
        {
          type: "CountryQuestion",
          id: "countryCode",
          header: { children: "Your Country" },
          validation: {
            type: "required",
            message: "Please enter your country",
          },
        },
        {
          type: "ZipQuestion",
          id: "zip",
          validation: {
            type: "zip",
            message: "Please enter your zip code",
          },
        },
      ],
    });
  }

  return phoneNumberOverrides;
};

const parseUrl = (url: string) =>
  url.replace(/^(https?:\/\/)?/i, (a) => a || "https://");

const contestRulesLink = (url: string) =>
  `<div style="display:flex;align-items:center;margin-top:15px;">
    <a style="color:#1B0076;font-size:15px;line-height:20px;font-family:Poppins;font-weight:600;text-decoration:none;margin-right:2px;"
     href=${parseUrl(
       url,
     )} target="__blank" onclick="(function(e){ e.stopPropagation();})(arguments[0]);">
     View Sweepstakes Rules
    </a>
    <svg width="17" height="16" viewBox="0 0 15 14" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.39456 2.14062V3.53634H4.30099V10.5149H11.2796V8.42134H12.6753V11.2128C12.6753 11.5982 12.3628 11.9106 11.9774 11.9106H3.60313C3.21771 11.9106 2.90527 11.5982 2.90527 11.2128V2.83848C2.90527 2.45307 3.21771 2.14062 3.60313 2.14062H6.39456ZM12.675 2.14062V6.32777H11.2793L11.2792 4.52311L8.28346 7.51908L7.29654 6.53216L10.2917 3.53634H8.48786V2.14062H12.675Z" fill="#1B0076"/>
    </svg>
    </div>`;
