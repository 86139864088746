import { CountryCode } from "libphonenumber-js";

export type Country = {
  id: string;
  value: CountryCode;
  text: string;
  image: string;
};

export const countryList = [
  {
    id: "us",
    value: "US",
    text: "United States",
  },
  {
    id: "ca",
    value: "CA",
    text: "Canada",
  },
  {
    id: "mx",
    value: "MX",
    text: "Mexico",
  },
  {
    id: "gb",
    value: "GB",
    text: "United Kingdom",
  },
  {
    id: "af",
    value: "AF",
    text: "Afghanistan",
  },
  {
    id: "ax",
    value: "AX",
    text: "Aland Islands",
  },
  {
    id: "al",
    value: "AL",
    text: "Albania",
  },
  {
    id: "dz",
    value: "DZ",
    text: "Algeria",
  },
  {
    id: "as",
    value: "AS",
    text: "American Samoa",
  },
  {
    id: "ad",
    value: "AD",
    text: "Andorra",
  },
  {
    id: "ao",
    value: "AO",
    text: "Angola",
  },
  {
    id: "ai",
    value: "AI",
    text: "Anguilla",
  },
  {
    id: "ag",
    value: "AG",
    text: "Antigua",
  },
  {
    id: "ar",
    value: "AR",
    text: "Argentina",
  },
  {
    id: "am",
    value: "AM",
    text: "Armenia",
  },
  {
    id: "aw",
    value: "AW",
    text: "Aruba",
  },
  {
    id: "au",
    value: "AU",
    text: "Australia",
  },
  {
    id: "at",
    value: "AT",
    text: "Austria",
  },
  {
    id: "az",
    value: "AZ",
    text: "Azerbaijan",
  },
  {
    id: "bs",
    value: "BS",
    text: "Bahamas",
  },
  {
    id: "bh",
    value: "BH",
    text: "Bahrain",
  },
  {
    id: "bd",
    value: "BD",
    text: "Bangladesh",
  },
  {
    id: "bb",
    value: "BB",
    text: "Barbados",
  },
  {
    id: "by",
    value: "BY",
    text: "Belarus",
  },
  {
    id: "be",
    value: "BE",
    text: "Belgium",
  },
  {
    id: "bz",
    value: "BZ",
    text: "Belize",
  },
  {
    id: "bj",
    value: "BJ",
    text: "Benin",
  },
  {
    id: "bm",
    value: "BM",
    text: "Bermuda",
  },
  {
    id: "bt",
    value: "BT",
    text: "Bhutan",
  },
  {
    id: "bo",
    value: "BO",
    text: "Bolivia",
  },
  {
    id: "ba",
    value: "BA",
    text: "Bosnia",
  },
  {
    id: "bw",
    value: "BW",
    text: "Botswana",
  },
  {
    id: "br",
    value: "BR",
    text: "Brazil",
  },
  {
    id: "vg",
    value: "VG",
    text: "British Virgin Islands",
  },
  {
    id: "bn",
    value: "BN",
    text: "Brunei",
  },
  {
    id: "bg",
    value: "BG",
    text: "Bulgaria",
  },
  {
    id: "bf",
    value: "BF",
    text: "Burkina Faso",
  },
  {
    id: "mm",
    value: "MM",
    text: "Burma",
  },
  {
    id: "bi",
    value: "BI",
    text: "Burundi",
  },
  {
    id: "tc",
    value: "TC",
    text: "Turks and Caicos Islands",
  },
  {
    id: "kh",
    value: "KH",
    text: "Cambodia",
  },
  {
    id: "cm",
    value: "CM",
    text: "Cameroon",
  },
  {
    id: "cv",
    value: "CV",
    text: "Cape Verde",
  },
  {
    id: "ky",
    value: "KY",
    text: "Cayman Islands",
  },
  {
    id: "cf",
    value: "CF",
    text: "Central African Republic",
  },
  {
    id: "td",
    value: "TD",
    text: "Chad",
  },
  {
    id: "cl",
    value: "CL",
    text: "Chile",
  },
  {
    id: "cn",
    value: "CN",
    text: "China",
  },
  {
    id: "cx",
    value: "CX",
    text: "Christmas Island",
  },
  {
    id: "cc",
    value: "CC",
    text: "Cocos Islands",
  },
  {
    id: "co",
    value: "CO",
    text: "Colombia",
  },
  {
    id: "km",
    value: "KM",
    text: "Comoros",
  },
  {
    id: "cd",
    value: "CD",
    text: "Congo",
  },
  {
    id: "ck",
    value: "CK",
    text: "Cook Islands",
  },
  {
    id: "cr",
    value: "CR",
    text: "Costa Rica",
  },
  {
    id: "ci",
    value: "CI",
    text: "Cote Divoire",
  },
  {
    id: "hr",
    value: "HR",
    text: "Croatia",
  },
  {
    id: "cu",
    value: "CU",
    text: "Cuba",
  },
  {
    id: "cy",
    value: "CY",
    text: "Cyprus",
  },
  {
    id: "cz",
    value: "CZ",
    text: "Czech Republic",
  },
  {
    id: "dk",
    value: "DK",
    text: "Denmark",
  },
  {
    id: "dj",
    value: "DJ",
    text: "Djibouti",
  },
  {
    id: "dm",
    value: "DM",
    text: "Dominica",
  },
  {
    id: "do",
    value: "DO",
    text: "Dominican Republic",
  },
  {
    id: "ec",
    value: "EC",
    text: "Ecuador",
  },
  {
    id: "eg",
    value: "EG",
    text: "Egypt",
  },
  {
    id: "sv",
    value: "SV",
    text: "El Salvador",
  },
  {
    id: "gq",
    value: "GQ",
    text: "Equatorial Guinea",
  },
  {
    id: "er",
    value: "ER",
    text: "Eritrea",
  },
  {
    id: "ee",
    value: "EE",
    text: "Estonia",
  },
  {
    id: "et",
    value: "ET",
    text: "Ethiopia",
  },
  {
    id: "fk",
    value: "FK",
    text: "Falkland Islands",
  },
  {
    id: "fo",
    value: "FO",
    text: "Faroe Islands",
  },
  {
    id: "fj",
    value: "FJ",
    text: "Fiji",
  },
  {
    id: "fi",
    value: "FI",
    text: "Finland",
  },
  {
    id: "fr",
    value: "FR",
    text: "France",
  },
  {
    id: "pf",
    value: "PF",
    text: "French Polynesia",
  },
  {
    id: "ga",
    value: "GA",
    text: "Gabon",
  },
  {
    id: "gm",
    value: "GM",
    text: "Gambia",
  },
  {
    id: "ge",
    value: "GE",
    text: "Georgia",
  },
  {
    id: "de",
    value: "DE",
    text: "Germany",
  },
  {
    id: "gh",
    value: "GH",
    text: "Ghana",
  },
  {
    id: "gi",
    value: "GI",
    text: "Gibraltar",
  },
  {
    id: "gr",
    value: "GR",
    text: "Greece",
  },
  {
    id: "gl",
    value: "GL",
    text: "Greenland",
  },
  {
    id: "gd",
    value: "GD",
    text: "Grenada",
  },
  {
    id: "gp",
    value: "GP",
    text: "Guadeloupe",
  },
  {
    id: "gu",
    value: "GU",
    text: "Guam",
  },
  {
    id: "gt",
    value: "GT",
    text: "Guatemala",
  },
  {
    id: "gn",
    value: "GN",
    text: "Guinea",
  },
  {
    id: "gw",
    value: "GW",
    text: "Guinea-Bissau",
  },
  {
    id: "gy",
    value: "GY",
    text: "Guyana",
  },
  {
    id: "ht",
    value: "HT",
    text: "Haiti",
  },
  {
    id: "hn",
    value: "HN",
    text: "Honduras",
  },
  {
    id: "hk",
    value: "HK",
    text: "Hong Kong",
  },
  {
    id: "hu",
    value: "HU",
    text: "Hungary",
  },
  {
    id: "is",
    value: "IS",
    text: "Iceland",
  },
  {
    id: "in",
    value: "IN",
    text: "India",
  },
  {
    id: "io",
    value: "IO",
    text: "Indian Ocean Territory",
  },
  {
    id: "id",
    value: "ID",
    text: "Indonesia",
  },
  {
    id: "ir",
    value: "IR",
    text: "Iran",
  },
  {
    id: "iq",
    value: "IQ",
    text: "Iraq",
  },
  {
    id: "ie",
    value: "IE",
    text: "Ireland",
  },
  {
    id: "il",
    value: "IL",
    text: "Israel",
  },
  {
    id: "it",
    value: "IT",
    text: "Italy",
  },
  {
    id: "jm",
    value: "JM",
    text: "Jamaica",
  },
  {
    id: "sj",
    value: "SJ",
    text: "Jan Mayen",
  },
  {
    id: "jp",
    value: "JP",
    text: "Japan",
  },
  {
    id: "jo",
    value: "JO",
    text: "Jordan",
  },
  {
    id: "kz",
    value: "KZ",
    text: "Kazakhstan",
  },
  {
    id: "ke",
    value: "KE",
    text: "Kenya",
  },
  {
    id: "ki",
    value: "KI",
    text: "Kiribati",
  },
  {
    id: "kw",
    value: "KW",
    text: "Kuwait",
  },
  {
    id: "kg",
    value: "KG",
    text: "Kyrgyzstan",
  },
  {
    id: "la",
    value: "LA",
    text: "Laos",
  },
  {
    id: "lv",
    value: "LV",
    text: "Latvia",
  },
  {
    id: "lb",
    value: "LB",
    text: "Lebanon",
  },
  {
    id: "ls",
    value: "LS",
    text: "Lesotho",
  },
  {
    id: "lr",
    value: "LR",
    text: "Liberia",
  },
  {
    id: "ly",
    value: "LY",
    text: "Libya",
  },
  {
    id: "li",
    value: "LI",
    text: "Liechtenstein",
  },
  {
    id: "lt",
    value: "LT",
    text: "Lithuania",
  },
  {
    id: "lu",
    value: "LU",
    text: "Luxembourg",
  },
  {
    id: "mo",
    value: "MO",
    text: "Macau",
  },
  {
    id: "mk",
    value: "MK",
    text: "Macedonia",
  },
  {
    id: "mg",
    value: "MG",
    text: "Madagascar",
  },
  {
    id: "mw",
    value: "MW",
    text: "Malawi",
  },
  {
    id: "my",
    value: "MY",
    text: "Malaysia",
  },
  {
    id: "mv",
    value: "MV",
    text: "Maldives",
  },
  {
    id: "ml",
    value: "ML",
    text: "Mali",
  },
  {
    id: "mt",
    value: "MT",
    text: "Malta",
  },
  {
    id: "mh",
    value: "MH",
    text: "Marshall Islands",
  },
  {
    id: "mq",
    value: "MQ",
    text: "Martinique",
  },
  {
    id: "mr",
    value: "MR",
    text: "Mauritania",
  },
  {
    id: "mu",
    value: "MU",
    text: "Mauritius",
  },
  {
    id: "yt",
    value: "YT",
    text: "Mayotte",
  },
  {
    id: "fm",
    value: "FM",
    text: "Micronesia",
  },
  {
    id: "md",
    value: "MD",
    text: "Moldova",
  },
  {
    id: "mc",
    value: "MC",
    text: "Monaco",
  },
  {
    id: "mn",
    value: "MN",
    text: "Mongolia",
  },
  {
    id: "me",
    value: "ME",
    text: "Montenegro",
  },
  {
    id: "ms",
    value: "MS",
    text: "Montserrat",
  },
  {
    id: "ma",
    value: "MA",
    text: "Morocco",
  },
  {
    id: "mz",
    value: "MZ",
    text: "Mozambique",
  },
  {
    id: "na",
    value: "NA",
    text: "Namibia",
  },
  {
    id: "nr",
    value: "NR",
    text: "Nauru",
  },
  {
    id: "np",
    value: "NP",
    text: "Nepal",
  },
  {
    id: "nl",
    value: "NL",
    text: "Netherlands",
  },
  {
    id: "nc",
    value: "NC",
    text: "New Caledonia",
  },
  {
    id: "pg",
    value: "PG",
    text: "New Guinea",
  },
  {
    id: "nz",
    value: "NZ",
    text: "New Zealand",
  },
  {
    id: "ni",
    value: "NI",
    text: "Nicaragua",
  },
  {
    id: "ne",
    value: "NE",
    text: "Niger",
  },
  {
    id: "ng",
    value: "NG",
    text: "Nigeria",
  },
  {
    id: "nu",
    value: "NU",
    text: "Niue",
  },
  {
    id: "nf",
    value: "NF",
    text: "Norfolk Island",
  },
  {
    id: "kp",
    value: "KP",
    text: "North Korea",
  },
  {
    id: "mp",
    value: "MP",
    text: "Northern Mariana Islands",
  },
  {
    id: "no",
    value: "NO",
    text: "Norway",
  },
  {
    id: "om",
    value: "OM",
    text: "Oman",
  },
  {
    id: "pk",
    value: "PK",
    text: "Pakistan",
  },
  {
    id: "pw",
    value: "PW",
    text: "Palau",
  },
  {
    id: "ps",
    value: "PS",
    text: "Palestine",
  },
  {
    id: "pa",
    value: "PA",
    text: "Panama",
  },
  {
    id: "py",
    value: "PY",
    text: "Paraguay",
  },
  {
    id: "pe",
    value: "PE",
    text: "Peru",
  },
  {
    id: "ph",
    value: "PH",
    text: "Philippines",
  },
  {
    id: "pl",
    value: "PL",
    text: "Poland",
  },
  {
    id: "pt",
    value: "PT",
    text: "Portugal",
  },
  {
    id: "pr",
    value: "PR",
    text: "Puerto Rico",
  },
  {
    id: "qa",
    value: "QA",
    text: "Qatar",
  },
  {
    id: "re",
    value: "RE",
    text: "Reunion",
  },
  {
    id: "ro",
    value: "RO",
    text: "Romania",
  },
  {
    id: "ru",
    value: "RU",
    text: "Russia",
  },
  {
    id: "rw",
    value: "RW",
    text: "Rwanda",
  },
  {
    id: "sh",
    value: "SH",
    text: "Saint Helena",
  },
  {
    id: "kn",
    value: "KN",
    text: "Saint Kitts and Nevis",
  },
  {
    id: "lc",
    value: "LC",
    text: "Saint Lucia",
  },
  {
    id: "pm",
    value: "PM",
    text: "Saint Pierre",
  },
  {
    id: "vc",
    value: "VC",
    text: "Saint Vincent",
  },
  {
    id: "ws",
    value: "WS",
    text: "Samoa",
  },
  {
    id: "sm",
    value: "SM",
    text: "San Marino",
  },
  {
    id: "st",
    value: "ST",
    text: "Sao Tome",
  },
  {
    id: "sa",
    value: "SA",
    text: "Saudi Arabia",
  },
  {
    id: "sn",
    value: "SN",
    text: "Senegal",
  },
  {
    id: "rs",
    value: "RS",
    text: "Serbia",
  },
  {
    id: "sc",
    value: "SC",
    text: "Seychelles",
  },
  {
    id: "sl",
    value: "SL",
    text: "Sierra Leone",
  },
  {
    id: "sg",
    value: "SG",
    text: "Singapore",
  },
  {
    id: "sk",
    value: "SK",
    text: "Slovakia",
  },
  {
    id: "si",
    value: "SI",
    text: "Slovenia",
  },
  {
    id: "sb",
    value: "SB",
    text: "Solomon Islands",
  },
  {
    id: "so",
    value: "SO",
    text: "Somalia",
  },
  {
    id: "za",
    value: "ZA",
    text: "South Africa",
  },
  {
    id: "kr",
    value: "KR",
    text: "South Korea",
  },
  {
    id: "es",
    value: "ES",
    text: "Spain",
  },
  {
    id: "lk",
    value: "LK",
    text: "Sri Lanka",
  },
  {
    id: "sd",
    value: "SD",
    text: "Sudan",
  },
  {
    id: "sr",
    value: "SR",
    text: "Suriname",
  },
  {
    id: "sz",
    value: "SZ",
    text: "Swaziland",
  },
  {
    id: "se",
    value: "SE",
    text: "Sweden",
  },
  {
    id: "ch",
    value: "CH",
    text: "Switzerland",
  },
  {
    id: "sy",
    value: "SY",
    text: "Syria",
  },
  {
    id: "tw",
    value: "TW",
    text: "Taiwan",
  },
  {
    id: "tj",
    value: "TJ",
    text: "Tajikistan",
  },
  {
    id: "tz",
    value: "TZ",
    text: "Tanzania",
  },
  {
    id: "th",
    value: "TH",
    text: "Thailand",
  },
  {
    id: "tl",
    value: "TL",
    text: "Timorleste",
  },
  {
    id: "tg",
    value: "TG",
    text: "Togo",
  },
  {
    id: "tk",
    value: "TK",
    text: "Tokelau",
  },
  {
    id: "to",
    value: "TO",
    text: "Tonga",
  },
  {
    id: "tt",
    value: "TT",
    text: "Trinidad",
  },
  {
    id: "tn",
    value: "TN",
    text: "Tunisia",
  },
  {
    id: "tr",
    value: "TR",
    text: "Turkey",
  },
  {
    id: "tm",
    value: "TM",
    text: "Turkmenistan",
  },
  {
    id: "tv",
    value: "TV",
    text: "Tuvalu",
  },
  {
    id: "ae",
    value: "AE",
    text: "U.A.E.",
  },
  {
    id: "ug",
    value: "UG",
    text: "Uganda",
  },
  {
    id: "ua",
    value: "UA",
    text: "Ukraine",
  },
  {
    id: "uy",
    value: "UY",
    text: "Uruguay",
  },
  {
    id: "vi",
    value: "VI",
    text: "US Virgin Islands",
  },
  {
    id: "uz",
    value: "UZ",
    text: "Uzbekistan",
  },
  {
    id: "vu",
    value: "VU",
    text: "Vanuatu",
  },
  {
    id: "va",
    value: "VA",
    text: "Vatican City",
  },
  {
    id: "ve",
    value: "VE",
    text: "Venezuela",
  },
  {
    id: "vn",
    value: "VN",
    text: "Vietnam",
  },
  {
    id: "wf",
    value: "WF",
    text: "Wallis and Futuna",
  },
  {
    id: "ye",
    value: "YE",
    text: "Yemen",
  },
  {
    id: "zm",
    value: "ZM",
    text: "Zambia",
  },
  {
    id: "zw",
    value: "ZW",
    text: "Zimbabwe",
  },
].map((country) => ({
  ...country,
  image: `https://flagcdn.com/w160/${country.id}.png`,
})) as Country[];
