import { INVALID_MODULE, LabeledModule } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import { LabeledBuilderConfigModule } from "../creator";

export class HTMLModule extends LabeledModule {
  protected _type: ModuleType = "Html";

  validate(): this is Required<HTMLModule> {
    return true;
  }

  toBuilderConfig(): LabeledBuilderConfigModule {
    if (this.validate()) {
      const builderConfigModule: LabeledBuilderConfigModule = {
        id: this.id,
        label: this.label,
        style: this.style,
        type: this.type,
      };
      if (this.tag) {
        builderConfigModule.tag = this.tag;
      }
      return builderConfigModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    const { label, ...rest } = this.toBuilderConfig();
    return {
      ...rest,
      content: label[this.languageCode],
      actionRequired: this.actionRequired,
    };
  }

  editProps() {
    return undefined;
  }
}
