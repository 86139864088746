import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  parseIncompletePhoneNumber,
  formatIncompletePhoneNumber,
  getCountryCallingCode,
  CountryCode,
  isValidPhoneNumber,
} from "libphonenumber-js/mobile";
import { Country, countryList } from "./countryList";
import { CountrySelect } from "./CountrySelect";
import { SvgMobile } from "../Svgs/Mobile";
import { HTMLInput, TextInput as TextInputBase } from "../TextInput";

type CountryPhoneProps = {
  value: string;
  onChange: (value: {
    value: string;
    countryCode?: string;
    isValid?: boolean;
  }) => void;
  defaultCountryCode?: CountryCode;
} & Record<string, any>;

const formatDisplayNumber = (input: string, countryCode: CountryCode) => {
  if (countryCode === "US") input = input.replace("+1", "");
  return formatIncompletePhoneNumber(input, countryCode);
};

export const CountryPhone = ({
  value,
  onChange,
  defaultCountryCode,
  ...props
}: CountryPhoneProps) => {
  const [country, setCountry] = useState<Country>(countryList[0]);
  const [selectOpen, setSelectOpen] = useState(false);
  const countryCallingCode = getCountryCallingCode(country.value);

  useEffect(() => {
    if (defaultCountryCode)
      setCountry(
        countryList.find((country) => country.value === defaultCountryCode) ||
          countryList[0]
      );
  }, [defaultCountryCode]);

  const handleChange = (text: string) => {
    const prefix = `+${countryCallingCode}`;
    const prefixSlice = text.slice(0, prefix.length);

    // first number can't be country code for US
    if (country.value === "US" && text === "1") {
      text = "+1";
    }

    // if partial country calling code, fill rest if number typed doesn't complete it
    if (country.value !== "US" && !prefix.includes(prefixSlice)) {
      text = `+${countryCallingCode}${text.slice(text.length - 1)}`;
    }

    // handle deleting parenthesis from US number
    if (
      parseIncompletePhoneNumber(text) === parseIncompletePhoneNumber(value)
    ) {
      text = text.slice(0, -1);
    }
    onChange({
      value: parseIncompletePhoneNumber(text),
      countryCode: country?.value,
      isValid: isValidPhoneNumber(text, country.value),
    });
  };

  // if input is empty and selected country is not US, insert country code
  const createIsolatedCountryCode = () => {
    if (value === "" && country.value !== "US") {
      onChange({
        value: `+${countryCallingCode}`,
        countryCode: country?.value,
      });
    }
  };

  // if input is just the country code and is not US, clear input
  const clearIsolatedCountryCode = () => {
    if (value === `+${countryCallingCode}` && country.value !== "US") {
      onChange({ value: "", countryCode: country.value });
    }
  };

  const onCountrySelect = (country: Country) => {
    const previousPrefix = `+${countryCallingCode}`;
    const selectedCountryPrefix = `+${getCountryCallingCode(country.value)}`;

    let newValue: string;
    switch (true) {
      // number contains entirety of previous country prefix - replace previous with new
      case value.indexOf(previousPrefix) === 0:
        newValue = value.replace(previousPrefix, selectedCountryPrefix);
        break;
      // contains partial of previous - remove partial and replace with new prefix
      case previousPrefix.includes(value):
        newValue = `${selectedCountryPrefix}`;
        break;
      // does not contain previous or partial (us -> others)
      default:
        newValue = `${selectedCountryPrefix}${value}`;
        break;
    }
    onChange({
      value: newValue,
      countryCode: country.value,
      isValid: isValidPhoneNumber(value, country.value),
    });
    setCountry(country);
  };

  return (
    <Container>
      <CountrySelect
        selected={country}
        onChange={onCountrySelect}
        options={countryList}
        onOpen={setSelectOpen}
        isOpen={selectOpen}
      />
      <TextInput
        value={formatDisplayNumber(value, country.value)}
        onChange={handleChange}
        autoComplete="tel"
        type="tel"
        leftIcon={<SvgMobile />}
        placeholder={
          country.value === "US"
            ? "(000) 000-0000"
            : `+${countryCallingCode} 000000000000`
        }
        onFocus={createIsolatedCountryCode}
        onBlur={clearIsolatedCountryCode}
        {...props}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  position: relative;
`;

const TextInput = styled(TextInputBase)`
  ${HTMLInput} {
    border-radius: 0 6px 6px 0;
    box-shadow: unset !important;
    line-height: 20px;
  }
`;
