import {
  createContext,
  ProviderProps,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useSearchParams } from "react-router-dom";

interface HistoryI {
  isFirstRoute?: boolean;
}

const HistoryContext = createContext<HistoryI>({});

export const HistoryProvider = (
  props: JSX.IntrinsicAttributes & Omit<ProviderProps<HistoryI>, "value">
) => {
  const location = useLocation();
  const [params] = useSearchParams();
  const [firstKey] = useState(
    typeof params.get("noredirect") === "string"
      ? "noredirectfakekey"
      : location.key
  );
  const value = {
    isFirstRoute: firstKey === location.key,
  };

  return <HistoryContext.Provider {...props} value={value} />;
};

export const useHistoryContext = () => useContext(HistoryContext);
