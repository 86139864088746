import { useEffect, useRef } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import { NavHeader } from ".";
import { H4 } from "../melodies-source/Text";
import { Link } from "../melodies-source/Link";

export const ArtistTerms = ({
  onClose,
}: {
  onClose?: VoidFunction;
  setModal?: (v: string) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const section2 = useRef(null);
  const section3 = useRef(null);
  const section14 = useRef(null);
  const section19 = useRef(null);
  const section20 = useRef(null);
  const scrollToSection = (elementRef: any) => {
    elementRef.current.scrollIntoView();
  };

  if (!onClose) {
    const goBackPath = location?.state?.back;
    onClose = goBackPath ? () => navigate(-1) : () => navigate("/");
  }

  return (
    <Wrapper>
      {/* <NavHeader onClose={onClose} /> */}
      <Content>
        <H4 style={{ textAlign: "center" }}>
          Music Audience Exchange, Inc.
          <br />
          Artist and Artist Representative Terms of Service
          <br />
          Last Updated: July 12, 2023
        </H4>
        <br />
        <p>
          These ARTIST AND ARTIST REPRESENTATIVE Terms of SERVICE (“
          <strong>agreement</strong>”) govern YOUR use of the MUSIC AUDIENCE
          EXCHANGE, INC. website and any related applications and services (the
          “<strong>SERVICES</strong>”) operated, provided by, or managed by
          MUSIC AUDIENCE EXCHANGE, INC. AND ITS SUBSIDIARIES AND OWNED BRANDS
          (COLLECTIVELY, “<strong>MAX</strong>”), including without limitation
          max’s set.live and set.fan services.
        </p>
        <p></p>
        <p>
          this agreement governs the registration and use of the services by
          ARTISTs, MUSIC LABELs, MARKETING COMPAnies, OR OTHER MANAGEMENT
          COMPANIES REPRESENTING artists (each an “
          <strong>Artist representative</strong>” or “<strong>YOU</strong>”).
          PLEASE NOTE THAT IF YOU ARE USING THE SERVICES AS AN individual END
          USER and not on behalf of an artist, this agreement may not apply to
          you and YOUR USE OF THE SERVICES IS GOVERNED BY THE{" "}
          <span
            onClick={() => window.open("/")}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            customer TERMS OF use
          </span>
          .
        </p>
        <p></p>
        <p>
          Please read this agreement before using or continuing to use the
          SERVICES. By using or continuing to use the SERVICES, you AFFIRM THAT
          YOU (A) ARE AT LEAST 18 YEARS OLD AND RESIDE IN THE UNITED STATES, OR
          ARE OF LEGAL AGE TO FORM A BINDING CONTRACT IN YOUR TERRITORY OR
          COUNTRY OF RESIDENCE, (B) ARE AN ARTIST, MUSIC LABEL, MARKETING
          COMPANY, OR OTHER MANAGEMENT COMPANY REPRESENTING an artist, AND (C)
          agree to BE LEGALLY BOUND AND ABIDE BY all terms and conditions
          contained in this agreement. IF YOU DO NOT AGREE TO BE BOUND BY this
          agreement, YOU MUST NOT USE THE SERVICES. This agreement MAY BE
          UPDATED FROM TIME TO TIME.
        </p>
        <p></p>
        <p>
          <strong>1.</strong> <strong>Access to the Services</strong>
        </p>
        <p>
          MAX reserves the right to withdraw or amend the Services, and any
          service, content, material, or information provided by or presented on
          the Services (“<strong>Content</strong>”), in its sole discretion
          without notice from time to time. MAX will not be liable if for any
          reason if all or any part of the Services is unavailable at any time
          or for any period.<strong> </strong>MAX assumes no responsibility
          regarding the accuracy, availability, currency, or usefulness of the
          Content.
          <span ref={section2}></span>
        </p>
        <p></p>
        <p>
          <strong>2.</strong> <strong>Policies</strong>
        </p>
        <p>
          All information MAX collects through the Services is subject to MAX’s{" "}
          <span
            onClick={() => window.open("https://www.max.live/privacy-policy")}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Privacy Policy
          </span>
          . By using the Services, you consent to MAX’s use of your information
          in compliance with MAX’s Privacy Policy. To the extent that you have
          previously received or are currently subject to any other MAX
          policies, you agree to abide by such policies. In the event of a
          conflict between any other MAX policy, and this Agreement, this
          Agreement shall govern and control with respect to the subject matter
          herein unless otherwise expressly set forth.
          <span ref={section3}></span>
        </p>
        <p></p>
        <p>
          <strong>3.</strong> <strong>Account Security</strong>
        </p>
        <p>
          Upon completion of your registration on the Services, an “
          <strong>Artist Account</strong>” or “<strong>Account</strong>” will be
          created for you or your organization. Your Artist Account will contain
          information and details unique to you (if you are an artist) or your
          organization (if you are a music label, marketing company, or other
          management company representing an artist). If you are a music label,
          marketing company, or other management company representing an artist,
          you may authorize the artists that you manage to create “
          <strong>User Accounts</strong>” associated with your Artist Account.
          You are responsible for all activities that occur under your Artist
          Account and any associated User Accounts and must ensure your Users
          comply with this Agreement. You are responsible for maintaining the
          security of the passwords associated with your Artist Account and User
          Accounts. If you believe an unauthorized person has access to your
          password, the Account itself, or an associated User Account, you must
          contact us as set forth herein. Your access to your Account or any
          associated User Account may be revoked by MAX at any time with or
          without cause.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>4.</strong> <strong>Your Content</strong>
        </p>
        <p>
          The Services may provide you with the ability to upload images and
          other content about an artist, which may include an artist’s name,
          image, and/or likeness (as such content, the “
          <strong>Artist Content</strong>”). When you upload Artist Content to
          the Services or otherwise provide such Artist Content to MAX, you
          continue to own such content (as between you and MAX), but you provide
          MAX with a nonexclusive, worldwide, royalty-free, paid-up, right and
          license to display, publish, and otherwise use such Artist Content for
          the sole purpose of providing you with the Services.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>5.</strong> <strong>Representations and Warranties</strong>
        </p>
        <p></p>
        <p>
          You represent and warrant to MAX that you have all rights necessary to
          utilize the Services and to upload all content you upload to the
          Services for MAX’s use in accordance with this Agreement, including,
          without limitation (a) your business logo or any other logos or
          trademarks uploaded to the Services by you; (b) your name and
          likeness; and (c) all Artist Content, including the name, likeness,
          publicity right, and other content relating to all the artists you
          manage and provide to MAX for display on the Service. You also
          represent and warrant that you will comply with all applicable laws
          and the policies set forth in{" "}
          <span
            onClick={() => scrollToSection(section2)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Section 2
          </span>{" "}
          (Policies).
        </p>
        <p></p>
        <p>
          <strong>6.</strong>{" "}
          <strong>
            Advertisements and Promotions; Communications with Users;
            Sweepstakes
          </strong>
        </p>
        <p>
          You are responsible for your statements through the Services. Any
          communications or engagements between you and customers, third-party
          advertisers, or other end users through the Services are between you
          and such third parties. MAX is not responsible or liable for any loss
          or damage as a result of such communications or engagements. You are
          solely responsible for the content of your communications and any
          obligations therein, and you hereby agree to indemnify and hold
          harmless MAX from any liability arising out of your communications
          with third party users through the Services.
        </p>
        <p></p>
        <p>
          For example, the Services may offer the ability for you to operate a
          promotion, such as a sweepstakes or contest. YOU ARE SOLELY
          RESPONSIBLE FOR ANY PROMOTIONS YOU RUN THROUGH THE SERVICES, INCLUDING
          WITHOUT LIMITATION SWEEPSTAKES. YOU SHALL ONLY OPERATE PROMOTIONS IN
          ACCORDANCE WITH APPLICABLE LAWS. TO THE EXTENT THE SERVICES PROVIDE
          ANY “TEMPLATE” OR “EXAMPLE” PROMOTION TERMS OR RULES, SUCH EXAMPLES
          ARE MERELY EXAMPLES AND PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND.
          MAX MAKES NO REPRESENTATIONS THAT ANY SUCH EXAMPLE OR TEMPLATE
          PROMOTION TERMS ARE LEGALLY SUFFICIENT OR OTHERWISE APPROPRIATE FOR
          YOUR PROMOTION. YOU ARE EXPECTED AND ENCOURAGED TO HAVE YOUR LEGAL
          COUNSEL REVIEW AND SUCH PROMOTION TERMS OR RULES AND YOU HEREBY AGREE
          TO INDEMNIFY AND HOLD HARMLESS MAX FROM ANY LIABILITY ARISING OUT OF
          YOUR PROMOTIONS.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>7.</strong>{" "}
          <strong>
            Payment; Revenue Share for Set.Live Sponsorship Deals; Donation
            Features
          </strong>
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          In general, most features of the Services are free to use. However,
          certain features of the Services may require payment or revenue share.
          When you sign up for features that require payment or revenue share,
          you will be provided with an Order Form from MAX detailing such
          payments.
        </p>
        <p></p>
        <p>
          For example, if you use the Services to arrange a brand partnership
          for an artist, a percentage of the sponsorship fee shall be due to MAX
          for use of the Services. For example, if you use the “donation”
          feature through the Services, a percentage of the total amount paid
          will be due to MAX for access to the feature. The amount shall be
          determined by MAX by Order Form or other communication when you access
          the “donation” feature.
        </p>
        <p></p>
        <p>
          For more detail and additional terms regarding such payments, please
          review your MAX Order Form or contact your MAX representative.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>8.</strong>{" "}
          <strong>Artist Pricing; Product Warranties</strong>
        </p>
        <p>
          The Services may provide the functionality to interact with users by
          offering goods or services for sale. To the extent you use this
          functionality the following sections (Sections 8 through 14) shall
          apply to your use of the Services. You also agree to comply with any
          additional sales terms provided by MAX from time to time.
        </p>
        <p></p>
        <p>
          You will set the pricing and warranties for the products and services
          you provide to end users through the Services on your behalf or on
          behalf of the artists that you represent. All pricing and warranty
          information for your sales through the Services must be visible to
          customers prior to making a purchase. MAX has no responsibility or
          liability for such transactions, and you hereby agree to indemnify and
          hold harmless MAX from any liability arising out of your transactions
          with third party users through the Services.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>9.</strong>{" "}
          <strong>Order Acceptance; Order Cancellations</strong>
        </p>
        <p>
          You must accept and fulfill all customer orders for products and
          services submitted to you through the Service (“
          <strong>Orders</strong>”). Orders are your binding obligation to sell
          the customer the products and services ordered via the Service. MAX
          reserves the right to, in its sole discretion and for any reason, on a
          case-by-case basis, to reject or cancel any Orders even after a
          confirmation email has been sent with the Order number and details.
          MAX will notify you in the event of an Order cancellation. In
          addition, MAX may limit or eliminate certain pre-approved products and
          services available for sale by you on your Artist Account portal in
          its sole discretion.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>10.</strong> <strong>Invoicing</strong>
        </p>
        <p>
          You must accompany all fulfilled Orders with an invoice to the
          customer directly from you. All invoices shall, at a minimum, include
          the following items: (i) Order number; (ii) your business name,
          address, phone number, and any other contact information available to
          the customers; (iii) the name, description, quantity, and net price of
          each product or service purchased in the Order; (iv) any special
          prices or discounts applicable to the Order; (v) the cost of shipping;
          (vi) the total amount of the Order; and (vii) any additional terms and
          conditions, such as, but not limited to, your (a) shipping policy and
          (b) return, exchange, and refund policy.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>11.</strong> <strong>Shipping</strong>
        </p>
        <p>
          You are responsible for all shipping charges on products and services
          you sell on the Services, including returned items, subject to
          applicable law and the Policies set forth in{" "}
          <span
            onClick={() => scrollToSection(section2)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Section 2
          </span>
          . You will prominently display your shipping policy for all products
          and services sold on the Services to be available for all customers to
          read before placing an Order, and you agree and acknowledge that your
          shipping policy is binding between you and the customer. At a minimum,
          your shipping policy must include your designated shipping carrier and
          shipping rate and speed options. All your shipments must contain a
          tracking number and a description of all physical items and units
          included in the shipment. You bear the risk of loss during shipment at
          all times.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>12.</strong> <strong>Returns; Refunds</strong>
        </p>
        <p>
          You are responsible for all returns and refunds on products and
          services you sell on the Services, including replacement items,
          subject to applicable law and the applicable Policies set forth in{" "}
          <span
            onClick={() => scrollToSection(section2)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Section 2
          </span>
          . You will prominently display your return and refund policy for all
          products and services you sell on the Service to be available for all
          customers to read before placing an Order.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>13.</strong> <strong>Setoff</strong>
        </p>
        <p>
          Any outstanding amounts owed to us, such as for any customer disputes
          we resolve on your behalf as set forth in{" "}
          <span
            onClick={() => scrollToSection(section14)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Section 14
          </span>
          , will be due and payable within thirty (30) days. No failure or delay
          on our part in the exercise of any such rights or remedies will
          constitute a waiver of such rights or remedies under this Section, or
          limit MAX in its enforcement of any other remedies that may be
          available to it. No cancellation of your Artist Account will relieve
          you of any of your obligations under this Agreement.
          <span ref={section2}></span>
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>14.</strong> <strong>Customer Disputes</strong>
        </p>
        <p>
          We may, in our sole discretion, resolve any shipping, return, or
          refund disputes between you and the customer that you do not resolve
          to the customer’s satisfaction.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>15.</strong>{" "}
          <strong>Ownership of the Services and MAX Content</strong>
        </p>
        <p>
          The MAX name, the MAX logo, Set.Live, Set.Fan, and all related names,
          logos, product and service names, designs, and slogans are the
          registered and unregistered trademarks of MAX, its licensors, or other
          providers of such material. You are prohibited from using such marks,
          except as otherwise permitted by this Agreement or MAX in a prior
          writing. MAX and its licensors retain all right, title, and interest
          in and to the Services and the Content (including but not limited to
          all information, software, text, displays, images, video and audio,
          and the design, selection and arrangement thereof), and any derivative
          works or other modifications thereof, including, without limitation,
          all copyright, trademark, trade secret and other intellectual property
          rights. You hereby assign to us all right, title and interest
          (including all intellectual property rights) throughout the world that
          you currently have or may later acquire in the Services or the Content
          (including with respect to any modifications suggested by, or other
          contributions made by, you). Assignment of intellectual property
          rights shall be deemed effective as to any future modifications or
          contributions of the Services or the Content immediately upon the
          creation thereof.
        </p>
        <p>
          <strong>16.</strong> <strong>Prohibited Uses</strong>
        </p>
        <p>
          You may use the Services only for lawful purposes and in accordance
          with this Agreement. You agree not to:
        </p>
        <p></p>
        <p>
          · Modify, duplicate, transmit, distribute, present, upload, publish,
          license, or sell any Content from the Services;
        </p>
        <p>
          · Use any illustrations, photographs, video or audio sequences, or any
          graphics separately from the accompanying text;
        </p>
        <p>
          · Delete or alter any copyright, trademark, or other proprietary
          rights notices from Content on the Services;
        </p>
        <p>
          · Use the Services in any way that violates any applicable federal,
          state, local, or international law or regulation (including, without
          limitation, any laws regarding the export of data or software to and
          from the United States or other countries);
        </p>
        <p>
          · Send, knowingly receive, upload, download, use, or re-use any
          material in violation of this Agreement;
        </p>
        <p>
          · Engage in any other conduct that restricts or inhibits anyone’s use
          or enjoyment of the Services, or which, as determined by MAX, may harm
          MAX or users of the Services or expose them to liability;
        </p>
        <p>
          · Use any bot or any other automated technology to access the Services
          for any purpose, including monitoring or copying any Content on the
          Services;
        </p>
        <p>
          · Use any manual process to monitor or copy any Content on the
          Services or for any other unauthorized purpose without MAX’s prior
          written consent;
        </p>
        <p>
          · Use any device, software or routine that interferes with the proper
          working of the Services;
        </p>
        <p>
          · Introduce any viruses or other harmful or malicious software to the
          Services;
        </p>
        <p>
          · Interfere with, damage, or disrupt any parts of the Services, the
          servers on which the Services are stored, or any server, computer or
          database connected to the Services; or
        </p>
        <p>
          · Otherwise attempt to interfere with the proper working of the
          Services.
        </p>
        <p></p>
        <p>
          You agree that MAX has the right to take appropriate legal action,
          including without limitation, referral to law enforcement, for any
          illegal or unauthorized use of the Services.
        </p>
        <p></p>
        <p>
          <strong>17.</strong> <strong>Intellectual Property Rights</strong>
        </p>
        <p>
          You retain all right, title, and interest in and to your registered
          and unregistered trademarks and copyrights as you make available or
          upload to the Services. You hereby grant MAX a non-exclusive,
          royalty-free, worldwide license to use, reproduce, modify, adapt,
          publicly display, translate, and distribute any trademarks and
          copyrights made available by you on the Service to provide certain
          services to you. For specific customized services that MAX may provide
          via the Service, you also grant MAX a non-exclusive, royalty-free,
          worldwide license to use, reproduce, modify, adapt, publicly display,
          translate, and distribute any trademarks and copyrights made available
          by you on third party public platforms including, but not limited to,
          social media websites. You warrant that you own or control all rights
          in and to your trademarks and copyrights and have the right to grant
          the license to MAX and its affiliates and service providers, and each
          of their and MAX’s respective licensees, successors and assigns.
        </p>
        <p>
          <strong>18.</strong> <strong>Copyright Infringement</strong>
        </p>
        <p>
          If you believe that anything on the Services infringes your copyright
          protected material, please provide MAX with written notice of such
          infringement. Such notice must include the following to be in
          compliance with the U.S. Digital Millennium Copyright Act (“
          <strong>DMCA</strong>”):
        </p>
        <p></p>
        <p>
          · The physical or electronic signature of the copyright owner, or the
          physical or electronic signature of a person authorized to act on
          their behalf;
        </p>
        <p>
          · Identification of the copyrighted material claimed to have been
          infringed;
        </p>
        <p>
          · Identification of the copyrighted material that is claimed to be
          infringing, and information reasonably sufficient to permit us to
          locate the material;
        </p>
        <p>
          · Your contact information, including, your address, telephone number,
          and an email address;
        </p>
        <p>
          · A statement by you that you have a good faith belief that use of the
          copyrighted material in the manner complained of is not authorized by
          the copyright owner, its agent, or the law; and
        </p>
        <p>
          · A statement that the information in the notice is accurate, and
          under penalty of perjury, that you are the copyright owner or are
          authorized to act on behalf of the copyright owner.
        </p>
        <p>
          <br /> Send the notice to MAX’s designated agent for notice of alleged
          copyright infringement at:
        </p>
        <p></p>
        <p>By Email: support@set.live</p>
        <p></p>
        <p>
          By Mail: Music Audience Exchange, Inc.
          <br /> Attn: Copyright Agent [or other designee]
          <br /> 2595 Dallas Parkway, Frisco, TX 75034
        </p>
        <p></p>
        <p>
          MAX reserves the right to remove and/or restrict access to any content
          alleged to be infringing. MAX may do so in its sole discretion,
          without prior notice, and without liability to you. In appropriate
          circumstances and in MAX’s sole discretion, MAX will also terminate a
          user’s account if the user is determined to be a repeat infringer.
          <span ref={section19}></span>
        </p>
        <p></p>
        <p>
          <strong>19.</strong> <strong>User Content and Contributions</strong>
        </p>
        <p>
          The Services may contain message boards, chat rooms, personal web
          pages or profiles, forums, bulletin boards, and/or other interactive
          features (collectively, “<strong>Interactive Services</strong>”) that
          allow users to post, submit, publish, display or transmit to other
          users or other persons (hereinafter, “<strong>post</strong>”) content
          or materials (collectively, “<strong>User Contributions</strong>”) on
          or through the Services. All User Contributions, Interactive Services,
          and posts must comply with the Content Standards (as set forth in{" "}
          <span
            onClick={() => scrollToSection(section20)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Section 20
          </span>{" "}
          of this Agreement). Any User Contribution you post to the Service will
          be considered non-confidential and non-proprietary. By providing any
          User Contribution on the Services, you grant MAX and its affiliates
          and service providers, and each of their and MAX’s respective
          licensees, successors and assigns a non-exclusive, worldwide, royalty
          free license to use, reproduce, modify, perform, display, distribute
          and otherwise disclose to third parties any such User Contribution for
          any purpose. You represent and warrant that:
        </p>
        <p></p>
        <p>
          · You own or control all rights in and to the User Contributions and
          have the right to grant the license granted above to us and MAX’s
          affiliates and service providers, and each of their and MAX’s
          respective licensees, successors and assigns;
        </p>
        <p>
          · All of your User Contributions do and will comply with this
          Agreement;
        </p>
        <p>
          · You are responsible for any User Contributions you submit or
          contribute and you, not MAX, have full responsibility for such
          content, including its legality, reliability, accuracy and
          appropriateness; and
        </p>
        <p>
          · MAX is not responsible, or liable to any third party, for the
          content or accuracy of any User Contributions posted by you or any
          other user of the Services.
          <span ref={section20}></span>
        </p>
        <p></p>
        <p>
          <strong>20.</strong> <strong>Content Standards</strong>
        </p>
        <p>
          These content standards (“<strong>Content Standards</strong>”) apply
          to any and all User Contributions and use of Interactive Services (as
          set forth in{" "}
          <span
            onClick={() => scrollToSection(section19)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Section 19
          </span>{" "}
          of this Agreement). User Contributions must in their entirety comply
          with all applicable federal, state, local and international laws and
          regulations. Without limiting the foregoing, User Contributions must
          not:
        </p>
        <p></p>
        <p>
          · Contain any material which is defamatory, obscene, indecent,
          abusive, offensive, harassing, violent, hateful, inflammatory or
          otherwise objectionable;
        </p>
        <p>
          · Promote sexually explicit or pornographic material, violence, or
          discrimination based on race, sex, religion, nationality, disability,
          sexual orientation or age;
        </p>
        <p>
          · Infringe any patent, trademark, trade secret, copyright or other
          intellectual property or other rights of any other person;
        </p>
        <p>
          · Violate the legal rights (including the rights of publicity and
          privacy) of others or contain any material that could give rise to any
          civil or criminal liability under applicable laws or regulations or
          that otherwise may be in conflict with this Agreement or MAX’s Privacy
          Policy;
        </p>
        <p>· Be likely to deceive any person;</p>
        <p>
          · Promote any illegal activity, or advocate, promote or assist any
          unlawful act;
        </p>
        <p>
          · Cause annoyance, inconvenience or needless anxiety or be likely to
          upset, embarrass, alarm or annoy any other person;
        </p>
        <p>
          · Impersonate any person, or misrepresent your identity or affiliation
          with any person or organization; or
        </p>
        <p>
          · Give the impression that they emanate from or are endorsed by MAX or
          any other person or entity if this is not the case.
        </p>
        <p></p>
        <p>
          <strong>21.</strong>{" "}
          <strong>Monitoring and Enforcement; Termination</strong>
        </p>
        <p>
          We have the right to use a combination of automated systems and a team
          of moderators to monitor and review accounts and messages for User
          Contributions and any other content that indicates violations of this
          Agreement. MAX has the right to:
        </p>
        <p>
          · Suspend or terminate any account, restrict access to the Services,
          or make use of any operational, technological, legal or other means
          available to enforce this Agreement (including without limitation
          blocking specific IP addresses), at any time without liability and
          without the need to give you prior notice;
        </p>
        <p>
          · Suspend or terminate any account without notice (i) for violating
          this Agreement, (ii) due to your conduct on the Services, or your
          conduct with other users of the Services (including your “offline”
          conduct), if MAX, in its sole discretion, determine your conduct was
          inappropriate or improper, (iii) if MAX or MAX’s affiliates, in MAX’s
          or such affiliates’ sole discretion, determine your conduct on other
          websites or apps operated by MAX’s affiliates was inappropriate or
          improper, or (iv) for any reasons whatsoever that MAX deems, in its
          sole discretion, justifies termination;
        </p>
        <p>
          · Remove or refuse to post to the Services any User Contributions for
          any or no reason in MAX’s sole discretion;
        </p>
        <p>
          · Take any action with respect to any User Contribution that we deem
          necessary or appropriate in MAX’s sole discretion, including if we
          believe that such User Contribution violates the Terms, including the
          Content Standards (as set forth in{" "}
          <span
            onClick={() => scrollToSection(section20)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Section 20
          </span>{" "}
          of this Agreement), infringes any intellectual property right or other
          right of any person or entity, threatens the personal safety of users
          of the Services or the public or could create liability for MAX;
        </p>
        <p>
          · Disclose your identity or other information about you to any third
          party if material posted by you violates their rights, including their
          intellectual property rights or their right to privacy; or
        </p>
        <p>
          · Take appropriate legal action, including without limitation,
          referral to law enforcement, for any illegal or unauthorized use of
          the Services.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>22.</strong> <strong>Third Party Websites</strong>
        </p>
        <p>
          Third party websites, which are linked to or from the Services (such
          as those for artist ticket sales and charitable donations), are
          independently owned, operated, and managed by such third parties.
          Third party websites are not under the control and/or supervision of
          MAX, and MAX does not warrant or guarantee the contents thereof.
        </p>
        <p></p>
        <p>
          <strong>23.</strong> <strong>Links to Service</strong>
        </p>
        <p>
          You may link to the Services, provided you (i) do so in a way that is
          fair and legal and does not damage MAX’s reputation or take advantage
          of it, and (ii) do not suggest any form of association, approval or
          endorsement on MAX’s part. If you would like to use, reprint, frame,
          or redistribute any Content on the Services other than as permitted
          herein, you must request permission from MAX in writing to
          support@set.live. You agree that you are liable for any Content or
          links that you publish directly or through a third party.
        </p>
        <p></p>
        <p>
          <strong>24.</strong> <strong>Feedback</strong>
        </p>
        <p>
          Your ideas, suggestions questions, suggestions, comments, feedback,
          ideas, or other information regarding MAX or the Services (including,
          but not limited to, those related to improvements, additions, or other
          aspects for MAX or the Services) that you provide to MAX, whether
          solicited or not by the Services (collectively, “
          <strong>Feedback</strong>”) are the exclusive property of MAX. If you
          send MAX Feedback, regardless of application, MAX assumes that you
          have sent them to MAX after accepting that, regarding those ideas, MAX
          assumes no obligation regarding their confidentiality.
        </p>
        <p></p>
        <p>
          <strong>25.</strong> <strong>Disclaimer of Warranties</strong>
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES, CONTENT, AND
          SERVICES THEREIN ARE PROVIDED “AS IS”, “WITH ALL FAULTS”, “AS
          AVAILABLE” AND AT THE USER’S SOLE RISK. OTHER THAN AS EXPRESSLY SET
          FORTH IN THESE TERMS, MAX MAKES NO REPRESENTATIONS AND WARRANTIES OF
          ANY KIND OR NATURE, WHETHER EXPRESS OR IMPLIED, AND EXPRESSLY
          DISCLAIMS TO THE FULL EXTENT PERMISSIBLE UNDER APPLICABLE LAW ANY AND
          ALL OTHER WARRANTIES OR CONDITIONS OF ANY KIND, INCLUDING ANY
          WARRANTIES OR CONDITIONS THAT THE SERVICE, CONTENT, OR SERVICES: (A)
          ARE FIT FOR A PARTICULAR PURPOSE; (B) DO NOT INFRINGE THIRD PARTY
          RIGHTS; (C) ARE ACCURATE OR ERROR FREE OR WILL MEET YOUR NEEDS OR
          REQUIREMENTS; OR (D) WILL ALWAYS BE AVAILABLE, ACCESSIBLE,
          UNINTERRUPTED, TIMELY, SECURE OR FREE OF MALICIOUS CODE OR VIRUS.
        </p>
        <p></p>
        <p>
          <strong>26.</strong> <strong>Limitation of Liability</strong>
        </p>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, MAX’S LIABILITY IN ALL EVENTS
          IS LIMITED TO THE SERVICE FEES PAID TO MAX FOR THE RELATED SALE IN
          QUESTION. IN NO EVENT WILL MAX, ITS AFFILIATES, OR THEIR LICENSORS,
          SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE
          FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
          CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICES, ANY
          WEBSITES LINKED TO THEM, ANY CONTENT ON THE SERVICES OR SUCH OTHER
          WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES OR
          SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT
          LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
          LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
          SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, THAT RESULT FROM
          YOUR SALE OF ANY PRODUCTS OR SERVICES, OR USE OF THE SERVICE OR ANY
          MAX SERVICES AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
          OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT
          AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
          APPLICABLE LAW.
        </p>
        <p>
          <strong>27.</strong> <strong>Assumption of Risk</strong>
        </p>
        <p></p>
        <p>
          You agree and understand that you assume all risks when using the
          Services, including without limitation any and all of the risks
          associated with any online interactions with other users, customers,
          or visitors of the Services.
        </p>
        <p>
          <strong>28.</strong> <strong>Indemnification</strong>
        </p>
        <p>
          You agree to defend, indemnify and hold harmless MAX, its affiliates,
          licensors and service providers, and their respective officers,
          directors, employees, contractors, agents, licensors, suppliers,
          successors, and assigns from and against any claims, liabilities,
          damages, judgments, awards, losses, costs, expenses, or fees
          (including reasonable attorneys’ fees) arising out of or relating to
          (i) an infringement of any patent, trademark, or copyright, including
          costs and expenses, (ii) any breach of this Agreement or your use of
          the Services, including, but not limited to, User Contributions or
          your use of any Content or materials obtained from the Services, (iii)
          any unlawful, improper or negligent use by anyone of any product or
          service you sell under this Agreement, (iv) any dispute between you
          and a customer of the Services or authorized User Account holders
          under your Artist Account, or (v) your negligence or more culpable act
          or omission. You will use counsel reasonably satisfactory to us to
          defend each indemnified claim.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>29.</strong> <strong>Information</strong>
        </p>
        <p>
          Except as disclosed in this Agreement, our use of your information is
          governed by the terms of our{" "}
          <span
            onClick={() => window.open("https://www.max.live/privacy-policy")}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Privacy Policy
          </span>
          . Please review the{" "}
          <span
            onClick={() => window.open("https://www.max.live/privacy-policy")}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Privacy Policy
          </span>{" "}
          to understand our practices with respect to your information. We will
          own all information generated during a customer interaction,
          including, but not limited to, name, address, phone number, shipment
          date, ASIN/SKU, quantity and other information gathered from
          customers’ activity and Orders placed on the Service. We will provide
          customers’ shipping information to you as needed to enable you to
          fulfill Orders for your products and services sold on the Service. We
          may share additional customer information with you as needed subject
          to your compliance with applicable privacy laws
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>30.</strong> <strong>Access</strong>
        </p>
        <p>
          You hereby grant to MAX the right to monitor, diagnose, communicate
          with, retrieve information from, and otherwise access your Artist
          Account for the purpose of (i) providing the Services to you; and (ii)
          offering the Services to customers. It is your responsibility to
          secure, at your own cost, your Artist Account and any associated User
          Accounts as set forth in{" "}
          <span
            onClick={() => scrollToSection(section3)}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Section 3
          </span>{" "}
          (Account Security).
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>31.</strong> <strong>Confidentiality</strong>
        </p>
        <p>
          Any specifical financial terms between you and MAX, as well as any
          customizations to the Services provided to you are confidential (“
          <strong>Confidential Information</strong>”). You will take all
          reasonable measures to avoid disclosure, dissemination or unauthorized
          use of Confidential Information, including, at a minimum, those
          measures you take to protect your own confidential information of a
          similar nature. You may use Confidential Information only in
          connection with your use of the Services. You will not disclose
          Confidential Information unless required by law. This applies to all
          Confidential Information in your possession, regardless of when or
          where you receive it. This obligation shall survive as long as
          permitted under applicable law.
        </p>
        <p>
          <strong>32.</strong> <strong>Marketing</strong>
        </p>
        <p>
          If you receive information about a customer who places an Order for a
          product or service you sell on the Service, you agree to use the
          information to fulfill the customer’s respective Order and as
          otherwise specified in the Privacy Policy. You may not disclose or
          distribute a customer’s information to a third party for purposes
          unrelated to the Services. In addition, you may only send marketing
          communications to customers who have consented to receive them in
          accordance with applicable laws.
        </p>
        <p>
          <strong> </strong>
        </p>
        <p>
          <strong>33.</strong>{" "}
          <strong>Governing Law and Dispute Resolution</strong>
        </p>
        <p>
          Use of the Services and interpretation and application of this
          Agreement are in compliance with the laws of the State of Texas. Any
          disputes arising out of or related to the Service shall be tried only
          in the Federal or State court with competent jurisdiction located in
          Dallas, Texas. Any controversy or claim arising out of or relating to
          this Agreement, or the breach thereof, shall be settled by
          confidential binding arbitration administered in Dallas, Texas by the
          American Arbitration Association (“<strong>AAA</strong>”) in
          accordance with its Arbitration Rules then in effect. There shall be
          one arbitrator agreed to by you and MAX (the “
          <strong>Arbitration Parties</strong>”) within twenty (20) days of a
          written request for arbitration. If the Arbitration Parties cannot
          agree, an arbitrator will be appointed by the AAA in accordance with
          its Arbitration Rules. Any award from any such arbitration proceeding
          may be entered as a judgment in any court of competent jurisdiction.
          You shall bear your own costs in connection with any arbitration
          hereunder. Nothing herein shall prevent MAX from seeking injunctive
          relief (or any other provisional remedy) from any court having
          jurisdiction over you and the subject matter of the dispute as is
          necessary to protect its proprietary rights.
        </p>
        <p></p>
        <p>
          <strong>34. Waiver and Severability</strong>
        </p>
        <p>
          If any provision of this Agreement is judged to be invalid, illegal or
          unenforceable, that provision shall be deemed deleted and the
          remainder shall not be affected. MAX’s failure to enforce any
          provision of this Agreement shall not be deemed a waiver of the
          provision or any right under this Agreement.
        </p>
        <p>
          <strong>35. Entire Agreement</strong>
        </p>
        <p>
          This Agreement (including any applicable Order Forms or additional
          terms set forth by MAX) and MAX’s{" "}
          <span
            onClick={() => window.open("https://www.max.live/privacy-policy")}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Privacy Policy
          </span>{" "}
          constitute the sole and entire agreement between you and MAX with
          respect to the Services, and supersede all prior and contemporaneous
          understandings, agreements, representations and warranties, both
          written and oral, with respect to the Services. This Agreement and all
          of your rights and obligations under this Agreement are not assignable
          or transferable by you without MAX’s prior written consent. MAX may
          freely assign, transfer, or delegate this Agreement or any of MAX’s
          rights and obligations under them.
        </p>
        <p>
          <strong>36.</strong> <strong>Contact</strong>
        </p>
        <p>
          The Services are operated by Music Audience Exchange, Inc., 2595
          Dallas Parkway, Suite 440, Frisco, Texas 75034. All feedback,
          comments, requests for technical support and other communications
          relating to the Services should be directed to: support@set.live
        </p>
        <p></p>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  overflow-y: scroll;
  button {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled(FlexColumn)`
  margin: 0 auto;
  padding: 40px 24px;
  width: 100%;
  ${Link} {
    color: #1b0076 !important;
    display: inline-block;
    text-decoration: underline;
  }
  ${H4} {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }
  a {
    word-break: break-all;
  }
  p {
    font-size: 15;
    font-weight: 400;
    line-height: 22px;
    color: ${(props) => props.theme.colors.black80};
    margin-top: 16px;
    strong {
      font-weight: 600;
    }
  }
  ul {
    padding-left: 24px;
    list-style: disc;
    li {
      margin-top: 8px;
      font-size: 15;
      font-weight: 400;
      line-height: 22px;
      color: ${(props) => props.theme.colors.black80};
    }
  }
`;
