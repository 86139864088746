import styled, { css } from "styled-components";
import { LinkText } from "../Text";

type LinkProps = {
  isDisabled?: boolean;
};
export const Link = styled(LinkText)<LinkProps>`
  cursor: pointer;
  color: ${(p) => p.theme.colors.navy};
  :hover {
    text-decoration: underline;
  }
  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      color: ${theme.colors.black40};
      pointer-events: none;
      cursor: default;
    `}
`;
