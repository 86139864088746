import {
  SurveyBuilderOptIn,
  SurveyBuilderVersionDocumentWithName,
} from "./types";
import { SurveyConfigModule } from "../fanApp";

// existing surveys might not have the opt in fields in the builder, so handle gracefully and fall
// back to defaults if they don't exist
export const buildSurveyConfigOptIns = (
  builderConfig: SurveyBuilderVersionDocumentWithName,
): SurveyConfigModule => {
  const hasRequiredDefaultOptIns =
    builderConfig.optIns?.filter(
      (optIn) => optIn.id === "optInTermsPrivacy1" || optIn.id === "optInText",
    )?.length === 2;

  const optinOptions = hasRequiredDefaultOptIns
    ? [...builderConfig.optIns]
    : // this handles existing surveys that don't have these
      [
        defaultTermsAndPrivacyOptIn(builderConfig.artistName),
        defaultSmsOptIn(builderConfig.artistName),
      ];

  if (builderConfig.fields.useCustomTermsAndPrivacyPolicy.content) {
    const optInTermsPrivacyIndex = optinOptions.findIndex(
      (opt) => opt.id === "optInTermsPrivacy1",
    );

    optinOptions[optInTermsPrivacyIndex] = {
      id: "optInTermsPrivacy1",
      htmlLabel: builderConfig.fields.customTermsAndPrivacyPolicy.content.en,
      required: true,
    };

    // if separate line items, insert after opt in terms and privacy
    if (
      builderConfig.fields.termsAndPrivacyPolicySeparateLineItems.content.en
    ) {
      optinOptions.splice(optInTermsPrivacyIndex + 1, 0, {
        id: "optInTermsPrivacy2",
        htmlLabel: styleLabelText(
          builderConfig.fields.termsAndPrivacyPolicySeparateLineItems.content
            .en,
        ),
        required: true,
      });
    }
  }

  if (builderConfig.fields.useCustomSmsOptIn.content) {
    const optInSmsIndex = optinOptions.findIndex(
      (opt) => opt.id === "optInText",
    );

    const optInLabelOverride = builderConfig.fields.customSmsOptIn.content.en;
    if (optInLabelOverride) {
      optinOptions[optInSmsIndex] = {
        id: "optInText",
        htmlLabel: optInLabelOverride,
      };
    }
  }

  return {
    type: "confirm",
    validation: {
      type: "require_all",
      message: "Please agree to the terms to submit your feedback",
      all: optinOptions
        .filter((optIn) => optIn.required)
        .map((optIn) => optIn.id),
    },
    id: "optIn",
    options: optinOptions,
  };
};

export const defaultTermsAndPrivacyOptIn = (
  artistName: string,
): SurveyBuilderOptIn => ({
  id: "optInTermsPrivacy1",
  htmlLabel: `<div style="font-size:12px;line-height:18px;font-family:'Poppins';">
I agree to share the above information with <span style="font-weight: 800">${artistName}</span>
and <span style="font-weight: 800">Set.Live</span>. I agree to SET's <a style="color: #006ACC" onclick="(function(e){ const event = new Event('setfan-show-terms',{bubbles: true});e.target.dispatchEvent(event); e.stopPropagation();return false;})(arguments[0]);return false;">
Terms of Use</a> and <a style="color: #006ACC" onclick="(function(e){const event = new Event('setfan-show-privacy',{bubbles: true});e.target.dispatchEvent(event);e.stopPropagation();return false;})(arguments[0]);return false;">
 Privacy Policy
</a></div>`,
  required: true,
});

export const defaultSmsOptIn = (artistName: string): SurveyBuilderOptIn => ({
  id: "optInText",
  htmlLabel: `<div style="font-size:12px;line-height:18px;font-family:'Poppins';">
    I’d like to receive text or messaging service messages from ${artistName} 
    or via ${artistName}’s partners or representatives. Consent is not a condition of purchase.
     Message data rates may apply.
   </div>`,
});

// default text styling for checkbox labels in the SET.Fan fan app
export const styleLabelText = (text: string): string =>
  `<span style="font-size:12px;line-height:18px;font-family:'Poppins';">${text}</span>`;

export const styleLinkText = ({
  href,
  text,
}: {
  href: string;
  text: string;
}): string =>
  `<a href=${href} ${STOP_PROPAGATION} style="color:#006ACC;text-decoration:none;" target="__blank">${text}</a>`;

// when a user clicks a link in an opt in in the SET.Fan fan app, we do not want the option
// selected.
const STOP_PROPAGATION = `onclick="(function(e){ e.stopPropagation();})(arguments[0]);"`;
export const injectStopPropagation = (text: string) =>
  text.replace(
    /(a) (href)/g,
    `$1 ${STOP_PROPAGATION} style="color:#006ACC;" $2`,
  );
