import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { usePopper } from "react-popper";
import { Placement } from "@popperjs/core/lib";

export const DropdownBackdrop = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownContainer = styled.div`
  background-color: white;
  border: solid 1px#F9FAFB;
  position: absolute;
  border-radius: 4px;
  overflow-y: auto;
  z-index: 1000000000000;
  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);
  border-radius: 6px;
`;

type Props = {
  onClose?: any;
  isOpen?: boolean;
  placement?: Placement;
  children: any;
} & React.BaseHTMLAttributes<HTMLDivElement>;
export const Dropdown = ({
  onClose,
  isOpen = false,
  placement = "top",
  children,
  ...props
}: Props) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
    }
  );

  useEffect(() => {
    if (update) update();
  }, [isOpen]);

  return (
    <div ref={setReferenceElement}>
      {isOpen && (
        <>
          <DropdownBackdrop onClick={onClose}></DropdownBackdrop>
          <DropdownContainer
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            {...props}
          >
            {children}
          </DropdownContainer>
        </>
      )}
    </div>
  );
};
