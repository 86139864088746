import {
  CSSServerProperties,
  TextBuilderConfigModule,
  TranslateableModuleContent,
} from "../creator/types";
import { INVALID_MODULE, LabeledModule, LabeledModuleProps } from "./base";
import { SurveyConfigModule, ModuleType, TextVariant } from "../fanApp";
import { generateUUID } from "./util";

type TextModuleProps = LabeledModuleProps & {
  caption?: TranslateableModuleContent;
  variants: { mobile: TextVariant; desktop: TextVariant };
};
export class TextModule extends LabeledModule {
  protected _type: ModuleType = "text";
  private _variants: { mobile: TextVariant; desktop: TextVariant };
  // this isn't something that exists on the text module type in Set.Fan. however,
  // the ui for the editor/creator requires them to be coupled at least until config
  // generation. split into two `text` modules with proper variants set when creating config
  caption: TranslateableModuleContent;

  constructor({ variants, caption = { en: "" }, ...rest }: TextModuleProps) {
    super(rest);

    this._variants = variants;
    this.caption = caption;
  }

  get variants(): { mobile: TextVariant; desktop: TextVariant } {
    return this._variants;
  }

  set variants({
    mobile,
    desktop,
  }: {
    mobile?: TextVariant;
    desktop?: TextVariant;
  }) {
    if (!mobile && !desktop) {
      throw new Error(
        "text variant must be specified for at least one of mobile or desktop",
      );
    }
    const newVariants = { ...this.variants };
    if (mobile) {
      newVariants.mobile = mobile;
    }
    if (desktop) {
      newVariants.desktop = desktop;
    }
    this._variants = newVariants;
  }

  // don't validate that a label exists, we need to be able to save drafts without specifying
  // all text fields in the survey
  validate(): this is Required<TextModule> {
    return true;
  }

  toBuilderConfig(): TextBuilderConfigModule {
    if (this.validate()) {
      return {
        actionRequired: this.actionRequired,
        id: this.id,
        caption: this.caption,
        label: this.label,
        noBorder: this.noBorder,
        variants: this.variants,
        type: this.type,
      };
    }
    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule;
  toSurveyConfig(): SurveyConfigModule[];
  toSurveyConfig() {
    const { variants, caption, label, tag, ...base } = this.toBuilderConfig();
    const surveyConfigModule: SurveyConfigModule = {
      ...base,
      actionRequired: this.actionRequired,
      content: label[this.languageCode],
    };

    surveyConfigModule.style = {
      ...this.style,
      "@media(max-width: 599px)": {
        ...TextVariants[variants.mobile],
      },
      "@media(min-width: 600px)": {
        ...TextVariants[variants.desktop],
      },
    } as CSSServerProperties;

    // if caption, provide two distint text modules to survey config
    if (caption?.[this.languageCode]) {
      return [
        surveyConfigModule,
        {
          ...base,
          id: generateUUID(),
          content: caption[this.languageCode],
          style: {
            ...this.style,
            "@media(max-width: 599px)": {
              ...TextVariants.Caption,
            },
            "@media(min-width: 600px)": {
              ...TextVariants.Caption,
            },
          },
        },
      ];
    }

    return surveyConfigModule;
  }

  editProps() {
    return {
      id: this.id,
      type: this.type,
      question: this.label[this.languageCode] ?? "",
      variants: this.variants,
      text: { caption: this.caption.en },
      actionRequired: this.actionRequired,
    };
  }
}

const TextVariants: Record<TextVariant, CSSServerProperties> = {
  Body1: {
    fontSize: 15,
    lineHeight: "22px",
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  Body2: {
    fontSize: 12,
    lineHeight: "18px",
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  Headline2: {
    fontSize: 26,
    lineHeight: "36px",
    fontWeight: 600,
    fontFamily: "Poppins",
  },
  Headline3: {
    fontSize: 20,
    lineHeight: "28px",
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  Headline4: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  Button: {
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 600,
    fontFamily: "Poppins",
  },
  Caption: {
    fontSize: 10,
    lineHeight: "16px",
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  Label: {
    fontSize: 15,
    lineHeight: "20px",
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  Subtitle2: {
    fontSize: 11,
    lineHeight: "16px",
    fontWeight: 600,
    fontFamily: "Poppins",
  },
};
