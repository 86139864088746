import {
  CardIcon,
  currencyFormatter,
  useActionContext,
  useDataContext,
  useIsVisible,
  useScript,
  useUserActionTrackingContext,
} from "../../../../../Components";
import styled, { css } from "styled-components";
import { Button } from "../../../../../melodies-source/Button";
import { Merch } from ".";
import { SvgDownload } from "../../../../../melodies-source/Svgs/Download";
import parse from "html-react-parser";
import { useScrollGradient } from "../../../../../Components/useScrollGradient";
import { SvgForward } from "../../../../../melodies-source/Svgs/Forward";
import { Body1 } from "../../../../../melodies-source/Text";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardType, UserEventStates } from "@max/common";

export const Card = ({
  visible,
  headline,
  subtitle,
  icon,
  customIcon,
  body,
  cta,
  ctalabel,
  background,
  color,
  script,
  html,
  image,
  merch,
  songs,
  label,
}: CardType): JSX.Element | null => {
  const { handleAction } = useActionContext();
  const { total, setValue } = useDataContext();
  const isVisible = useIsVisible(visible);
  const { data } = useDataContext();
  const [srollRef, handleScroll, hasScroll, atBottom] = useScrollGradient();
  const isNotRegistered = data.status === UserEventStates.draft;
  const { track } = useUserActionTrackingContext();
  const { t } = useTranslation();

  const labelString = label?.custom || label?.auto || "";

  useEffect(() => {
    if (isVisible) {
      track({
        event: "card",
        context: { label: labelString },
      });
    }
  }, [isVisible, labelString, track]);

  useScript(script);

  if (!isVisible) {
    return null;
  }

  if (html) {
    return <>{parse(html)}</>;
  }

  if (merch) {
    return (
      <Container>
        <Merch merch={merch} />
      </Container>
    );
  }

  return (
    <Container style={{ background, color }}>
      {(icon || customIcon) && (
        <Icon>
          <CardIcon icon={icon} customIcon={customIcon} />
        </Icon>
      )}
      {headline && (
        <Header hasBody={!!body || !!subtitle} count={headline.length}>
          {headline}
        </Header>
      )}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {!!songs && (
        <SongWrapper
          scroll={!!songs?.length}
          ref={srollRef}
          onScroll={handleScroll}
          atBottom={atBottom}
        >
          {songs?.map(({ title, album, id: songId }) => (
            <Song
              title={title}
              album={album!}
              onClick={() => {
                setValue({ songId });
                handleAction("register");
              }}
            />
          ))}
          {!atBottom && hasScroll && (
            <ScrollButton isDisabled={true}>
              <SvgForward />
            </ScrollButton>
          )}
        </SongWrapper>
      )}
      {body && <Body count={body.length}>{body}</Body>}
      {image && <Image style={{ marginBottom: "20px" }} src={image} />}
      {cta && !songs?.length && ctalabel && (
        <CtaButton
          cta={isNotRegistered && cta !== "payment"}
          onClick={() => handleAction(cta)}
        >
          {cta === "social" && <SvgDownload />}
          {ctalabel}
        </CtaButton>
      )}
      {!!total && cta === "payment" && (
        <Donate>
          {t("You have donated")} <Amount>{currencyFormatter(total)}</Amount>
        </Donate>
      )}
    </Container>
  );
};
const Song = ({
  title,
  album,
  onClick,
}: {
  title: string;
  album?: string;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <SongContainer>
      <div>
        <SongTitle>{title}</SongTitle>
        <SongBody>{album}</SongBody>
      </div>
      <VoteButton onClick={onClick}>{t("Vote")}</VoteButton>
    </SongContainer>
  );
};

const Icon = styled.div`
  flex-shrink: 0;
  margin: 0 auto;
  svg {
    width: 32px;
    height: 32px;
  }
`;

const Subtitle = styled(Body1)`
  font-family: var(--template-font-family);
  color: var(--template-text);
  font-weight: 300;
  margin: 0 0 22px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 20px;
`;
const Header = styled.div<{ hasBody: boolean; count?: number }>`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin: 10px auto 16px;
  text-align: center;
  ${(p) =>
    p.count &&
    p.count > 40 &&
    css`
      font-size: 20px;
      line-height: 28px;
    `};

  ${({ hasBody }) =>
    !hasBody &&
    css`
      font-size: 20px;
      line-height: 28px;
      padding: 0 20px 8px;
    `}
`;
const Donate = styled.div`
  text-align: center;
  color: #fff;
  padding: 5px 0 15px;
`;

const Amount = styled.div`
  padding: 10px;
  font-size: 20px;
  display: inline-block;
`;

const Body = styled.div<{ count?: number }>`
  margin-bottom: 24px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  ${(p) =>
    p.count &&
    p.count > 84 &&
    css`
      font-size: 16px;
      line-height: 22px;
    `};
`;
const SongWrapper = styled.div<{ scroll?: boolean; atBottom: boolean }>`
  display: none;
  ${({ scroll }) =>
    scroll &&
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 250px;
      overflow-y: scroll;
      scrollbar-width: thin;
      scrollbar-color: transparent;
      ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 3px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
    `};
`;

const SongContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #424040;
  padding: 8px 0;
`;
const SongTitle = styled.div`
  text-align: left;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
`;
const SongBody = styled.div`
  text-align: left;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;
const VoteButton = styled(Button)`
  height: 26px;
  width: 64px;
  flex-shrink: 0;
  color: var(--template-primary);
  border: 2px solid var(--template-primary);
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  background-color: #000;
  border-radius: 15px;
  margin-left: 10px;
  padding: 4px 12px;
  :hover {
    background: #000;
  }
`;
export const CtaButton = styled(Button)<{ cta?: boolean }>`
  color: var(--template-text);
  width: auto;
  min-width: 180px;
  margin: 0 auto;
  height: auto;
  padding: 6px 12px;
  background-color: #000000;
  font-size: 18px;
  line-height: 24px;
  border-width: 4px;
  border-style: solid;
  border-color: var(--template-primary);
  border-radius: var(--template-border-radius);
  font-family: var(--template-font-family);
  svg {
    margin: 0 8px 0 -6px;
    width: 24px;
    height: 24px;
    color: var(--template-primary);
    background-color: var(--template-secondary);
    border-radius: 15px;
    padding: 4px;
  }
  &:hover {
    background-color: #000000;
    border-color: var(--template-primary);
  }
  ${({ cta }) =>
    !cta &&
    css`
      min-width: 130px;
      background: var(--template-primary);
      color: var(--template-secondary);
      font-size: 16px;
      :hover {
        background: var(--template-primary);
        color: var(--template-secondary);
      }
    `}
`;
const Image = styled.img`
  width: 130px;
  margin: 0 auto;
  border-radius: 11px;
`;
const ScrollButton = styled(Button)`
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  margin: 0 auto 15px auto;
  background-color: #000;
  padding: 4px 2px 2px 2px;
  font-size: 11px;
  letter-spacing: 0.5px;
  height: 25px;
  width: 25px;
  font-weight: 100;
  color: #ffffff78;
  border-radius: 15px;
  border: 1.5px solid #ffffff6d;
  svg {
    color: #ffffff95;
    transform: rotate(90deg);
  }
  :active {
    background-color: var(--template-primary);
  }
  :hover {
    background-color: var(--template-primary);
  }
`;
