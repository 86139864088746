import { SelectBuilderConfigModule } from "../creator";
import { ModuleType, SurveyConfigModule } from "../fanApp";
import { INVALID_MODULE, SelectModule, SelectModuleProps } from "./base";
import { addRequiredValidation, generateUUID } from "./util";

type DropdownModuleProps = Omit<SelectModuleProps, "randomizeOptions">;
export class DropdownModule extends SelectModule {
  protected _type: ModuleType = "Dropdown";

  validate(): this is Required<DropdownModuleProps> {
    return true;
  }

  toBuilderConfig(): SelectBuilderConfigModule {
    if (this.validate()) {
      const builderConfigModule: SelectBuilderConfigModule = {
        actionRequired: this.actionRequired,
        id: this.id,
        label: this.label,
        options: this.options,
        style: this.style,
        required: this.required,
        type: this.type,
      };
      if (this.tag) {
        builderConfigModule.tag = this.tag;
      }
      return builderConfigModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    const { required, label, ...base } = this.toBuilderConfig();
    const surveyConfigModule: SurveyConfigModule = {
      ...base,
      actionRequired: this.actionRequired,
      header: { children: label?.[this.languageCode] ?? "" },
      options: this.options.map((opt) => ({
        id: opt.id || generateUUID(),
        label: opt.label?.[this.languageCode],
      })),
    };
    if (required) {
      surveyConfigModule.validation = addRequiredValidation();
    }
    return surveyConfigModule;
  }

  editProps() {
    return {
      id: this.id,
      options: this.options.map((opt) => ({
        id: opt.id || generateUUID(),
        label: opt.label?.[this.languageCode],
      })),
      type: this.type,
      question: this.label[this.languageCode] ?? "",
      actionRequired: this.actionRequired,
    };
  }
}
