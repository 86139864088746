import { DateTime } from "luxon";
import { BuilderConfigDocument } from "./index";

type ReplaceString = {
  value: string;
  key: string;
};

export const updateStringVars = (
  content = '',
  replaceStrings: ReplaceString[],
) => {
  for (const s of replaceStrings) {
    const regex = new RegExp(`{${s.key}}`, "g");
    content = content.replace(regex, s.value);
  }
  return content;
};

export interface PrizeOption {
  headline?: string;
  body?: string;
  subtitle?: string;
  registerTitle?: string;
  ctalabel?: string;
  cta?: string;
  value: string;
  label: string;
}

interface PrizeList {
  group: string;
  items: PrizeOption[];
}

const updateObject = (item: PrizeOption, replaceStrings: ReplaceString[]) => {
  const uItem = { ...item };
  Object.keys(item).forEach((p) => {
    const key = p as keyof PrizeOption;
    if (item[key]) {
      uItem[key] = updateStringVars(item[key] || "", replaceStrings);
    }
  });
  return uItem;
};

export const generatePreRegText = <T>(
  config: Partial<BuilderConfigDocument<T | DateTime>>,
) => {
  const stringReplacements = [
    { key: "artistName", value: config.artistName || "" },
    { key: "title", value: "My Release" },
    {
      key: "customPrize",
      value: config.fields?.sweepstakesPrizeCustom?.content?.en || "",
    },
  ];

  const contentType =
    config.fields?.checkInVote?.content === "vote"
      ? config.fields?.voteType?.content || "song"
      : "checkin";

  const prizeOptions: PrizeList[] = prizeTextContent[contentType].map((g) => {
    const items = g.items.map((opt) => updateObject(opt, stringReplacements));
    return { ...g, items };
  });

  const customPrize = updateObject(
    customPrizeTextContent[contentType],
    stringReplacements,
  );

  const noPrize = updateObject(
    noPrizeTextContent[contentType],
    stringReplacements,
  );

  const selectedPrize = prizeOptions.reduce((acc, p) => {
    const option = p.items.find(
      (i) => i.label === config.fields?.sweepstakesPrize?.content,
    );
    if (option) {
      acc = option;
    }
    return acc;
  }, {}) as PrizeOption | undefined;

  const matchingContent = !config.fields?.sweepstakes?.content
    ? noPrize
    : config.fields?.sweepstakesPrizeCustom?.content
      ? customPrize
      : selectedPrize;

  const update = (v?: string) => {
    if (!v) return;
    return updateStringVars(v, stringReplacements);
  };

  const preRegText = {
    headline: update(matchingContent?.headline),
    subtitle: update(matchingContent?.subtitle),
    registerTitle: update(matchingContent?.registerTitle),
    body: update(matchingContent?.body),
    ctalabel: matchingContent?.ctalabel,
    cta: matchingContent?.cta,
  };

  return { preRegText, prizeOptions };
};

const checkin: PrizeList[] = [
  {
    group: "Merch",
    items: [
      {
        label: "a tour poster",
        value: "a tour poster",
        headline: "Take home a tour poster!",
        body: "We'll be giving away a tour poster to someone here tonight. If you want to leave tonight with it, please take a minute to check in and let us know you're here for a chance to win!",
        registerTitle: "Check in below for a chance to win",
        cta: "register",
        ctalabel: "Check In",
      },
      {
        label: "a t-shirt",
        value: "a t-shirt",
        headline: "Win a free {artistName} t-shirt!",
        body: "Check in for a chance to leave tonight with a free t-shirt! If you want that t-shirt to be yours, please take a minute to let us know you were here.",
        registerTitle: "Check in below for a chance to win",
        cta: "register",
        ctalabel: "Check In",
      },
      {
        label: "a merch bundle",
        value: "a merch bundle",
        headline: "Win a {artistName} merch bundle!",
        body: "Check in for a chance to leave tonight with a free merch bundle! It could be yours if you take a minute to let us know you were here.",
        registerTitle: "Check in below for a chance to win",
        cta: "register",
        ctalabel: "Check In",
      },
      {
        label: "VIP pass in future",
        value: "VIP pass in future",
        headline: "See {artistName} as a VIP",
        body: "The next time you see {artistName} you could be on the guestlist! Check in for a chance to win a pair of VIP tickets the next time {artistName} is in town! ",
        registerTitle: "Check in below for a chance to win",
        cta: "register",
        ctalabel: "Check In",
      },
    ],
  },
  {
    group: "Signed",
    items: [
      {
        label: "a signed poster",
        value: "a signed poster",
        headline: "Win a signed tour poster!",
        body: "We'll be giving away a signed tour poster to someone here tonight. If you want to leave tonight with it, please take a minute to check in and let us know you're here for a chance to win!",
        registerTitle: "Check in below for a chance to win",
        cta: "register",
        ctalabel: "Check In",
      },
      {
        label: "a signed setlist",
        value: "a signed setlist",
        headline: "Take home tonight's signed setlist!",
        body: "Check in at the show, and you could win a piece of history: a signed setlist! Plus, get insider updates on upcoming releases, merch drops, and future shows in your area. ",
        registerTitle: "Check in below for a chance to win",
        cta: "register",
        ctalabel: "Check In",
      },
    ],
  },
  {
    group: "Experiential",
    items: [
      {
        label: "an onstage shoutout from {artistName}",
        value: "an onstage shoutout from {artistName}",
        headline: "Win an onstage shoutout from {artistName}!",
        body: "This is your chance to be a part of history and get a shoutout from stage from {artistName}. Check in and submit your requested shoutout now!",
        registerTitle: "Check in below for a chance to win",
        cta: "register",
        ctalabel: "Check In",
      },
      {
        label: "a chance to dedicate a song",
        value: "a chance to dedicate a song",
        headline: "Dedicate one of tonight's songs!",
        body: "This is your chance to be a part of history and be able to dedicate a song from tonight's set. Check in and submit your requested dedication now!",
        registerTitle: "Check in below for a chance to win",
        cta: "register",
        ctalabel: "Check In",
      },
      {
        label: "a chance to watch the show from backstage",
        value: "a chance to watch the show from backstage",
        headline: "Watch from backstage!",
        body: "Get the ultimate concert experience by watching from the best seats in the house! Check in for a chance to watch from up close!",
        registerTitle: "Check in below for a chance to win",
        cta: "register",
        ctalabel: "Check In",
      },
    ],
  },
  {
    group: "Meet & Greet",
    items: [
      {
        label: "a chance to meet {artistName}",
        value: "a chance to meet {artistName}",
        headline: "Meet {artistName} tonight!",
        body: "Imagine going backstage to meet {artistName}... it could happen tonight! Check in for an opportunity for a once-in-a-lifetime experience!",
        registerTitle: "Check in below for a chance to win",
        cta: "register",
        ctalabel: "Check In",
      },
    ],
  },
];

const song: PrizeList[] = [
  {
    group: "Merch",
    items: [
      {
        label: "a tour poster",
        value: "a tour poster",
        headline: "Cast Your Vote & Win!",
        subtitle:
          "Vote for the song you want to hear, and you'll be entered to win a tour poster!",
        registerTitle:
          "Fill out the form below to submit your vote and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
      {
        label: "a t-shirt",
        value: "a t-shirt",
        headline: "Cast Your Vote & Win!",
        subtitle:
          "Vote for the song you want to hear, and you'll be entered to win a tour t-shirt!",
        registerTitle:
          "Fill out the form below to submit your vote and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
      {
        label: "a merch bundle",
        value: "a merch bundle",
        headline: "Cast Your Vote & Win!",
        subtitle:
          "Vote for the song you want to hear, and you'll be entered to win a merch bundle!",
        registerTitle:
          "Fill out the form below to submit your vote and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
      {
        label: "VIP pass in future",
        value: "VIP pass in future",

        headline: "Cast Your Vote & Win!",
        subtitle:
          "Vote for the song you want to hear, and you'll be entered to win VIP tickets the next time {artistName} is in town! ",
        registerTitle:
          "Fill out the form below to submit your vote and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
    ],
  },
  {
    group: "Signed",
    items: [
      {
        label: "a signed poster",
        value: "a signed poster",
        headline: "Cast Your Vote & Win!",
        subtitle:
          "Vote for the song you want to hear, and you'll be entered to win a signed tour poster!",
        registerTitle:
          "Fill out the form below to submit your vote and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
      {
        label: "a signed setlist",
        value: "a signed setlist",
        headline: "Cast Your Vote & Win!",
        subtitle:
          "Vote for the song you want to hear, and you'll be entered to win a signed setlist!",
        registerTitle:
          "Fill out the form below to submit your vote and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
    ],
  },
  {
    group: "Experiential",
    items: [
      {
        label: "an onstage shoutout from {artistName}",
        value: "an onstage shoutout from {artistName}",
        headline: "Cast Your Vote & Win!",
        subtitle:
          "Vote for the song you want to hear, and you'll be entered to win a shoutout from the stage!",
        registerTitle:
          "Fill out the form below to submit your vote and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
      {
        label: "a chance to watch the show from backstage",
        value: "a chance to watch the show from backstage",
        headline: "Cast Your Vote & Win!",
        subtitle:
          "Vote for the song you want to hear, and you'll be entered to win a chance to watch from the backstage!",
        registerTitle:
          "Fill out the form below to submit your vote and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
    ],
  },
  {
    group: "Meet & Greet",
    items: [
      {
        label: "a chance to meet {artistName}",
        value: "a chance to meet {artistName}",
        headline: "Cast Your Vote & Win!",
        subtitle:
          "Vote for the song you want to hear, and you'll be entered to win a chance to meet {artistName}!",
        registerTitle:
          "Fill out the form below to submit your vote and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
    ],
  },
];

const vote: PrizeList[] = [
  {
    group: "Merch",
    items: [
      {
        value: "a tour poster",
        label: "a tour poster",
        headline: "Be a Part of the Show & Win!",
        subtitle:
          "Take a minute to choose, and you'll be entered to win a tour poster!",
        registerTitle:
          "Fill out the form below to submit your choice and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
      {
        value: "a t-shirt",
        label: "a t-shirt",
        headline: "Be a Part of the Show & Win!",
        subtitle:
          "Take a minute to choose, and you'll be entered to win a tour t-shirt!",
        registerTitle:
          "Fill out the form below to submit your choice and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
      {
        value: "a merch bundle",
        label: "a merch bundle",
        headline: "Be a Part of the Show & Win!",
        subtitle:
          "Take a minute to choose, and you'll be entered to win a merch bundle!",
        registerTitle:
          "Fill out the form below to submit your choice and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
      {
        value: "VIP pass in future",
        label: "VIP pass in future",

        headline: "Be a Part of the Show & Win!",
        subtitle:
          "Take a minute to choose, and you'll be entered to win VIP tickets the next time {artistName} is in town! ",
        registerTitle:
          "Fill out the form below to submit your choice and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
    ],
  },
  {
    group: "Signed",
    items: [
      {
        value: "a signed poster",
        label: "a signed poster",
        headline: "Be a Part of the Show & Win!",
        subtitle:
          "Take a minute to choose, and you'll be entered to win a signed tour poster!",
        registerTitle:
          "Fill out the form below to submit your choice and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
      {
        value: "a signed setlist",
        label: "a signed setlist",
        headline: "Be a Part of the Show & Win!",
        subtitle:
          "Take a minute to choose, and you'll be entered to win a signed setlist!",
        registerTitle:
          "Fill out the form below to submit your choice and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
    ],
  },
  {
    group: "Experiential",
    items: [
      {
        value: "an onstage shoutout from {artistName}",
        label: "an onstage shoutout from {artistName}",
        headline: "Be a Part of the Show & Win!",
        subtitle:
          "Take a minute to choose, and you'll be entered to win a shoutout from the stage!",
        registerTitle:
          "Fill out the form below to submit your choice and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
      {
        value: "a chance to watch the show from backstage",
        label: "a chance to watch the show from backstage",
        headline: "Be a Part of the Show & Win!",
        subtitle:
          "Take a minute to choose, and you'll be entered to win a chance to watch from the backstage!",
        registerTitle:
          "Fill out the form below to submit your choice and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
    ],
  },
  {
    group: "Meet & Greet",
    items: [
      {
        value: "a chance to meet {artistName}",
        label: "a chance to meet {artistName}",
        headline: "Be a Part of the Show & Win!",
        subtitle:
          "Take a minute to choose, and you'll be entered to win a chance to meet {artistName}!",
        registerTitle:
          "Fill out the form below to submit your choice and be entered to win",
        cta: "register",
        ctalabel: "Vote",
      },
    ],
  },
];

export const noPrizeTextContent = {
  checkin: {
    headline: "Let us know you're here!",
    body: "Check in at the show for exclusive updates on new music, merch drops, and tour dates. Don't miss out on being the first to know!",
    registerTitle: "Check in below",
    cta: "register",
    ctalabel: "Check In",
    label: "",
    value: "",
  },
  song: {
    headline: "Be a Part of the Show",
    subtitle:
      "Cast your vote for the song you want to hear {artistName} play tonight.",
    registerTitle: "Fill out the form below to submit your vote.",
    cta: "register",
    ctalabel: "Vote",
    label: "",
    value: "",
  },
  vote: {
    headline: "Be a Part of the Show",
    subtitle:
      "This your chance to make tonight's show special. Cast your vote and let your voice be heard!",
    registerTitle: "Fill out the form below to submit your choice.",
    cta: "register",
    ctalabel: "Vote",
    label: "",
    value: "",
  },
};

export const customPrizeTextContent = {
  checkin: {
    headline: "Win {customPrize}!",
    body: "Check in at the show for exclusive {artistName} updates and be entered to win {customPrize}",
    registerTitle: "",
    cta: "register",
    ctalabel: "Check In",
    label: "",
    value: "",
  },
  song: {
    headline: "Cast Your Vote & Win!",
    subtitle:
      "Vote for the song you want to hear, and you'll be entered to win {customPrize}!",
    registerTitle: "",
    cta: "register",
    ctalabel: "Vote",
    label: "",
    value: "",
  },
  vote: {
    headline: "Be a Part of the Show & Win!",
    subtitle:
      "Take a minute to choose, and you'll be entered to win {customPrize}!",
    registerTitle: "",
    cta: "register",
    ctalabel: "Vote",
    label: "",
    value: "",
  },
};

export const prizeTextContent = {
  checkin,
  song,
  vote,
};
