import { INVALID_MODULE, SurveyModule, SurveyModuleProps } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import { PageBuilderConfigModule } from "../creator";

export type PageModuleProps = Omit<SurveyModuleProps, "noBorder"> & {
  compact?: boolean;
  modules?: SurveyModule[];
};
export class PageModule extends SurveyModule {
  protected _type: ModuleType = "page";
  modules: SurveyModule[];
  compact: boolean;

  constructor({ modules = [], compact = false, ...rest }: PageModuleProps) {
    super(rest);

    this.modules = modules;
    this.compact = compact;
  }

  validate(): this is Required<PageModule> {
    return true;
  }

  toBuilderConfig(): PageBuilderConfigModule {
    if (this.validate()) {
      const surveyConfigModule: PageBuilderConfigModule = {
        compact: this.compact,
        modules: this.modules.map((mod) => mod.toBuilderConfig()),
        style: this.style,
        type: this.type,
      };

      if (this.tag) {
        surveyConfigModule.tag = this.tag;
      }

      return surveyConfigModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    if (this.validate()) {
      const SurveyConfigModule: SurveyConfigModule = {
        compact: this.compact,
        modules: this.modules.flatMap((mod) => mod.toSurveyConfig()),
        style: this.style,
        type: this.type,
      };

      if (this.tag) {
        SurveyConfigModule.tag = this.tag;
      }

      return SurveyConfigModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  editProps() {
    return undefined;
  }
}
