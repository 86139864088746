import { useIpLocation, Coordinates } from "../../Components";
import { CountryCode } from "libphonenumber-js";
import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface LocationError {
  error: boolean;
  message?: string;
}
interface LocationContextProps {
  countryCode?: CountryCode;
  location?: Coordinates;
  ipLocation?: Coordinates;
  setLocation: React.Dispatch<React.SetStateAction<Coordinates | undefined>>;
  locationLoading: boolean;
  allowLocationSearch: boolean;
  setAllowLocationSearch: React.Dispatch<React.SetStateAction<boolean>>;
  handleLocationRequest: (cb?: () => void) => void;
  hasLocationError: boolean;
  locationErrorMessage?: string;
  ipaddress?: string;
  ipLocationLoading: boolean;
}

export const LocationContext = createContext<LocationContextProps>({
  countryCode: "US",
  setLocation: () => {},
  locationLoading: true,
  allowLocationSearch: false,
  setAllowLocationSearch: () => {},
  handleLocationRequest: () => {},
  hasLocationError: false,
  ipLocationLoading: true,
});

export const LocationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [location, setLocation] = useState<Coordinates>();
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [allowLocationSearch, setAllowLocationSearch] = useState<boolean>(true);
  const [locationError, setLocationError] = useState<LocationError>({
    error: false,
  });

  const {
    coordinates: ipLocation,
    countryCode,
    ip,
    loading: ipLocationLoading,
  } = useIpLocation();

  const options: PositionOptions = {
    enableHighAccuracy: false,
    timeout: 10000,
    maximumAge: 10000,
  };

  const handleError = (error: GeolocationPositionError) => {
    const { code } = error;

    let message: LocationError;
    if (code === 1) {
      // permission denied
      message = {
        error: true,
        message: "Please enable location services to find nearby shows.",
      };
    } else if (code === 2) {
      // position unavailable
      message = {
        error: true,
        message: "We couldn't seem to find your location, please try again.",
      };
    } else {
      // timeout
      message = {
        error: true,
        message: "We've encountered a server issue, please try again.",
      };
    }
    setLocationError(message);
    setLocationLoading(false);
  };

  const hasLocationError = locationError.error;

  const handleSuccess = (
    geolocationPosition: GeolocationPosition,
    cb?: () => void
  ) => {
    const {
      coords: { latitude, longitude },
    } = geolocationPosition;
    setLocation({ latitude, longitude });
    setLocationError({
      error: false,
    });
    setLocationLoading(false);
    if (cb) {
      cb();
    }
  };

  const handleLocationRequest = (cb?: () => void) => {
    if (!!location) setLocation(undefined);
    setLocationLoading(true);
    setLocationError({
      error: false,
    });
    navigator.geolocation.getCurrentPosition(
      (args) => handleSuccess(args, cb),
      handleError,
      options
    );
  };

  useEffect(() => {
    if (!location && allowLocationSearch) {
      handleLocationRequest();
    }
  }, []);

  const value = {
    countryCode,
    location,
    ipLocation,
    handleLocationRequest,
    hasLocationError,
    locationErrorMessage: locationError?.message,
    locationLoading,
    setLocation,
    allowLocationSearch,
    setAllowLocationSearch,
    ipaddress: ip || undefined,
    ipLocationLoading:
      typeof ipLocationLoading === "boolean" ? ipLocationLoading : true,
  };

  return (
    <LocationContext.Provider value={value}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocationContext = () => useContext(LocationContext);
