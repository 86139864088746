import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { SvgCheck } from "../Svgs/Check";
import { Button } from "../Button";
import { SvgDropdown } from "../Svgs/Dropdown";
import { ListItem as ListItemBase } from "../ListItem";
import { SelectMenu as SelectMenuBase, SelectMenuDiv } from "../SelectMenu";
import { Label, Selected, Caption, Body2 } from "../Text";
import { SvgAddAlt } from "../Svgs/AddAlt";

export type Option = {
  label: string;
  value: any;
};

type CheckIconProps = {
  isSelected: boolean;
};
const CheckIconWrapper = styled.div<CheckIconProps>`
  ${(p) =>
    p.isSelected
      ? css`
          color: ${(p) => p.theme.colors.navy};
        `
      : css`
          opacity: 0;
        `}
`;

const ListItem = styled(ListItemBase)`
  & > div {
    text-align: left !important;
  }
`;

const SelectMenu = styled(SelectMenuBase)<{ hasButton?: boolean }>`
  ${SelectMenuDiv} {
    margin-top: -44px;
  }
  ${({ hasButton }) =>
    hasButton &&
    css`
      ${SelectMenuDiv} {
        padding: 10px 0 0;
      }
    `}
`;

type ListItemWrapperProps = {
  isFirst: boolean;
};

const ListItemWrapper = styled.div<ListItemWrapperProps>`
  ${(p) =>
    !p.isFirst &&
    css`
      border-top: solid 1px ${(p) => p.theme.colors.gray3};
    `}
`;

type SelectedValueProps = {
  hasError?: boolean;
};
const SelectedValue = styled.div<SelectedValueProps>`
  background-color: #ffffff;
  text-align: center;
  width: 100%;
  height: 44px;
  border-radius: 6px;
  border: solid 1px ${(p) => p.theme.colors.black20};
  padding: 11px 12px;
  user-select: none;
  cursor: pointer;
  justify-content: space-between;
  ${(p) =>
    p.hasError &&
    css`
      border: solid 1px ${(p) => p.theme.colors.error};
    `}

  ${Selected} {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ${Body2} {
    color: #999999;
  }
`;

type WrapperProps = {
  disabled?: boolean;
};
const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;
export const IconWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Icon = styled.div`
  margin-right: 9px;
  text-align: center;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-top: 1px solid #999999;
  margin-top: 10px;
  button {
    display: flex;
    justify-content: flex-start;
    text-align: left;
    margin-left: 5px;
    svg {
      width: 17px;
    }
  }
`;

type Props = {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  value: string | undefined | null;
  hasError?: boolean;
  icon?: JSX.Element;
  helperText?: string;
  buttonText?: string;
  onChange: (v: string) => void;
  options: Option[];
  style?: any;
};
export const Select = ({
  label,
  placeholder = "Select an option...",
  value,
  icon,
  hasError,
  helperText,
  buttonText,
  onChange,
  options,
  disabled,
  ...props
}: Props) => {
  const [updatedText, setUpdatedText] = useState(false);
  const [text, setText] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setUpdatedText(true);
  }, [text]);

  useEffect(() => {
    setUpdatedText(false);
  }, [isMenuOpen]);

  const onSelect = (v: string) => {
    setText(options.find((o) => o.value === v)?.label || "");
    onChange(v);
    setIsMenuOpen(false);
  };

  const filteredOptions = updatedText
    ? options.filter((o) => o.label.toLowerCase().includes(text.toLowerCase()))
    : options;

  return (
    <Wrapper {...props} disabled={disabled}>
      {label && <Label style={{ marginBottom: 0 }}>{label}</Label>}
      <div onClick={() => setIsMenuOpen(true)}>
        <SelectedValue style={{ marginTop: 4 }} {...{ hasError, disabled }}>
          <IconWrap>
            {icon && <Icon>{icon}</Icon>}
            {!value || value === "" ? (
              <Body2>{placeholder}</Body2>
            ) : (
              <Selected>
                {options.find((o) => o.value === value)?.label || value}
              </Selected>
            )}
            <div style={{ width: "20px", textAlign: "center" }}>
              <SvgDropdown />
            </div>
          </IconWrap>
        </SelectedValue>
      </div>
      <SelectMenu
        hasButton={!!buttonText}
        isOpen={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
      >
        {filteredOptions.map((o, index) => (
          <ListItemWrapper key={index} isFirst={index === 0}>
            <ListItem
              leftIcon={
                <CheckIconWrapper isSelected={o.value === value}>
                  <SvgCheck />
                </CheckIconWrapper>
              }
              isSelected={value === o.value}
              onClick={() => onSelect(o.value)}
            >
              {o.label}
            </ListItem>
          </ListItemWrapper>
        ))}
        {buttonText && (
          <ButtonWrap>
            <Button>
              <SvgAddAlt />
              {buttonText || "Add a custom option"}
            </Button>
          </ButtonWrap>
        )}
      </SelectMenu>
      {helperText && <Caption style={{ marginTop: 4 }}>{helperText}</Caption>}
    </Wrapper>
  );
};
