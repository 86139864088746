import React, { ReactHTMLElement, ReactPropTypes, useState } from "react";
import styled, { css } from "styled-components";
import { Caption, Label } from "../Text";
import { SvgHide } from "../Svgs/Hide";
import { SvgShow } from "../Svgs/Show";

export const HTMLInput = styled.input`
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;

  background: ${(p) => p.theme.colors.white};
  border: solid 1px ${(p) => p.theme.colors.black20};
  border-radius: 6px;
  padding: 11px 12px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);

  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${(p) => p.theme.colors.black80};

  ::placeholder {
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    line-height: 17px;
    color: ${(p) => p.theme.colors.black40};
    ${(p: any) =>
      p.isDisabled &&
      css`
        color: ${(p) => p.theme.colors.gray3};
      `};
  }

  :focus {
    box-shadow: 0px 0px 8px var(--max-action);
    border: solid 1px var(--max-action);
  }

  ${(p: any) =>
    p.isDisabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: ${(p) => p.theme.colors.black40};
      border: solid 1px ${(p) => p.theme.colors.gray3};
    `};

  ${(p: any) =>
    p.hasError &&
    css`
      border: solid 2px ${(p) => p.theme.colors.error};
    `};
  ${(p: any) =>
    p.hasRightIcon &&
    css`
      padding-right: 44px;
    `};
  ${(p: any) =>
    p.hasLeftIcon &&
    css`
      padding-left: 44px;
    `};
`;

const InputIcon = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  color: ${(p) => p.theme.colors.black60};
`;

const RightIcon = styled(InputIcon)`
  right: 14px;
`;

const LeftIcon = styled(InputIcon)`
  left: 14px;
`;

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange"> & {
  label?: string;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  placeholder?: string;
  value: string;
  type?: string;
  onChange: (v: string) => void;
  onBlur?: () => void;
  isDisabled?: boolean;
  hasError?: boolean;
  onFocus?: () => void;
  helperText?: string;
};

export const TextInput = ({
  label = "",
  leftIcon,
  rightIcon,
  placeholder = "",
  value = "",
  type = "text",
  isDisabled = false,
  hasError = false,
  helperText,
  onChange,
  onBlur,
  onFocus,
  ...props
}: Props) => {
  const { passwordVisibleIcon, isPasswordVisible } =
    usePasswordVisibleIcon(type);
  const _rightIcon = passwordVisibleIcon || rightIcon;

  return (
    <div style={{ width: "100%" }} {...props}>
      {label && <Label isDisabled={isDisabled}>{label}</Label>}
      <div style={{ position: "relative", marginTop: label ? 4 : 0 }}>
        <LeftIcon
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {leftIcon}
        </LeftIcon>
        <HTMLInput
          {...{
            hasRightIcon: _rightIcon,
            hasLeftIcon: leftIcon,
            placeholder,
            isDisabled,
            onFocus,
            hasError,
            value,
          }}
          type={isPasswordVisible ? "text" : type}
          onChange={(e) => !isDisabled && onChange(e.target.value)}
          onBlur={() => onBlur && onBlur()}
        />
        <RightIcon
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {_rightIcon}
        </RightIcon>
      </div>
      {helperText && (
        <Caption hasError={hasError} style={{ marginTop: "4px" }}>
          {helperText}
        </Caption>
      )}
    </div>
  );
};

const PIcon = styled.div`
  text-align: center;
`;
const usePasswordVisibleIcon = (type: string) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  if (type !== "password")
    return { passwordVisibleIcon: null, isPasswordVisible: null };

  const passwordVisibleIcon = isPasswordVisible ? (
    <PIcon
      title="Hide"
      style={{ marginTop: "-2px" }}
      onClick={() => setIsPasswordVisible(false)}
    >
      <SvgHide />
    </PIcon>
  ) : (
    <PIcon title="Show" onClick={() => setIsPasswordVisible(true)}>
      <SvgShow />
    </PIcon>
  );
  return { isPasswordVisible, passwordVisibleIcon };
};
