import parse from "html-react-parser";
import {
  CardIcon,
  currencyFormatter,
  useActionContext,
  useDataContext,
  useIsVisible,
  useScript,
  useUserActionTrackingContext,
} from "../../../../../Components";
import styled, { css } from "styled-components";
import { Button as ButtonBase } from "../../../../../melodies-source/Button";
import { Merch } from ".";
import { Body1, Body2, H3 } from "../../../../../melodies-source/Text";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardType } from "@max/common";

export const Card = ({
  visible,
  headline,
  subtitle,
  icon,
  customIcon,
  body,
  cta,
  ctalabel,
  background,
  color,
  image,
  merch,
  songs,
  script,
  html,
  label,
}: CardType): JSX.Element | null => {
  const { handleAction } = useActionContext();
  const { setValue, total } = useDataContext();
  const isVisible = useIsVisible(visible);
  const { t } = useTranslation();
  const { track } = useUserActionTrackingContext();

  const labelString = label?.custom || label?.auto || "";

  useEffect(() => {
    if (isVisible) {
      track({
        event: "card",
        context: { label: labelString },
      });
    }
  }, [isVisible, labelString, track]);

  useScript(script);

  if (!isVisible) {
    return null;
  }
  if (html) {
    return <>{parse(html)}</>;
  }

  if (merch) {
    return (
      <Container>
        <Merch merch={merch} />
      </Container>
    );
  }

  return (
    <Box style={{ background, color }}>
      {(icon || customIcon) && (
        <Icon>
          <CardIcon icon={icon} customIcon={customIcon} />
        </Icon>
      )}
      {headline && (
        <Header
          hasBody={!!body || !!subtitle}
          count={cta !== "register" && headline.length}
        >
          {headline}
        </Header>
      )}
      {subtitle && (
        <Subtitle headlineCount={headline?.length}>{subtitle}</Subtitle>
      )}
      {body && <Body count={body.length}>{body}</Body>}
      {image && <Image src={image} />}
      {songs?.map((song) => (
        <Song>
          <Container>
            <Body1>{song.title}</Body1>
            {song.album && <Body2>{song.album}</Body2>}
          </Container>
          <Button
            onClick={() => {
              setValue({ songId: song.id });
              handleAction("register");
            }}
          >
            {t("Vote")}
          </Button>
        </Song>
      ))}
      {cta && ctalabel && (
        <Button onClick={() => handleAction(cta)}>{ctalabel}</Button>
      )}
      {cta === "payment" && !!total && (
        <Donate>
          {t("You have donated")} <Amount> {currencyFormatter(total)}</Amount>
        </Donate>
      )}
    </Box>
  );
};

const Subtitle = styled(Body1)<{ headlineCount?: number }>`
  font-family: var(--template-font-family);
  color: var(--template-text);
  margin-bottom: 24px;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  ${(p) =>
    p.headlineCount &&
    p.headlineCount > 35 &&
    css`
      font-size: 13px;
      line-height: 20px;
    `};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

const Song = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  padding: 12px 0;
  & + & {
    border-top: 2px solid #ffffff30;
  }
  ${Body1}, ${Body2} {
    color: var(--template-text);
    font-family: var(--template-font-family);
  }
  ${Body1} {
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
  }
  ${Body2} {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.6);
  }
  button {
    height: auto;
    min-width: auto;
    padding: 3px 16px 0;
    font-size: 15px;
    line-height: 20px;
    color: var(--template-secondary);
  }
`;

const Icon = styled.div`
  display: flex;
  align-self: center;
  flex: 0 0 32px;
  color: var(--template-text);
  margin-bottom: 12px;
  svg {
    width: 32px;
    height: 32px;
  }
`;

const Image = styled.img`
  height: 120px;
  width: auto;
  object-fit: contain;
  overflow: hidden;
  align-self: center;
  margin-bottom: 24px;
`;

const Box = styled(Container)`
  flex: 1 1 auto;
  padding: 0 20px 20px;
  min-height: 0;
  justify-content: center;
`;

const Button = styled(ButtonBase)<{ outlined?: boolean }>`
  background: var(--template-primary);
  border: solid 4px var(--template-primary);
  border-radius: 255px 18px 225px 20px/30px 225px 30px 255px;
  color: var(--template-secondary);
  font-family: var(--template-font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 11px 12px 9px;
  width: auto;
  min-width: 160px;
  align-self: center;

  &:hover {
    background: var(--template-primary);
    color: var(--template-secondary);
  }

  ${(p) =>
    p.outlined &&
    css`
      background: transparent;
      color: var(--template-text);
      font-family: var(--template-font-family);
      &:hover {
        background: transparent;
        color: var(--template-text);
      }
    `};
`;

const Header = styled(H3)<{ hasBody: boolean; count?: number | false }>`
  color: var(--template-text);
  margin-bottom: 8px;
  text-align: center;
  font-size: 26px;
  line-height: 32px;
  font-family: var(--template-font-family);
  margin-bottom: 16px;
  ${(p) =>
    p.count &&
    p.count > 35 &&
    css`
      font-size: 20px;
      line-height: 24px;
    `};
  ${({ hasBody }) =>
    !hasBody &&
    css`
      margin-bottom: 24px;
    `}
`;

const Body = styled(Body2)<{ count?: number }>`
  color: var(--template-text);
  font-weight: 300;
  margin-bottom: 24px;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  font-family: var(--template-font-family);
`;
const Donate = styled.div`
  text-align: center;
  color: #fff;
  padding: 5px 0 15px;
`;

const Amount = styled.div`
  padding: 10px;
  font-size: 20px;
  display: inline-block;
`;
