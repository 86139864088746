// Removes indexed keys from an object (ex: {[key: string]: string})
// @see https://stackoverflow.com/a/51955852
export type RemoveIndex<T> = {
  [K in keyof T as string extends K
    ? never
    : number extends K
    ? never
    : K]: T[K];
};

export type RemovePrefix<
  P extends string,
  S extends string,
> = S extends `${P}.${infer T}` ? T : never;

export const removeUndefined = (obj: any, drop = true) => {
  if (typeof obj === "object" && obj !== null) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === undefined) {
          if (drop) {
            delete obj[key];
          } else {
            obj[key] = null;
          }
        } else if (typeof obj[key] === "object") {
          obj[key] = removeUndefined(obj[key]);
        }
      }
    }
  }
  return obj;
};

/**
 * Confirms that the input is of type T and not undefined.  This is useful
 * as an array filter to signal to the type system that the result only
 * contains objects of type T.  Example
 *
 *    const widgets: Widget[] = [...].filter(filterUndefined)
 */
export const filterUndefined = <T>(
  t: T | undefined,
): t is Exclude<T, undefined> => {
  return t !== undefined;
};

export const onlyUnique = <T extends unknown[]>(
  value: T[number],
  index: number,
  array: T,
) => {
  return array.indexOf(value) === index;
};

/**
 * Enumerate the keys from the given object (which can also be an "enum").
 * This is convinient for looping over the keys of an object and maintining the
 * correct typings.  Usage:
 *
 *    for (const key in enumKeys(obj)) {
 *      const value = obj[key]; // This is now properly typed
 *      // Do something with value
 *    }
 */
export const enumKeys = <O extends object, K extends keyof O = keyof O>(
  obj?: O,
): K[] => {
  return Object.keys(obj || {}).filter((k) => Number.isNaN(+k)) as K[];
};

/**
 * Filters an input array `potentialValues` so that the returned array includes
 * only unique values from `allowedValues`.  This is useful when sanitizing
 * user-supplied values in an array that should conform to a const typed-array.
 * For example, consider the following:
 *
 *    const ArtistGroupRoles = ["artist", "admin", "manager"] as const;
 *    type ArtistGroupRole = (typeof ArtistGroupRoles)[number];
 *
 * and we want to sanitize a user-supplied array such that we're given an
 * array that we know only contains items from `ArtistGroupRoles`.  Usage:
 *
 *    onlyTypeArray(["foo", "artist", "bar", "artist"], ArtistGroupRoles);
 *
 * Returns: [ 'artist' ]
 *
 */
export const onlyTypeArray = <S extends string[], T extends readonly string[]>(
  potentialValues: S,
  allowedValues: T,
): T[number][] =>
  potentialValues
    .map((v) => (allowedValues.includes(v) ? v : undefined))
    .filter(onlyUnique)
    .filter(filterUndefined);
