import React, { useEffect } from "react";
import { useLocationContext } from "./LocationServices";
import { useUserActionTrackingContext } from "./UserActionTrackingContext";

export const LocationTracking: React.FC = () => {
  const {
    ipLocation,
    location,
    locationLoading,
    countryCode,
    ipaddress,
    ipLocationLoading,
  } = useLocationContext();
  const { track } = useUserActionTrackingContext();

  useEffect(() => {
    if (!locationLoading && !ipLocationLoading) {
      track({
        event: "user",
        context: {
          ipLocation: ipLocation,
          location: location,
          ipaddress: ipaddress,
          countryCode,
          userAgent: window.navigator.userAgent,
        },
      });
    }
  }, [
    locationLoading,
    ipLocation,
    track,
    ipaddress,
    countryCode,
    location,
    ipLocationLoading,
  ]);

  return null;
};
