import { INVALID_MODULE, SelectModule } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import { addRequiredValidation, generateUUID } from "./util";
import { SelectBuilderConfigModule } from "../creator";

export class AutocompleteModule extends SelectModule {
  protected _type: ModuleType = "Autocomplete";

  validate(): this is Required<AutocompleteModule> {
    return true;
  }

  toBuilderConfig(): SelectBuilderConfigModule {
    if (this.validate()) {
      return {
        actionRequired: this.actionRequired,
        type: this.type,
        id: this.id,
        options: this.options.map((opt) => ({
          id: opt.id || generateUUID(),
          label: opt.label || { en: "" },
        })),
        required: this.required,
        label: this.label,
      };
    }
    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    const { required, label, ...base } = this.toBuilderConfig();
    const surveyConfigModule: SurveyConfigModule = {
      ...base,
      actionRequired: this.actionRequired,
      header: { children: label?.[this.languageCode] ?? "" },
      options: base.options?.map((opt) => ({
        id: opt.id || generateUUID(),
        label: opt.label?.[this.languageCode],
      })),
    };
    if (required) {
      surveyConfigModule.validation = addRequiredValidation();
    }

    return surveyConfigModule;
  }

  editProps() {
    return {
      id: this.id,
      options: this.options.map((opt) => ({
        id: opt.id || generateUUID(),
        label: opt.label?.[this.languageCode],
      })),
      type: this.type,
      question: this.label[this.languageCode] ?? "",
      actionRequired: this.actionRequired,
    };
  }
}
