import styled from "styled-components";
import { SvgHelpAlt } from "../../../../melodies-source/Svgs/HelpAlt";
import { Link } from "../../../../melodies-source/Link";
import { LogoKnockout, useEventConfig } from "../../../../Components";
import { HelpIconModal } from "../../../../Components/HelpModal";
import { useTranslation } from "react-i18next";

export const Menu = () => {
  const { handleNavigateHome } = useEventConfig();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <LogoKnockout />
      <Actions>
        <Link onClick={() => handleNavigateHome()}>{t("Exit Show")}</Link>
        <HelpIconModal />
      </Actions>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${Link} {
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-right: 12px;
  }
`;

const Help = styled(SvgHelpAlt)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: #ffffff;
`;
