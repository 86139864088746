import { INVALID_MODULE, SurveyModule, SurveyModuleProps } from "./base";
import {
  SurveyConfigModule,
  DEFAULT_PROFILING_CATEGORIES,
  ModuleType,
  ProfilingCategoryId,
} from "../fanApp";
import { ProfilingBuilderConfigModule } from "../creator";

type ProfilingModuleProps = SurveyModuleProps & {
  index: number;
  force?: ProfilingCategoryId;
};
export class ProfilingModule extends SurveyModule {
  protected _type: ModuleType = "profiling";
  private _index: number;
  private _force?: ProfilingCategoryId;

  constructor({ index, force, ...rest }: ProfilingModuleProps) {
    super(rest);

    this._index = index;
    this._force = force;
  }

  get index(): number {
    return this._index;
  }

  set index(index) {
    if (index < 0) {
      throw new Error("index must be greater than or equal to 0");
    }
    this.index = index;
  }

  get force(): ProfilingCategoryId | undefined {
    return this._force;
  }

  set force(force: ProfilingCategoryId | undefined) {
    if (!!force && !DEFAULT_PROFILING_CATEGORIES.includes(force)) {
      throw new Error(`${force} is not a valid profile category`);
    }

    this.force = force;
  }

  validate(): this is Required<ProfilingModule> {
    return true;
  }

  toBuilderConfig(): ProfilingBuilderConfigModule {
    if (this.validate()) {
      const baseModule: ProfilingBuilderConfigModule = {
        index: this.index,
        type: this.type,
      };

      if (this.force) {
        baseModule.force = this.force;
      }

      return baseModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    return this.toBuilderConfig();
  }

  editProps() {
    return undefined;
  }
}
