import { useUserActionTrackingContext } from "../../../../../Components";
import { Body2 } from "../../../../../melodies-source/Text";
import styled, { css } from "styled-components";
import { SvgOpen } from "../../../../../melodies-source/Svgs/Open";
import { Merch as MerchType } from "@max/common";

export const Merch = ({ merch }: { merch: MerchType[] }) => (
  <Container>
    {merch.map((m, i) => (
      <MerchItem {...m} key={`merch-${i}`} />
    ))}
  </Container>
);

const Container = styled.div`
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  min-height: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 16px;
  margin-bottom: -16px;
  scroll-padding-left: 20px;
  scroll-padding-right: 20px;
  scroll-snap-type: x mandatory;
`;

const MerchItem = ({ name, photo, url }: MerchType) => {
  const { track } = useUserActionTrackingContext();

  return (
    <Item
      onClick={() => {
        track({
          event: "click",
          context: { type: "merch", value: name || "Unknown" },
        });
        window.open(url);
      }}
    >
      <Photo src={photo} />
      <NameContainer>
        <Name count={name?.length}>{name}</Name>
        <SvgOpen />
      </NameContainer>
    </Item>
  );
};

const Item = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
  overflow: hidden;
  position: relative;
  min-width: min(70%, 318px);
  min-height: 0;
  padding-top: 72%;
  border-radius: 12px;
  background-color: #f7fafc;
  & + & {
    margin-left: 20px;
  }
`;

const Photo = styled.img`
  overflow: hidden;
  width: 100%;
  height: calc(100% - 46px);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const NameContainer = styled.div`
  background-color: var(--template-primary);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  width: 100%;
  height: 47px;
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  ${Body2} {
  }
  svg {
    margin-left: 6px;
    width: 16px;
    height: 16px;
  }
`;

const Name = styled(Body2)<{ count?: number }>`
  color: #fff;
  font-family: var(--template-font-family);
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
`;
