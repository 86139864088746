import { DateTime } from "luxon";
import { BuilderConfigDocument, PrivacyPolicy } from "./index";
import { updateStringVars } from "./preregistration";

const LanguageTypes = ["en", "es"] as const;
export type Languages = (typeof LanguageTypes)[number];

export const generatePrivacyText = <T>(
  config: Partial<BuilderConfigDocument<T | DateTime>>,
  language: Languages = "en",
) => {
  const stringReplacements = [
    { key: "artistName", value: config.artistName || "" },
  ];
  const separateTerms = config.fields?.privacyPolicies?.content
    ?.filter(({ isSeparate }) => isSeparate)
    ?.map(
      (p) =>
        `I agree to the <a href='${p.url}' target='_blank'>${p.name} Privacy Policy</a>.`,
    );

  const policies = config.fields?.privacyPolicies?.content?.filter(
    ({ isSeparate }) => !isSeparate,
  );

  const sms = updateStringVars(smsOptIns[language], stringReplacements);
  const terms = getOptInLabel(language, config.artistName || "", policies);

  return { sms, terms, separateTerms };
};

const smsOptIns = {
  en: "I'd like to receive text or messaging service messages from {artistName} or via {artistName}'s partners or representatives. Consent is not a condition of purchase. Message data rates may apply.",
  es: "Me gustaría recibir mensajes de texto de {artistName} o a través de los socios o representantes de {artistName}. El consentimiento no es una condición de compra. Pueden aplicar tarifas de datos de mensajes.",
};

const label = {
  privacyPolicy: {
    en: "Privacy Policy",
    es: "política de privacidad",
  },
  termsOfUse: {
    en: "Terms of Use",
    es: "términos de uso",
  },
};

const labelConjunction = {
  en: "and",
  es: "y la",
};

const linkConjunction = {
  en: "and",
  es: "y",
};

const statements = {
  statement1: {
    en: `I am at least 13 years of age and agree to share the above information with {artistName} and SET. I agree to SET's {setAgreement} as well as <a href='{artistUrl}' target='_blank'>{artistName}'s Privacy Policy</a>.`,
    es: `Soy mayor de 13 años y acepto compartir la información anterior con {artistName} y SET. Acepto los {setAgreement} de SET, así como la <a href='{artistUrl}' target='_blank'>Política de privacidad de {artistName}</a>.`,
  },
  statement2: {
    en: `I am at least 13 years of age and agree to SET's {setAgreement} as well as the privacy policies of {additionalLinks}.`,
    es: `Soy mayor de 13 años y acepto los {setAgreement} de SET, así como las políticas de privacidad de {additionalLinks}.`,
  },
  statement3: {
    en: `I am at least 13 years of age and agree to share the above information with {artistName} and SET. I agree to SET's {setAgreement} as well as the privacy policies of {additionalLinks}.`,
    es: `Soy mayor de 13 años y acepto compartir la información anterior con {artistName} y SET. Acepto los {setAgreement} de SET, así como las políticas de privacidad de {additionalLinks}.`,
  },
  statement4: {
    en: `I am at least 13 years of age and agree to share the above information with {artistName} and SET and agree to SET's {setAgreement}.`,
    es: `Soy mayor de 13 años y acepto compartir la información anterior con {artistName} y SET y acepto los {setAgreement} de SET.`,
  },
};

type StatementType = "statement1" | "statement2" | "statement3" | "statement4";

const setAgreement = (lang: Languages) => `<a href="#" onclick="(function(e){
  const event = new Event('setfan-show-terms', {bubbles: true});
  e.target.dispatchEvent(event);
  e.stopPropagation();
  return false;
})(arguments[0]);return false;">${label.termsOfUse[lang]}</a>
 ${labelConjunction[lang]} <a href="#" onclick="(function(e){
  const event = new Event('setfan-show-privacy', {bubbles: true});
  e.target.dispatchEvent(event);
  e.stopPropagation();
  return false;
})(arguments[0]);return false;">${label.privacyPolicy[lang]}</a>`;

const createEntityLinks = (entities: PrivacyPolicy[], conjunction: string) => {
  return entities.map(
    (entity, index) =>
      `${
        !!entities?.length &&
        entities.length > 1 &&
        index === entities.length - 1
          ? `${conjunction} `
          : ""
      }<a href='${entity.url}' target='_blank'>${entity.name}</a>`,
  );
};

export const getOptInLabel = (
  lang: Languages,
  artistName: string,
  values?: PrivacyPolicy[],
) => {
  const firstArtistPolicyIndex = (values || [])?.findIndex(
    ({ entity }) => entity === "artist",
  );
  const artistPolicy =
    firstArtistPolicyIndex >= 0 ? values?.[firstArtistPolicyIndex] : undefined;
  const additionalPolicies = values?.filter(
    (a, i) => i !== firstArtistPolicyIndex,
  );

  const getStatement = (statement: StatementType, additionalLinks?: string) => {
    const mapObj: { [key: string]: string } = {
      "{setAgreement}": setAgreement(lang),
      "{artistName}": artistName,
      "{artistUrl}": artistPolicy?.url || "",
      "{additionalLinks}": additionalLinks || "",
    };
    const regex = new RegExp(Object.keys(mapObj).join("|"), "gi");
    let selectedStatement = statements[statement][lang];
    selectedStatement = selectedStatement.replace(
      regex,
      (matched) => mapObj[matched],
    );
    return selectedStatement;
  };

  // No policy links provided for artist or additional entities
  if (!values?.length) {
    return getStatement("statement4");
  }

  // artist has privacy policy link, but no additional entities present.
  if (!additionalPolicies?.length && artistPolicy?.url) {
    return getStatement("statement1");
  }

  // additional entities have included a privacy policy link
  const additionalLinks = createEntityLinks(values, linkConjunction[lang]);
  // more than 2 entities
  if (values.length > 2) {
    return getStatement("statement2", additionalLinks.join(", "));
  }
  // 1 or 2 entities
  return getStatement("statement3", additionalLinks.join(" "));
};
