import styled from "styled-components";
import { Card } from ".";
import { CardType } from "@max/common";

export const Cards = ({ cards }: { cards: CardType[] }) => {
  return (
    <ScrollContainer>
      {cards?.map((c, i) => (
        <Card key={i} {...c} index={i} />
      ))}
    </ScrollContainer>
  );
};

const ScrollContainer = styled.div`
  display: flex;
  max-width: 100%;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-padding-left: 20px;
  scroll-padding-right: 20px;
  padding-left: 20px;
  padding-right: 20px;
  & > * {
    scroll-snap-align: start;
  }
  scrollbar-width: thin;
  scrollbar-color: #87868666 transparent;
  ::-webkit-scrollbar {
    width: 4px;
    height: 3.5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #87868666;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;
