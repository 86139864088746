import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../../../melodies-source/Button";
import {
  Container,
  StatusIcon,
  OverflowContainer,
  Header,
  Body,
  Subtitle,
} from "./Card";
import Lottie from "react-lottie";
import * as animationData from "./checkmarkColor2.json";
import Aos from "aos";
import "aos/dist/aos.css";
import { SvgCheckLarge } from "../../../../../melodies-source/Svgs/CheckLarge";
import { SvgDownload } from "../../../../../melodies-source/Svgs/Download";
import { useEventConfig } from "../../../../../Components";
import { hexToHSL, hexToRgb, hslToRgb } from "../../../../../Utils";

export type RegisteredCardType = {
  headline?: string | React.ReactNode;
  subtitle?: string;
  body?: string | React.ReactNode;
  cta?: string;
  ctalabel?: string;
  background?: string;
  color?: string;
  image?: string;
  handleAction: (arg0: string) => void;
};

export const Registered = forwardRef<HTMLDivElement, RegisteredCardType>(
  (
    {
      headline,
      subtitle,
      body,
      image,
      cta,
      ctalabel,
      handleAction,
      background,
    }: RegisteredCardType,
    ref
  ) => {
    const [hide, setHide] = useState<boolean>(false);
    const config = useEventConfig();
    const [updatedLottie, setUpdatedLottie] = useState<string>();
    const color = config?.colors?.dark;

    useEffect(() => {
      if (hide) {
        Aos.init({
          duration: 550,
          delay: 700,
        });
      }
    }, [hide]);

    const hideLottie = () => {
      setHide(true);
    };

    useEffect(() => {
      let lottieJson = Object.assign(animationData);
      if (color) {
        let rgbCode = hexToRgb(color);
        let hslCode = hexToHSL(color);

        if (hslCode) {
          hslCode = [
            hslCode[0],
            Math.round(hslCode[1] - 5),
            Math.round(hslCode[2] + 15),
          ];
        }
        let lightColorRgb = hslToRgb(hslCode[0], hslCode[1], hslCode[2]);
        if (lottieJson) {
          if (lottieJson?.layers[1]?.shapes[0]?.it[1]?.c?.k) {
            lottieJson.layers[1].shapes[0].it[1].c.k = rgbCode;
          }
          if (lottieJson?.layers[2]?.shapes[0]?.it[2]?.c?.k) {
            lottieJson.layers[2].shapes[0].it[2].c.k = lightColorRgb;
          }
          setUpdatedLottie(lottieJson);
        }
      }
    }, [color]);

    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: updatedLottie ? updatedLottie : "",
      rendererSettings: {},
    };

    const propsFade = {
      "data-aos": "fade-up",
      "data-aos-easing": "ease-in-out",
      "data-aos-once": "true",
    };

    const propsIcon = {
      "data-aos": "zoom-in",
      "data-aos-easing": "ease-in-out",
      "data-aos-once": "true",
    };

    return (
      <Container ref={ref} style={{ background, color }}>
        <StatusIcon {...propsIcon}>
          <SvgCheckLarge />
        </StatusIcon>
        <OverflowContainer id="lottie">
          {!hide && cta !== "payment" && updatedLottie && (
            <StyledLottie
              hide={hide}
              options={defaultOptions}
              height={150}
              width={120}
              eventListeners={[
                {
                  eventName: "complete",
                  callback: hideLottie,
                },
              ]}
            />
          )}
          {headline && (
            <Header
              {...propsFade}
              count={typeof headline === "string" ? headline.length : undefined}
            >
              {headline}
            </Header>
          )}
          {subtitle && <Subtitle {...propsFade}>{subtitle}</Subtitle>}
          {body && (
            <Body
              {...propsFade}
              count={typeof body === "string" ? body.length : undefined}
            >
              {body}
            </Body>
          )}
          {image && <Image {...propsFade} src={image} alt="album cover" />}
          {cta && ctalabel && (
            <CtaButton
              {...propsFade}
              onClick={() => handleAction(cta)}
              variant="secondary"
            >
              {ctalabel === "Save" && <SvgDownload />} {ctalabel}
            </CtaButton>
          )}
        </OverflowContainer>
      </Container>
    );
  }
);

const StyledLottie = styled(Lottie)<{ hide: boolean }>`
  ${({ hide }) => hide && "display: none;"}
`;
export const Image = styled.img`
  margin-bottom: 16px;
  width: 100px;
  border-radius: 16px;
`;
export const CtaButton = styled(Button)<{ cta?: boolean }>`
  color: var(--template-text);
  width: auto;
  min-width: 120px;
  height: 36px;
  padding: 6px 16px;
  background-color: var(--template-primary);
  background: var(--template-primary);
  border-radius: 18px;
  border: 2px solid var(--template-text);
  font-family: var(--template-font-family);
  line-height: 24px;
  svg {
    margin: 0 8px 0 -10px;
    width: 17px;
    height: 17px;
    color: var(--template-text);
  }
  :hover {
    border: 2px solid var(--template-text);
  }
`;
