import { INVALID_MODULE, LabeledModule, LabeledModuleProps } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import { isValidUrl } from "./util";
import {
  ImageBuilderConfigModule,
  TranslateableModuleContent,
} from "../creator";

type ImageModuleProps = LabeledModuleProps & {
  src: TranslateableModuleContent;
};
export class ImageModule extends LabeledModule {
  protected _type: ModuleType = "image";
  private _src: TranslateableModuleContent;

  constructor({ src, ...rest }: ImageModuleProps) {
    super(rest);

    this._src = src;
  }

  get src(): TranslateableModuleContent | undefined {
    return this._src;
  }

  set src(src) {
    if (
      !src?.[this.languageCode] ||
      !isValidUrl(src?.[this.languageCode] ?? "")
    ) {
      throw new Error(`invalid url provided for src: ${src}`);
    }
    this._src = src;
  }

  validate(): this is Required<ImageModuleProps> {
    return true;
  }

  toBuilderConfig(): ImageBuilderConfigModule {
    if (this.validate()) {
      return {
        actionRequired: this.actionRequired,
        id: this.id,
        src: this.src,
        label: this.label,
        style: this.style,
        type: this.type,
      };
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    if (this.validate()) {
      return {
        actionRequired: this.actionRequired,
        id: this.id,
        src: this.src[this.languageCode],
        style: this.style,
        type: this.type,
      };
    }

    throw new Error(INVALID_MODULE(this));
  }

  editProps() {
    if (this.validate()) {
      return {
        id: this.id,
        image: { src: this.src[this.languageCode] ?? "" },
        type: this.type,
        question: this.label[this.languageCode] ?? "",
        actionRequired: this.actionRequired,
      };
    }
  }
}
