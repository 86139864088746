import React from "react";
import styled, { css } from "styled-components";
import Portal from "../common/Portal";
import { useOverlay } from "../utils/hooks";
import { SvgLeft } from "../Svgs/Left";

const BackIcon = styled(SvgLeft)(
  ({ theme: { colors, css: c } }) => css`
    color: ${colors.black60};
  `
);

const Container = styled.div(
  ({ theme: { colors } }) => css`
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    position: fixed;
    background: ${colors.white};
    padding: 16px 18px;
    z-index: 1000;
  `
);

const Body = styled.div`
  height: calc(100vh - 78px);
`;

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element;
};
export const FullscreenModal = ({ isOpen, onClose, children }: Props) => {
  useOverlay(isOpen);
  Math.random().toString(36).substring(2);
  return (
    <>
      {isOpen && (
        <Portal>
          <Container>
            <div
              style={{
                cursor: "pointer",
                height: "30px",
                marginBottom: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BackIcon />
            </div>
            <Body>{children}</Body>
          </Container>
        </Portal>
      )}
    </>
  );
};
