import {
  currencyFormatter,
  useActionContext,
  useDataContext,
  useIsVisible,
  useScript,
  useScrollGradient,
  useUserActionTrackingContext,
} from "../../../../../Components";
import styled, { css } from "styled-components";
import { Button } from "../../../../../melodies-source/Button";
import { Merch } from ".";
import { SvgDownload } from "../../../../../melodies-source/Svgs/Download";
import parse from "html-react-parser";
import { SvgForward } from "../../../../../melodies-source/Svgs/Forward";
import { Body1 } from "../../../../../melodies-source/Text";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardType, UserEventStates } from "@max/common";

export const Card = ({
  visible,
  headline,
  subtitle,
  body,
  cta,
  ctalabel,
  background,
  color,
  image,
  merch,
  songs,
  script,
  html,
  label,
}: CardType): JSX.Element | null => {
  const { handleAction } = useActionContext();
  const { total, setValue } = useDataContext();
  const isVisible = useIsVisible(visible);
  const { data } = useDataContext();
  const [srollRef, handleScroll, hasScroll, atBottom] = useScrollGradient();
  const isNotRegistered = data.status === UserEventStates.draft;
  const { t } = useTranslation();

  const { track } = useUserActionTrackingContext();

  const labelString = label?.custom || label?.auto || "";

  useEffect(() => {
    if (isVisible) {
      track({
        event: "card",
        context: { label: labelString },
      });
    }
  }, [isVisible, track, labelString]);

  useScript(script);

  if (!isVisible) {
    return null;
  }

  if (html) {
    const res = parse(html);
    if (res) {
      return <>{res}</>;
    }
    return null;
  }

  return (
    <Container style={{ background, color }}>
      {headline && !merch && (
        <Header
          hasBody={!!body || !!subtitle}
          count={cta !== "register" && headline.length}
        >
          {headline}
        </Header>
      )}
      {subtitle && (
        <Subtitle headlineCount={headline?.length}>{subtitle}</Subtitle>
      )}
      {!!songs && (
        <SongWrapper
          scroll={!!songs?.length}
          ref={srollRef}
          onScroll={handleScroll}
          atBottom={atBottom}
        >
          {songs?.map(({ title, album, id: songId }) => (
            <Song
              title={title}
              album={album!}
              onClick={() => {
                setValue({ songId });
                handleAction("register");
              }}
            />
          ))}

          {!atBottom && hasScroll && (
            <ScrollButton isDisabled={true}>
              <SvgForward />
            </ScrollButton>
          )}
        </SongWrapper>
      )}
      {body && <Body count={body.length}>{body}</Body>}
      {image && <Image style={{ marginBottom: "20px" }} src={image} />}
      {cta && !songs?.length && ctalabel && (
        <CtaButton
          cta={isNotRegistered || cta === "payment"}
          saveBtn={cta === "social"}
          onClick={() => handleAction(cta)}
          variant="secondary"
        >
          {cta === "social" && <SvgDownload />}
          {ctalabel}
        </CtaButton>
      )}
      {cta === "payment" && !!total && (
        <Donate>
          {t("You have donated")} <Amount> {currencyFormatter(total)}</Amount>
        </Donate>
      )}
      {merch && <Merch merch={merch} />}
    </Container>
  );
};
const Song = ({
  title,
  album,
  onClick,
}: {
  title: string;
  album?: string;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <SongContainer>
      <div>
        <SongTitle>{title}</SongTitle>
        <SongBody>{album}</SongBody>
      </div>
      <VoteButton onClick={onClick}>{t("Vote")}</VoteButton>
    </SongContainer>
  );
};

const Subtitle = styled(Body1)<{ headlineCount?: number }>`
  font-family: var(--template-font-family);
  color: var(--template-text);
  margin-top: -4px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  ${(p) =>
    p.headlineCount &&
    p.headlineCount > 40 &&
    css`
      margin-left: 24px;
      margin-right: 24px;
      font-size: 14px;
      line-height: 20px;
    `};
`;

const Container = styled.div<{ scroll?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 20px;
  justify-content: center;
  flex-grow: 1;
`;

const Header = styled.div<{ hasBody: boolean; count?: number | false }>`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin: 10px auto 16px;
  text-align: center;
  font-family: var(--template-font-family);
  ${(p) =>
    p.count &&
    p.count > 40 &&
    css`
      font-size: 20px;
      line-height: 28px;
    `};
  ${({ hasBody }) =>
    !hasBody &&
    css`
      font-size: 20px;
      line-height: 24px;
      padding: 0 20px 8px;
    `}
`;

const Donate = styled.div`
  text-align: center;
  color: #fff;
  padding: 5px 0 15px;
`;

const Amount = styled.div`
  text-align: center;
  padding: 10px;
  font-size: 20px;
  display: inline-block;
`;

const Body = styled.div<{ count?: number }>`
  margin-bottom: 24px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  font-family: var(--template-font-family);
`;

const SongContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #424040;
  padding: 8px 0;
`;

const SongTitle = styled.div`
  font-family: var(--template-font-family);
  text-align: left;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
`;

const SongBody = styled.div`
  font-family: var(--template-font-family);
  text-align: left;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
`;

const VoteButton = styled(Button)`
  height: 28px;
  width: 60px;
  color: #fff;
  border: none;
  font-size: 15px;
  line-height: 20px;
  flex-shrink: 0;
  font-family: var(--template-font-family);
  background-color: var(--template-primary);
  border-radius: 0px;
  margin-left: 10px;
  padding: 4px 12px;
  :hover,
  :active {
    background-color: var(--template-primary);
  }
`;

export const CtaButton = styled(Button)<{ cta?: boolean; saveBtn?: boolean }>`
  color: #fff;
  width: auto;
  min-width: 180px;
  height: 51px;
  margin: 0 auto;
  background-color: var(--template-primary);
  font-size: 18px;
  border: none;
  border-radius: 0px;
  font-family: var(--template-font-family);
  padding: 10px 16px;
  svg {
    margin: 0 8px 0 -10px;
    width: 21px;
    height: 21px;
    color: var(--template-primary);
    background-color: var(--template-text);
    border-radius: 15px;
    padding: 4px;
  }
  ${({ cta }) =>
    !cta &&
    css`
      background: var(--template-primary);
      border: none;
      border-radius: 30px;
      color: #fff;
      height: 34px;
      min-width: 130px;
      font-size: 16px;
      font-weight: 400;
    `}
  ${({ saveBtn }) =>
    saveBtn &&
    css`
      background: #000;
      border: 2px solid var(--template-primary);
      border-radius: 0px;
      color: #fff;
      min-width: 130px;
      height: 40px;
      font-size: 16px;
      font-weight: 400;
      :hover {
        border: 2px solid var(--template-primary);
      }
      svg {
        margin: 0 8px 0 -10px;
        width: 26px;
        height: 26px;
        color: #fff;
        background-color: #000;
      }
    `}
`;

const Image = styled.img`
  width: 130px;
  margin: 0 auto;
  border-radius: 11px;
`;

const SongWrapper = styled.div<{ scroll?: boolean; atBottom: boolean }>`
  display: none;
  ${({ scroll }) =>
    scroll &&
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 250px;
      overflow-y: scroll;
      scrollbar-width: thin;
      scrollbar-color: transparent;
      ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 3px;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
    `}
`;

const ScrollButton = styled(Button)`
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  margin: 0 auto 15px auto;
  background-color: #000;
  padding: 4px 2px 2px 2px;
  font-size: 11px;
  letter-spacing: 0.5px;
  height: 25px;
  width: 25px;
  font-weight: 100;
  color: #ffffff78;
  border-radius: 15px;
  border: 1.5px solid #ffffff81;
  svg {
    color: #ffffff90;
    transform: rotate(90deg);
  }
  :active {
    background-color: var(--template-primary);
  }
  :hover {
    background-color: var(--template-primary);
  }
`;
