import { useInView } from "react-intersection-observer";
import {
  useActionContext,
  useDataContext,
  useIsVisible,
  currencyFormatter,
  useScript,
  CardIcon,
  useUserActionTrackingContext,
} from "../../../../../Components";
import styled, { css } from "styled-components";
import { Button } from "../../../../../melodies-source/Button";
import { Merch } from ".";
import { SvgAccordionOpen } from "../../../../../melodies-source/Svgs/AccordionOpen";
import { Registered } from "./Registered";
import parse from "html-react-parser";
import { useScrollGradient } from "../../../../../Components/useScrollGradient";
import { Body1 } from "../../../../../melodies-source/Text";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardType, UserEventStates } from "@max/common";

export const Card = ({
  visible,
  headline,
  subtitle,
  body,
  cta,
  icon,
  customIcon,
  ctalabel,
  background,
  color,
  image,
  merch,
  html,
  script,
  songs = [],
  label,
  index,
}: CardType & { index: number }): JSX.Element | null => {
  const { handleAction } = useActionContext();
  const { total, data, setValue } = useDataContext();
  const isVisible = useIsVisible(visible);
  const isNotRegistered = data.status === UserEventStates.draft;
  const [srollRef, handleScroll, hasScroll, atBottom] = useScrollGradient();
  const { track } = useUserActionTrackingContext();
  const { t } = useTranslation();

  const { inView, ref } = useInView({ threshold: 0.8 });

  const labelString = label?.custom || label?.auto || "";

  useEffect(() => {
    if (inView) {
      track({
        event: "card",
        context: { label: labelString },
      });
    }
  }, [inView, track, labelString]);

  useScript(script);

  if (!isVisible) {
    return null;
  }

  if (html) {
    const res = parse(html);
    if (res) {
      return (
        <>
          <div ref={ref} style={{ position: "relative", left: "170px" }}></div>
          {res}
        </>
      );
    }
    return null;
  }

  if (!isNotRegistered && index === 1) {
    return (
      <Registered
        headline={headline}
        subtitle={subtitle}
        body={body}
        image={image}
        cta={cta}
        ctalabel={ctalabel}
        handleAction={handleAction}
        background={background}
        color={color}
        ref={ref}
      />
    );
  }

  return (
    <Container
      ref={ref}
      scroll={songs?.length > 4}
      style={{ background, color }}
    >
      {(icon || customIcon) && (
        <StatusIcon merch={!!merch}>
          <CardIcon icon={icon} customIcon={customIcon} />
        </StatusIcon>
      )}
      {headline && (
        <Header
          style={merch && { marginTop: 8, marginBottom: 8 }}
          count={headline.length}
        >
          {headline}
        </Header>
      )}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {merch ? (
        <Merch merch={merch} />
      ) : (
        <>
          <ScrollContainer
            ref={srollRef}
            onScroll={handleScroll}
            hasScroll={hasScroll}
            atBottom={atBottom}
          >
            {songs?.map(({ title, album, id: songId }) => (
              <Song
                key={songId}
                title={title}
                album={album!}
                onClick={() => {
                  setValue({ songId });
                  handleAction("register");
                }}
              />
            ))}
            {body && <Body count={body.length}>{body}</Body>}
            {image && <Image src={image} alt="album cover" />}
            {cta && ctalabel && (
              <CtaButton
                onClick={() => handleAction(cta)}
                cta={isNotRegistered}
                variant="secondary"
              >
                {ctalabel}
              </CtaButton>
            )}
            {!!total && cta === "payment" && (
              <Donate>
                {t("You have donated")}{" "}
                <Amount>{currencyFormatter(total)}</Amount>
              </Donate>
            )}
          </ScrollContainer>
          {hasScroll && !atBottom && (
            <>
              <Gradient primary></Gradient>
              <Gradient></Gradient>
              <ScrollButton isDisabled={true}>
                <SvgAccordionOpen />
              </ScrollButton>
            </>
          )}
        </>
      )}
    </Container>
  );
};

const Song = ({
  title,
  album,
  onClick,
}: {
  title: string;
  album?: string;
  onClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <SongContainer>
      <div>
        <SongTitle>{title}</SongTitle>
        {album && <SongBody>{album}</SongBody>}
      </div>
      <VoteButton onClick={onClick}>{t("Vote")}</VoteButton>
    </SongContainer>
  );
};

export const Container = styled.div<{ scroll?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--template-primary);
  color: var(--template-text);
  min-height: 360px;
  aspect-ratio: 304/440;
  border-radius: 16px;
  z-index: 10;
  padding: 24px;
  position: relative;
  min-width: min(87%, 380px);
  margin: 20px 0 20px 0;
  & + & {
    margin-left: 20px;
  }
`;
const Gradient = styled.div<{ primary?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15%;
  border-radius: 0 0 16px 16px;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 18%,
    rgba(4, 0, 0, 0.1) 53%,
    rgba(1, 0, 0, 0.2) 66%,
    rgba(0, 0, 0, 0.35) 89%
  );
  ${({ primary }) =>
    primary &&
    `
       background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 18%,
    var(--template-primary) 73%,
    var(--template-primary) 76%,
    var(--template-primary) 79%
  );
    `}
`;
const Donate = styled.div`
  text-align: left;
  color: #fff;
  padding: 5px 0 15px;
`;

const Amount = styled.div`
  padding: 10px;
  font-size: 20px;
  display: inline-block;
`;
export const OverflowContainer = styled.div`
  overflow: hidden;
`;
export const Header = styled.div<{ count?: number }>`
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  color: var(--template-text);
  margin: 16px 0;
  font-family: var(--template-font-family);
  ${(p) =>
    p.count &&
    p.count > 40 &&
    css`
      font-size: 23px;
      line-height: 29px;
    `};
`;

export const Subtitle = styled(Body1)`
  font-family: var(--template-font-family);
  color: var(--template-text);
  margin-top: -8px;
  margin-bottom: 12px;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
`;
export const Body = styled.div<{ count?: number }>`
  color: var(--template-text);
  margin-bottom: 24px;
  font-size: 19px;
  line-height: 25px;
  font-weight: 400;
  font-family: var(--template-font-family);
`;
const CtaButton = styled(Button)<{ cta?: boolean }>`
  color: var(--template-text);
  width: 100%;
  height: 40px;
  background: var(--template-primary);
  padding: 6px 16px;
  border-radius: 20px;
  border: 2px solid var(--template-text);
  font-family: var(--template-font-family);
  line-height: 24px;
  margin-top: 8px;
  :hover {
    border: 2px solid var(--template-text);
  }
  ${({ cta }) =>
    cta &&
    `background: var(--template-text);
     border: 2px solid var(--template-text);
     color: var(--template-primary);
  `}
`;
export const StatusIcon = styled.div<{ merch?: boolean }>`
  background-color: white;
  border-radius: 25px;
  border: 2px solid var(--template-primary);
  width: 36px;
  height: 36px;
  position: absolute;
  top: -16px;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  & > svg {
    flex-shrink: 0;
    color: var(--template-primary);
    width: 24px;
    height: 24px;
    ${({ merch }) =>
      merch &&
      `
    width: 28px;
    height: 28px;
    `}
  }
`;
export const Image = styled.img`
  margin-bottom: 20px;
  width: 110px;
  border-radius: 16px;
`;
const SongContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  & + & {
    border-top: 1px solid #ffffff6c;
  }
`;
const SongTitle = styled.div`
  text-align: left;
  font-weight: 700;
  font-size: 15px;
  line-height: 22px;
  font-family: var(--template-font-family);
`;
const SongBody = styled.div`
  text-align: left;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  font-family: var(--template-font-family);
`;
const VoteButton = styled(Button)`
  height: 28px;
  width: 54px;
  color: var(--template-primary);
  font-size: 13px;
  line-height: 20px;
  padding: 4px 12px;
  flex-shrink: 0;
  background-color: #fff;
  border-radius: 20px;
  margin-left: 10px;
  :active {
    background-color: #fff;
  }
  :hover {
    background-color: #fff;
  }
`;
const ScrollContainer = styled.div<{ hasScroll: boolean; atBottom: boolean }>`
  min-height: 0;
  overflow-y: scroll;
  max-width: 400px;
  padding-right: 13px;
  margin-right: -15px;
  flex-grow: 1;
  & > * {
    scroll-snap-align: start;
  }
  scrollbar-width: 1px;
  scrollbar-color: transparent;
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;
const ScrollButton = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto 15px auto;
  background-color: var(--template-primary);
  padding: 4px 2px 2px 2px;
  font-size: 11px;
  letter-spacing: 0.5px;
  height: 25px;
  width: 25px;
  font-weight: 100;
  color: #ffffff78;
  border-radius: 15px;
  border: 1.5px solid #ffffff6d;
  svg {
    color: #ffffff;
    transform: rotate(0deg);
  }
  :active {
    background-color: var(--template-primary);
  }
  :hover {
    background-color: var(--template-primary);
  }
`;
