import { ContainerBuilderConfigModule } from "../creator";
import { ModuleType, SurveyConfigModule } from "../fanApp";
import { INVALID_MODULE, SurveyModule, SurveyModuleProps } from "./base";

export type ContainerModuleProps = SurveyModuleProps & {
  modules?: SurveyModule[];
};

export class ContainerModule extends SurveyModule {
  protected _type: ModuleType = "container";

  modules: SurveyModule[];

  constructor({ modules = [], ...rest }: ContainerModuleProps = {}) {
    super(rest);

    this.modules = modules;
  }

  validate(): this is Required<ContainerModule> {
    return true;
  }

  toBuilderConfig(): ContainerBuilderConfigModule {
    if (this.validate()) {
      const builderConfigModule: ContainerBuilderConfigModule = {
        id: this.id,
        modules: this.modules.map((mod) => mod.toBuilderConfig()),
        noBorder: this.noBorder,
        style: this.style,
        type: this.type,
      };
      if (this.tag) {
        builderConfigModule.tag = this.tag;
      }
      return builderConfigModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    if (this.validate()) {
      const surveyConfigModule: SurveyConfigModule = {
        id: this.id,
        modules: this.modules.flatMap((mod) => mod.toSurveyConfig()),
        noBorder: this.noBorder,
        style: this.style,
        type: this.type,
      };
      if (this.tag) {
        surveyConfigModule.tag = this.tag;
      }
      return surveyConfigModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  editProps() {
    return undefined;
  }
}
