import * as React from "react";
import { SVGProps } from "react";
export const SvgCheckLarge = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20.2691073,5.75445875 C20.6764654,6.17495126 20.6764654,6.84288374 20.2691073,7.26337625 L8.89908477,19 L3.73089273,13.665171 C3.32353458,13.2446785 3.32353458,12.576746 3.73089273,12.1562535 C4.09189714,11.7836093 4.66865722,11.7462343 5.07277274,12.0502907 L5.1814537,12.144714 L5.1814537,12.144714 L8.89908477,15.9813259 L18.807293,5.75443084 C19.1682924,5.38182032 19.7450231,5.34446933 20.1491054,5.64852491 L20.2691073,5.75445875 L20.2691073,5.75445875 Z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
