import { RefObject, useEffect, useRef, useState } from "react";

export const useScrollGradient: () => [
  RefObject<HTMLDivElement>,
  (e: any) => any,
  boolean,
  boolean,
  boolean
] = () => {
  const [hasScroll, setHasScroll] = useState<boolean>(false);
  const [atBottom, setAtBottom] = useState<boolean>(false);
  const [atTop, setAtTop] = useState<boolean>(true);
  const container = useRef<HTMLDivElement>(null);
  const handleScroll = ({
    target: { offsetHeight, scrollTop, scrollHeight },
  }: {
    target: HTMLDivElement;
  }) => {
    if (offsetHeight + scrollTop >= scrollHeight - 7) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
    if (scrollTop <= 2) {
      setAtTop(true);
    } else {
      setAtTop(false);
    }
  };
  useEffect(() => {
    if (
      container.current &&
      container.current.clientHeight < container.current.scrollHeight - 7
    ) {
      setHasScroll(true);
    }
  }, []);
  return [container, handleScroll, hasScroll, atBottom, atTop];
};
