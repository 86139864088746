import { useUserActionTrackingContext } from "../../../../../Components";
import { Body2 } from "../../../../../melodies-source/Text";
import styled, { css } from "styled-components";
import { SvgOpen } from "../../../../../melodies-source/Svgs/Open";
import { Merch as MerchType } from "@max/common";

export const Merch = ({ merch }: { merch: MerchType[] }) => (
  <Container>
    {merch.map((m, i) => (
      <MerchItem {...m} key={`merch-${i}`} />
    ))}
  </Container>
);

const Container = styled.div`
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  min-height: 0;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 12px;
  scroll-padding-left: 20px;
  scroll-padding-right: 20px;
  scroll-snap-type: x mandatory;

  scrollbar-width: thin;
  scrollbar-color: #404658 transparent;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    opacity: 0.3;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #404658;
    opacity: 0.3;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const MerchItem = ({ name, photo, url }: MerchType) => {
  const { track } = useUserActionTrackingContext();
  return (
    <Item
      onClick={() => {
        track({
          event: "click",
          context: { type: "merch", value: name || "Unknown" },
        });
        window.open(url);
      }}
    >
      <Photo src={photo} />
      <Name count={name?.length}>
        <Body2>{name}</Body2>
        <SvgOpen />
      </Name>
    </Item>
  );
};

const Item = styled.div`
  background-color: #666666;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  scroll-snap-align: start;
  overflow: hidden;
  position: relative;
  min-width: min(70%, 318px);
  border-radius: 35px 5px 20px 2px/2px 45px 6px 45px;
  min-height: 0;
  padding-top: 72%;
  & + & {
    margin-left: 20px;
  }
`;

const Photo = styled.img`
  overflow: hidden;
  width: 100%;
  height: calc(100% - 46px);
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const Name = styled.div<{ count?: number }>`
  background-color: var(--template-primary);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 0;
  width: 100%;
  height: 47px;
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  ${Body2} {
    color: #fff;
    font-family: var(--template-font-family);
    font-size: 15px;
    line-height: 18px;
    ${(p) =>
      p.count &&
      p.count > 25 &&
      css`
        font-size: 14px;
      `};
  }
  svg {
    color: #fff;
    margin-left: 6px;
    margin-bottom: 1px;
    width: 16px;
    height: 16px;
  }
`;
