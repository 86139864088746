import * as React from "react";
import { SVGProps } from "react";
export const SvgLocationAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 7.71429C16 4.55837 13.3137 2 10 2C6.68629 2 4 4.55837 4 7.71429C4 10.8702 6.4 14.5714 10 18C13.6 14.5714 16 10.8702 16 7.71429ZM10 5C8.34315 5 7 6.34315 7 8C7 9.65685 8.34315 11 10 11C11.6569 11 13 9.65685 13 8C13 6.34315 11.6569 5 10 5Z"
      fillRule="evenodd"
      fill="currentColor"
    />
  </svg>
);
