import { useUserActionTrackingContext } from "../../../../../Components";
import styled from "styled-components";

import { Merch as MerchType } from "@max/common";

export const Merch = ({ merch }: { merch: MerchType[] }) => (
  <Container>
    {merch.map((m, i) => (
      <MerchItem {...m} key={i} />
    ))}
  </Container>
);
const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin-left: -20px;
  margin-right: -20px;
  padding-right: 20px;
  padding-left: 20px;
  scroll-padding-right: 20px;
  scroll-padding-left: 20px;
  padding-bottom: 10px;
  scrollbar-width: thin;
  scrollbar-color: #ffffffad;
  scroll-snap-type: x mandatory;
  ::-webkit-scrollbar {
    width: 7px;
    height: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ffffffad;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const MerchItem = ({ name, photo, url }: MerchType) => {
  const { track } = useUserActionTrackingContext();
  return (
    <Item
      onClick={() => {
        track({
          event: "click",
          context: { type: "merch", value: name || "Unknown" },
        });
        window.open(url);
      }}
    >
      <Photo src={photo} />
      <NameContainer>{name}</NameContainer>
    </Item>
  );
};
const Item = styled.div`
  background: #f2f5f7;
  position: relative;
  cursor: pointer;
  display: flex;
  background-size: cover;
  border-radius: 12px;
  width: 200px;
  flex-shrink: 0;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  scroll-snap-align: start;
  & + & {
    margin-left: 20px;
  }
`;

const Photo = styled.img`
  height: 250px;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const NameContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--template-primary);
  color: #000;
  font-family: var(--template-font-family);
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  padding: 13px;
  width: 100%;
`;
