import { INVALID_MODULE, SelectModule, SelectModuleProps } from "./base";
import { SurveyConfigModule, SurveyConfigOption, ModuleType } from "../fanApp";
import { addRequiredValidation, generateUUID } from "./util";
import {
  BuilderConfigOption,
  SingleSelectBuilderConfigModule,
} from "../creator";

type SingleSelectQuestionProps = SelectModuleProps & {
  /**
   * we use this flag to keep a module from being shown to the user and from being validated on submit.
   * currently, this is only useable on single select modules since we use it to inject eventIds into
   * the survey form opaquely
   */
  hidden?: boolean;
};

export class SingleSelectQuestionModule extends SelectModule {
  protected _type: ModuleType = "SingleSelectQuestion";
  hidden: boolean;

  constructor({ hidden = false, ...rest }: SingleSelectQuestionProps) {
    super(rest);
    this.hidden = hidden;
  }

  validate(): this is Required<SingleSelectQuestionModule> {
    return true;
  }

  toBuilderConfig(): SingleSelectBuilderConfigModule {
    if (this.validate()) {
      const userValues: string[] = [];

      const builderModule: SingleSelectBuilderConfigModule = {
        actionRequired: this.actionRequired,
        id: this.id,
        label: this.label,
        options: this.options.map((opt) => {
          const option: BuilderConfigOption = {
            id: opt.id || generateUUID(),
            label: opt.label,
          };
          if (opt.userDefined) {
            userValues.push(opt.id || generateUUID());
            option.userDefined = true;
          }
          return option;
        }),
        required: this.required,
        type: this.type,
      };

      if (this.hidden) {
        this.hidden = true;
      }

      return builderModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    if (this.validate()) {
      const userValues: string[] = [];

      const configModule: SurveyConfigModule = {
        actionRequired: this.actionRequired,
        id: this.id,
        header: { children: this.label[this.languageCode] ?? "" },
        options: this.options.map((opt) => {
          const option: SurveyConfigOption = {
            id: opt.id || generateUUID(),
            label: opt.label?.[this.languageCode],
          };
          if (opt.userDefined) {
            userValues.push(opt.id || generateUUID());
            option.userDefined = true;
          }
          return option;
        }),
        type: this.type,
        noBorder: this.noBorder,
      };

      if (this.required) {
        configModule.validation = addRequiredValidation();
      }

      if (this.hidden) {
        this.hidden = true;
      }

      return configModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  editProps() {
    return {
      id: this.id,
      options: this.options.map((opt) => ({
        id: opt.id || generateUUID(),
        label: opt.label?.[this.languageCode],
        userDefined: opt.userDefined,
      })),
      type: this.type,
      question: this.label[this.languageCode] ?? "",
      actionRequired: this.actionRequired,
    };
  }
}
