/// <reference types="styled-components/cssprop" />
import React from "react";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import { colors } from "./colors";
import { fonts } from "./fonts";
import "./reset.css";
import { mediaQueries, mediaQueryBreakpoints } from "./mediaQueries";
import GlobalStyle from "./GlobalStyle";

export { colors };

export const theme = {
  colors,
  fonts,
  mediaQueries,
  mediaQueryBreakpoints,
};

export type ThemeType = typeof theme;

export const Theme = ({
  children,
  ...props
}: {
  children?: React.ReactNode;
  theme?: ThemeType;
}) => {
  const theme_ = { ...theme, ...props.theme };
  const gFontUrl =
    "https://fonts.googleapis.com/css2?family=" +
    theme_.fonts.families.join("&family=") +
    "&display=swap";
  return (
    <ThemeProvider {...props} theme={theme_}>
      <GlobalStyle />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link href={gFontUrl} rel="stylesheet"></link>
      </Helmet>
      {children}
    </ThemeProvider>
  );
};
