import { useEffect } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

import { NavHeader } from ".";
import { H4 } from "../melodies-source/Text";
import { Link } from "../melodies-source/Link";

export const Terms = ({
  onClose,
}: {
  onClose?: VoidFunction;
  setModal?: (v: string) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!onClose) {
    const goBackPath = location?.state?.back;
    onClose = goBackPath ? () => navigate(-1) : () => navigate("/");
  }

  return (
    <Wrapper>
      <NavHeader onClose={onClose} />
      <Content>
        <H4 style={{ textAlign: "center" }}>
          Music Audience Exchange, Inc.
          <br />
          SET Terms of Use
        </H4>
        <p>
          THESE WEBSITE TERMS OF USE (“<strong>TERMS</strong>”) GOVERN YOUR USE
          OF THE MUSIC AUDIENCE EXCHANGE, INC. WEBSITE (THE “
          <strong>SITE</strong>
          ”) OPERATED, PROVIDED BY, OR MANAGED BY MUSIC AUDIENCE EXCHANGE, INC.
          AND ITS SUBSIDIARIES AND OWNED BRANDS (COLLECTIVELY, “
          <strong>MAX</strong>
          ”). PLEASE READ THESE TERMS BEFORE USING OR CONTINUING TO USE THE
          SITE. BY USING OR CONTINUING TO USE THE SITE, YOU AFFIRM THAT YOU (A)
          ARE AT LEAST 18 YEARS OLD AND RESIDE IN THE UNITED STATES, OR ARE OF
          LEGAL AGE TO FORM A BINDING CONTRACT IN YOUR TERRITORY OR COUNTRY OF
          RESIDENCE, AND (B) AGREE TO BE LEGALLY BOUND AND ABIDE BY ALL TERMS
          AND CONDITIONS CONTAINED IN THESE TERMS. IF YOU DO NOT AGREE TO BE
          BOUND BY THESE TERMS, YOU MUST NOT USE THE SITE. THESE TERMS MAY BE
          UPDATED FROM TIME TO TIME.
        </p>
        <p>
          PLEASE NOTE THAT IF YOU ARE USING THE SITE AS A BRAND OR AN ARTIST AND
          YOU HAVE AN ACTIVE [SERVICES AGREEMENT] WITH MAX, ADDITIONAL AND/OR
          DIFFERENT TERMS MAY APPLY TO YOUR USE OF THE MAX SERVICES. PLEASE
          REVIEW YOUR SERVICES AGREEMENT WITH MAX FOR ADDITIONAL INFORMATION.
        </p>
        <p>
          <strong>1. Access to the Site</strong>
        </p>
        <p>
          MAX reserves the right to withdraw or amend the Site, and any service,
          content, material, or information provided by or presented on the Site
          (“<strong>Content</strong>”), in its sole discretion without notice
          from time to time. MAX will not be liable if for any reason if all or
          any part of the Site is unavailable at any time or for any period. MAX
          assumes no responsibility regarding the accuracy, availability,
          currency, or usefulness of the Content.
        </p>
        <p>
          <strong>2. Privacy Policy</strong>
        </p>
        <p>
          All information MAX collects through the Site is subject to MAX’s{" "}
          <button onClick={() => navigate("/privacy")}>Privacy Policy</button>.
          By using the Site, you consent to MAX’s use of your information in
          compliance with MAX’s Privacy Policy.
        </p>
        <p>
          <strong>3. Ownership of the Site and Content</strong>
        </p>
        <p>
          The MAX name, the MAX logo and all related names, logos, product and
          service names, designs, and slogans are the registered and
          unregistered trademarks of MAX, its licensors, or other providers of
          such material. You are prohibited from using such marks, except as
          otherwise permitted by these Terms or MAX in a prior writing. MAX and
          its licensors retain all right, title, and interest in and to the Site
          and the Content (including but not limited to all information,
          software, text, displays, images, video and audio, and the design,
          selection and arrangement thereof), and any derivative works or other
          modifications thereof, including, without limitation, all copyright,
          trademark, trade secret and other intellectual property rights. You
          hereby assign to us all right, title and interest (including all
          intellectual property rights) throughout the world that you currently
          have or may later acquire in the Site or the Content (including with
          respect to any modifications suggested by, or other contributions made
          by, you). Assignment of intellectual property rights shall be deemed
          effective as to any future modifications or contributions of the Site
          or the Content immediately upon the creation thereof.
        </p>
        <p>
          <strong>4. Prohibited Uses</strong>
        </p>
        <p>
          You may use the Site only for lawful purposes and in accordance with
          these Terms. You agree not to:
        </p>
        <ul>
          <li>
            Modify, duplicate, transmit, distribute, present, upload, publish,
            license, or sell any Content from the Site;
          </li>
          <li>
            Use any illustrations, photographs, video or audio sequences, or any
            graphics separately from the accompanying text;
          </li>
          <li>
            Delete or alter any copyright, trademark, or other proprietary
            rights notices from Content on the Site;
          </li>
          <li>
            Use the Site in any way that violates any applicable federal, state,
            local, or international law or regulation (including, without
            limitation, any laws regarding the export of data or software to and
            from the United States or other countries);
          </li>
          <li>
            Send, knowingly receive, upload, download, use, or re-use any
            material in violation of these Terms;
          </li>
          <li>
            Engage in any other conduct that restricts or inhibits anyone’s use
            or enjoyment of the Site, or which, as determined by MAX, may harm
            MAX or users of the Site or expose them to liability;
          </li>
          <li>
            Use any bot or any other automated technology to access the Site for
            any purpose, including monitoring or copying any Content on the
            Site;
          </li>
          <li>
            Use any manual process to monitor or copy any Content on the Site or
            for any other unauthorized purpose without MAX’s prior written
            consent;
          </li>
          <li>
            Use any device, software or routine that interferes with the proper
            working of the Site;
          </li>
          <li>
            Introduce any viruses or other harmful or malicious software to the
            Site;
          </li>
          <li>
            Interfere with, damage, or disrupt any parts of the Site, the server
            on which the Site is stored, or any server, computer or database
            connected to the Site; or
          </li>
          <li>
            Otherwise attempt to interfere with the proper working of the Site.
          </li>
        </ul>
        <p>
          You agree that MAX has the right to take appropriate legal action,
          including without limitation, referral to law enforcement, for any
          illegal or unauthorized use of the Site.
        </p>
        <p>
          <strong>5. Intellectual Property Rights</strong>
        </p>
        <p>
          You retain all right, title, and interest in and to your registered
          and unregistered trademarks and copyrights as you make available or
          upload to the Site. You hereby grant MAX a non-exclusive,
          royalty-free, worldwide license to use, reproduce, modify, adapt,
          publicly display, translate, and distribute any trademarks and
          copyrights made available by you on the Site to provide certain
          services to you. For specific customized services that MAX may provide
          via the Site, you also grant MAX a non-exclusive, royalty-free,
          worldwide license to use, reproduce, modify, adapt, publicly display,
          translate, and distribute any trademarks and copyrights made available
          by you on third party public platforms including, but not limited to,
          social media websites. You warrant that you own or control all rights
          in and to your trademarks and copyrights and have the right to grant
          the license to MAX and its affiliates and service providers, and each
          of their and MAX’s respective licensees, successors and assigns.
        </p>
        <p>
          <strong>6. Copyright Infringement</strong>
        </p>
        <p>
          If you believe that anything on the Site infringes your copyright
          protected material, please provide MAX with written notice of such
          infringement. Such notice must include the following to be in
          compliance with the U.S. Digital Millennium Copyright Act (“
          <strong>DMCA</strong>”):
        </p>
        <ul>
          <li>
            The physical or electronic signature of the copyright owner, or the
            physical or electronic signature of a person authorized to act on
            their behalf;{" "}
          </li>
          <li>
            Identification of the copyrighted material claimed to have been
            infringed;{" "}
          </li>
          <li>
            Identification of the copyrighted material that is claimed to be
            infringing, and information reasonably sufficient to permit us to
            locate the material;{" "}
          </li>
          <li>
            Your contact information, including, your address, telephone number,
            and an email address;{" "}
          </li>
          <li>
            A statement by you that you have a good faith belief that use of the
            copyrighted material in the manner complained of is not authorized
            by the copyright owner, its agent, or the law; and{" "}
          </li>
          <li>
            A statement that the information in the notice is accurate, and
            under penalty of perjury, that you are the copyright owner or are
            authorized to act on behalf of the copyright owner.{" "}
          </li>
        </ul>
        <p>
          Send the notice to MAX’s designated agent for notice of alleged
          copyright infringement at:
        </p>
        <p>By Email: copyright@max.live</p>
        <p>
          By Mail:
          <br />
          Music Audience Exchange, Inc.
          <br />
          Attn: Copyright Agent
          <br />
          2595 Dallas Pkwy., Ste 440
          <br />
          Frisco, TX 75034
        </p>
        <p>
          MAX reserves the right to remove and/or restrict access to any Content
          alleged to be infringing. MAX may do so in its sole discretion,
          without prior notice, and without liability to you. In appropriate
          circumstances and in MAX’s sole discretion, MAX will also terminate a
          user’s account if the user is determined to be a repeat infringer.
        </p>
        <p>
          <strong>7. Account Security</strong>
        </p>
        <p>
          Some Site functionality may be available upon your registration of an
          account on the Site. You are responsible for maintaining the
          confidentiality and use of your user id and password, and for any
          activities conducted through your account. You agree to notify MAX as
          soon as you discover any unauthorized use of your user id and
          password.
        </p>
        <p>
          <strong>8. User Content and Contributions</strong>
        </p>
        <p>
          The Site may contain message boards, chat rooms, personal web pages or
          profiles, forums, bulletin boards, and/or other interactive features
          (collectively, “<strong>Interactive Services</strong>”) that allow
          users to post, submit, publish, display or transmit to other users or
          other persons (hereinafter, “<strong>post</strong>”) content or
          materials (collectively, “<strong>User Contributions</strong>”) on or
          through the Site. All User Contributions, Interactive Services, and
          posts must comply with the Content Standards (as set forth in Section
          9 of these Terms). Any User Contribution you post to the Site will be
          considered non-confidential and non-proprietary. By providing any User
          Contribution on the Site, you grant MAX and its affiliates and service
          providers, and each of their and MAX’s respective licensees,
          successors and assigns a non-exclusive, worldwide, royalty free
          license to use, reproduce, modify, perform, display, distribute and
          otherwise disclose to third parties any such User Contribution for any
          purpose. You represent and warrant that:
        </p>
        <ul>
          <li>
            You own or control all rights in and to the User Contributions and
            have the right to grant the license granted above to us and MAX’s
            affiliates and service providers, and each of their and MAX’s
            respective licensees, successors and assigns;
          </li>
          <li>
            All of your User Contributions do and will comply with these Terms;
          </li>
          <li>
            You are responsible for any User Contributions you submit or
            contribute and you, not MAX, have full responsibility for such
            content, including its legality, reliability, accuracy and
            appropriateness; and
          </li>
          <li>
            MAX is not responsible, or liable to any third party, for the
            content or accuracy of any User Contributions posted by you or any
            other user of the Site.
          </li>
        </ul>
        <p>
          <strong>9. Content Standards</strong>
        </p>
        <p>
          These content standards (“<strong>Content Standards</strong>”) apply
          to any and all User Contributions and use of Interactive Services (as
          set forth in Section 8 of these Terms). User Contributions must in
          their entirety comply with all applicable federal, state, local and
          international laws and regulations. Without limiting the foregoing,
          User Contributions must not:
        </p>
        <ul>
          <li>
            Contain any material which is defamatory, obscene, indecent,
            abusive, offensive, harassing, violent, hateful, inflammatory or
            otherwise objectionable;
          </li>
          <li>
            Promote sexually explicit or pornographic material, violence, or
            discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age;
          </li>
          <li>
            Infringe any patent, trademark, trade secret, copyright or other
            intellectual property or other rights of any other person;
          </li>
          <li>
            Violate the legal rights (including the rights of publicity and
            privacy) of others or contain any material that could give rise to
            any civil or criminal liability under applicable laws or regulations
            or that otherwise may be in conflict with these Terms or MAX’s
            Privacy Policy;
          </li>
          <li>Be likely to deceive any person;</li>
          <li>
            Promote any illegal activity, or advocate, promote or assist any
            unlawful act;
          </li>
          <li>
            Cause annoyance, inconvenience or needless anxiety or be likely to
            upset, embarrass, alarm or annoy any other person;
          </li>
          <li>
            Impersonate any person, or misrepresent your identity or affiliation
            with any person or organization;
          </li>
          <li>
            Involve commercial activities or sales, such as contests,
            sweepstakes and other sales promotions, barter or advertising; or
          </li>
          <li>
            Give the impression that they emanate from or are endorsed by MAX or
            any other person or entity, if this is not the case.
          </li>
        </ul>
        <p>
          <strong>10. Monitoring and Enforcement; Termination</strong>
        </p>
        <p>
          We use a combination of automated systems and a team of moderators to
          monitor and review accounts and messages for User Contributions and
          any other content that indicates violations of these Terms. MAX has
          the right to:
        </p>
        <ul>
          <li>
            Suspend or terminate any account, restrict access to the Site, or
            make use of any operational, technological, legal or other means
            available to enforce these Terms (including without limitation
            blocking specific IP addresses), at any time without liability and
            without the need to give you prior notice;
          </li>
          <li>
            Suspend or terminate any account without notice (1) for violating
            these Terms, (2) due to your conduct on the Site, or your conduct
            with other users of the Site (including your “offline” conduct), if
            MAX, in its sole discretion, determine your conduct was
            inappropriate or improper, (3) if MAX or MAX’s affiliates, in MAX’s
            or such affiliates’ sole discretion, determine your conduct on other
            websites or apps operated by MAX’s affiliates was inappropriate or
            improper, or (4) for any reasons whatsoever that MAX deems, in its
            sole discretion, justifies termination;
          </li>
          <li>
            Remove or refuse to post to the Site any User Contributions for any
            or no reason in MAX’s sole discretion;
          </li>
          <li>
            Take any action with respect to any User Contribution that we deem
            necessary or appropriate in MAX’s sole discretion, including if we
            believe that such User Contribution violates the Terms, including
            the Content Standards (as set forth in Section 9 of these Terms),
            infringes any intellectual property right or other right of any
            person or entity, threatens the personal safety of users of the Site
            or the public or could create liability for MAX;
          </li>
          <li>
            Disclose your identity or other information about you to any third
            party if material posted by you violates their rights, including
            their intellectual property rights or their right to privacy; or
          </li>
          <li>
            Take appropriate legal action, including without limitation,
            referral to law enforcement, for any illegal or unauthorized use of
            the Site.{" "}
          </li>
        </ul>
        <p>
          YOU HEREBY WAIVE AND HOLD HARMLESS MAX AND ITS AFFILIATES, LICENSEES
          AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN
          BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS
          INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF
          INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES
          IN RELATION TO YOUR USE OF THE SITE. WE ASSUME NO LIABILITY FOR ANY
          ACTION OR INACTION REGARDING TRANSMISSIONS, COMMUNICATIONS OR CONTENT
          PROVIDED BY ANY USER OR THIRD PARTY. WE HAVE NO LIABILITY OR
          RESPONSIBILITY TO ANYONE FOR PERFORMANCE OR NONPERFORMANCE OF THE
          ACTIVITIES DESCRIBED IN THIS SECTION.
        </p>
        <p>
          <strong>11. Third Party Websites</strong>
        </p>
        <p>
          Third party websites, which are linked to or from the Site (such as
          those for artist ticket sales and charitable donations), are
          independently owned, operated, and managed by such third parties.
          Third party websites are not under the control and/or supervision of
          MAX, and MAX does not warrant or guarantee the contents thereof. Any
          correspondence or business dealings between you and third party
          advertisers on the Site are between you and such third party
          advertisers. MAX is not responsible or liable for any loss or damage
          as a result of such correspondence or business dealings. When using
          third party websites, please confirm the terms of use and privacy
          policy of each such third party website. MAX assumes no responsibility
          for information provided by third party websites or any damage caused
          by your use of such third party websites.
        </p>
        <p>
          <strong>12. Links to Site</strong>
        </p>
        <p>
          You may link to the Site, provided you (i) do so in a way that is fair
          and legal and does not damage MAX’s reputation or take advantage of
          it, and (ii) do not suggest any form of association, approval or
          endorsement on MAX’s part. If you would like to use, reprint, frame,
          or redistribute any Content on the Site other than as permitted
          herein, you must request permission from MAX in writing to [●]. Please
          include: (a) your name, e-mail address, and telephone number; (b) the
          name of your company (if applicable); (c) the website address(es)
          where the proposed use will occur; and (d) specific details about the
          contemplated linking or framing activities, including the content or
          webpage(s) of the Site which you would like to use. You agree that you
          are liable for any Content or links that you publish directly or
          through a third party.
        </p>
        <p>
          <strong>13. Services</strong>
        </p>
        <p>
          If you are an artist or brand, you may request certain commercial
          services to be performed by MAX under a separate agreement to be
          provided by MAX (“<strong>Services</strong>”). Such Services may
          include, but are not limited to, software-as-a-service, customized
          services, sweepstakes offers, or other professional services provided
          by MAX on your behalf for your fans via the Site. You agree to
          negotiate the Services with MAX under the terms and conditions of the
          applicable agreement.
        </p>
        <p>
          <strong>14. Feedback</strong>
        </p>
        <p>
          Your ideas, suggestions questions, suggestions, comments, feedback,
          ideas, or other information regarding MAX or the Site (including, but
          not limited to, those related to improvements, additions, or other
          aspects for MAX or the Site) that you provide to MAX, whether
          solicited or not by the Site (collectively, “<strong>Feedback</strong>
          ”) are the exclusive property of MAX. If you send MAX Feedback,
          regardless of application, MAX assumes that you have sent them to MAX
          after accepting that, regarding those ideas, MAX assumes no obligation
          regarding their confidentiality.
        </p>
        <p>
          <strong>15. Disclaimer of Warranties</strong>
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SITE, CONTENT, AND
          SERVICES ARE PROVIDED “AS IS”, “WITH ALL FAULTS”, “AS AVAILABLE” AND
          AT THE USER’S SOLE RISK. OTHER THAN AS EXPRESSLY SET FORTH IN THESE
          TERMS, MAX MAKES NO REPRESENTATIONS AND WARRANTIES OF ANY KIND OR
          NATURE, WHETHER EXPRESS OR IMPLIED, AND EXPRESSLY DISCLAIMS TO THE
          FULL EXTENT PERMISSIBLE UNDER APPLICABLE LAW ANY AND ALL OTHER
          WARRANTIES OR CONDITIONS OF ANY KIND, INCLUDING ANY WARRANTIES OR
          CONDITIONS THAT THE SITE, CONTENT, OR SERVICES: (A) ARE FIT FOR A
          PARTICULAR PURPOSE; (B) DO NOT INFRINGE THIRD PARTY RIGHTS; (C) ARE
          ACCURATE OR ERROR FREE OR WILL MEET YOUR NEEDS OR REQUIREMENTS; OR (D)
          WILL ALWAYS BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE OR
          FREE OF MALICIOUS CODE OR VIRUS.
        </p>
        <p>
          <strong>16. Limitation of Liability</strong>
        </p>
        <p>
          IN NO EVENT WILL MAX, ITS AFFILIATES, OR THEIR LICENSORS, SERVICE
          PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR
          DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
          CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITE, ANY WEBSITES
          LINKED TO IT, ANY CONTENT ON THE SITE OR SUCH OTHER WEBSITES OR ANY
          SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR SUCH OTHER WEBSITES,
          INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR
          PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN
          AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
          LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
          GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
          NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>
          <strong>17. Assumption of Risk</strong>
        </p>
        <p>
          You agree and understand that you assume all risks when using the
          Site, including without limitation any and all of the risks associated
          with any online interactions with other users or visitors of the Site.
        </p>
        <p>
          <strong>18. Indemnification</strong>
        </p>
        <p>
          You agree to defend, indemnify and hold harmless MAX, its affiliates,
          licensors and service providers, and their respective officers,
          directors, employees, contractors, agents, licensors, suppliers,
          successors, and assigns from and against any claims, liabilities,
          damages, judgments, awards, losses, costs, expenses, or fees
          (including reasonable attorneys’ fees) arising out of or relating to
          your violation of these Terms or your use of the Site, including, but
          not limited to, User Contributions or your use of any Content or
          materials obtained from the Site.
        </p>
        <p>
          <strong>19. Governing Law and Dispute Resolution</strong>
        </p>
        <p>
          Use of the Site and interpretation and application of these Terms are
          in compliance with the laws of the State of Texas. Any disputes
          arising out of or related to the Site shall be tried only in the
          Federal or State court with competent jurisdiction located in Frisco,
          Texas. Any controversy or claim arising out of or relating to these
          Terms, or the breach thereof, shall be settled by confidential binding
          arbitration administered in Frisco, Texas by the American Arbitration
          Association (“<strong>AAA</strong>”) in accordance with its
          Arbitration Rules then in effect. There shall be one arbitrator agreed
          to by you and MAX (the “<strong>Arbitration Parties</strong>”) within
          twenty (20) days of a written request for arbitration. If the
          Arbitration Parties cannot agree, an arbitrator will be appointed by
          the AAA in accordance with its Arbitration Rules. Any award from any
          such arbitration proceeding may be entered as a judgment in any court
          of competent jurisdiction. You shall bear your own costs in connection
          with any arbitration hereunder. Nothing herein shall prevent MAX from
          seeking injunctive relief (or any other provisional remedy) from any
          court having jurisdiction over you and the subject matter of the
          dispute as is necessary to protect its proprietary rights.
        </p>
        <p>
          <strong>20. Waiver and Severability</strong>
        </p>
        <p>
          If any provision of these Terms is judged to be invalid, illegal or
          unenforceable, that provision shall be deemed deleted and the
          remainder shall not be affected. MAX’s failure to enforce any
          provision of these Terms shall not be deemed a waiver of the provision
          or any right under these Terms.
        </p>
        <p>
          <strong>21. Entire Agreement</strong>
        </p>
        <p>
          These Terms and MAX’s{" "}
          <button onClick={() => navigate("/privacy")}>Privacy Policy</button>{" "}
          constitute the sole and entire agreement between you and MAX with
          respect to the Site, and supersede all prior and contemporaneous
          understandings, agreements, representations and warranties, both
          written and oral, with respect to the Site. These Terms and all of
          your rights and obligations under the Terms are not assignable or
          transferable by you without MAX’s prior written consent. MAX may
          freely assign, transfer, or delegate these Terms or any of MAX’s
          rights and obligations under them.
        </p>
        <p>
          <strong>22. Contact</strong>
        </p>
        <p>
          The Site is operated by Music Audience Exchange, Inc., 2595 Dallas
          Parkway, Suite 440, Frisco, Texas 75034. All feedback, comments,
          requests for technical support and other communications relating to
          the Site should be directed to: hello@max.live
        </p>
      </Content>
    </Wrapper>
  );
};

export default Terms;

const Wrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 500;
  overflow-y: scroll;
  button {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Content = styled(FlexColumn)`
  margin: 0 auto;
  padding: 40px 24px;
  width: 100%;
  ${Link} {
    color: #1b0076 !important;
    display: inline-block;
    text-decoration: underline;
  }
  ${H4} {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }
  a {
    word-break: break-all;
  }
  p {
    font-size: 15;
    font-weight: 400;
    line-height: 22px;
    color: ${(props) => props.theme.colors.black80};
    margin-top: 16px;
    strong {
      font-weight: 600;
    }
  }
  ul {
    padding-left: 24px;
    list-style: disc;
    li {
      margin-top: 8px;
      font-size: 15;
      font-weight: 400;
      line-height: 22px;
      color: ${(props) => props.theme.colors.black80};
    }
  }
`;
