import { useRequestAnimationFrame } from "../../../Components";
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
export const ArtistImageScroller = ({
  images,
  velocity = 0,
  style,
}: {
  images: string[];
  velocity?: number;
  style?: any;
}) => {
  const scrolls = useRef<{ scrollTop: number; lastTop: number }>({
    scrollTop: 0,
    lastTop: 0,
  });
  const refs = useRef<HTMLImageElement[]>([]);
  const [offsets, setOffsets] = useState<number[]>([]);
  const [widths, setWidths] = useState<number[]>([]);
  const maxValue = widths.reduce((acc, curr) => acc + curr, 0);

  const handleLoaded = (e: SyntheticEvent<HTMLImageElement>, idx: number) => {
    const width = (e.target as HTMLImageElement).clientWidth;
    setWidths((widths) => {
      let res = [...widths];
      res[idx] = width + 8;
      return res;
    });
  };

  const handleAnimationFrame = useMemo(
    () => (time: number, delta: number) => {
      if (!offsets.length) {
        return;
      }
      const min = offsets.reduce((min, curr) => (min < curr ? min : curr), 0);
      let scrollAcceleration = -Math.abs(
        scrolls.current.scrollTop - scrolls.current.lastTop
      );
      setOffsets((offsets) => {
        let newOffsets = offsets.map((o, idx) => {
          let newOffset = scrollAcceleration + o + (velocity * delta) / 1000;
          if (newOffset < -widths[idx]) {
            newOffset = newOffset + maxValue;
          }
          return newOffset;
        });
        return newOffsets;
      });
      scrolls.current.lastTop = scrolls.current.scrollTop;
    },
    [offsets.length]
  );

  useRequestAnimationFrame(handleAnimationFrame);

  const handlePageScroll = (e: any) => {
    scrolls.current.scrollTop = e.target.scrollingElement.scrollTop;
  };

  useEffect(() => {
    if (
      !offsets.length &&
      widths.length === images.length &&
      !images.find((i, idx) => !widths[idx])
    ) {
      let offsets: number[] = [];
      refs.current.map((el, idx) => {
        offsets[idx] =
          idx === 0 ? 0 : widths[idx - 1] + (offsets[idx - 1] || 0);
      });
      setOffsets(offsets);
    }
  }, [widths]);

  useEffect(() => {
    window.addEventListener("scroll", handlePageScroll);
    return () => window.removeEventListener("scroll", handlePageScroll);
  }, []);

  return (
    <Container style={style}>
      <Row>
        {images.map((i, idx) => (
          <ImageContainer
            style={{
              transform: widths[idx]
                ? `translateX(${offsets[idx]}px)`
                : `translateY(1000px)`,
            }}
          >
            <Image
              src={i}
              ref={(ref) => {
                if (ref) {
                  refs.current[idx] = ref;
                }
              }}
              onLoad={(e) => handleLoaded(e, idx)}
            />
          </ImageContainer>
        ))}
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  overflow: hidden;
  position: relative;
  height: 100px;
  width: 100%;
  display: flex;
`;
const ImageContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 0 4px;
  position: absolute;
  backface-visibility: hidden;
`;
const Image = styled.img`
  backface-visibility: hidden;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  margin: 4px 0;
  @media all and (max-width: 599px) {
    border: 1px solid #ffffff;
  }
`;
