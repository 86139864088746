import { isVisible, useDataContext } from "../../../../../Components";
import { useState } from "react";
import styled, { css } from "styled-components";
import { Card } from ".";
import { CardType } from "@max/common";

export const Cards = ({ cards }: { cards: CardType[] }) => {
  const { data } = useDataContext();
  const [view, setView] = useState<number>(0);
  const visibles = cards.reduce((acc, cur) => {
    if (isVisible(cur.visible, data)) {
      acc.push(cur);
    }
    return acc;
  }, [] as CardType[]);

  return (
    <Container>
      <NavBar singleTab={visibles.length === 1}>
        {visibles.map((c, i) => (
          <NavItem
            key={`cards-tab-${i}`}
            onClick={() => setView(i)}
            active={view === i}
          >
            {c.tab || ""}
          </NavItem>
        ))}
      </NavBar>
      <Card {...visibles[view]} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavBar = styled.div<{ singleTab?: boolean }>`
  background: var(--template-secondary);
  color: var(--template-primary);
  border-radius: 20px;
  display: inline-flex;
  flex-shrink: 0;
  overflow: hidden;
  border: 2px solid var(--template-primary);
  align-self: center;
  margin: 20px;
  ${({ singleTab }) =>
    singleTab &&
    css`
      border: none;
      background: transparent;
      margin: 0;
      opacity: 0;
      height: 20px;
    `}
`;

const NavItem = styled.div<{ active?: boolean }>`
  padding: 5px 16px;
  border-radius: 20px;
  font-size: 15px;
  line-height: 22px;
  ${({ active }) =>
    active &&
    css`
      color: var(--template-secondary);
      background: var(--template-primary);
      font-weight: 700;
    `}
`;
