import React from "react";
import styled, { css } from "styled-components";
import { Body1 } from "../Text";
import { colors } from "../Theme/colors";

const Body1White = styled(Body1)`
  color: white;
`;

type AvatarWrapperProps = {
  bgColor?: string;
};
const AvatarWrapper = styled.div<AvatarWrapperProps>`
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  overflow: hidden;
  color: ${(p) => p.theme.colors.white};
  ${(p) =>
    p.bgColor &&
    css`
      background: ${p.bgColor};
    `}
  svg {
    height: 70%;
    width: 70%;
    color: white;
  }
`;

export const AvatarImg = styled.img`
  border-radius: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export type AvatarProps = {
  imgUrl?: string;
  name?: string;
  icon?: JSX.Element;
  bgColor?: string;
} & React.BaseHTMLAttributes<HTMLDivElement>;
export const Avatar = ({
  imgUrl,
  name,
  icon,
  bgColor = colors.black40,
  ...props
}: AvatarProps) => {
  return (
    <AvatarWrapper bgColor={bgColor} {...props}>
      {icon}
      {name && <Body1White>{name && name[0]}</Body1White>}
      {imgUrl && <AvatarImg src={imgUrl} />}
    </AvatarWrapper>
  );
};
