import { DateTime } from "luxon";
import { BuilderConfigDocument } from ".";
import { Colors } from "../event";

export interface SelectedTemplateColors {
  [key: number]: Colors;
}

interface TemplateColors {
  [key: number]: Colors[];
}

export const getColorConfig = <T>(
  config: Partial<BuilderConfigDocument<T | DateTime>>,
) => {
  const template = config.fields?.type?.content || 0;
  const colorStore = config.images?.reduce(
    (prevImg: TemplateColors[], currImg, imgIdx) => {
      prevImg[imgIdx] = currImg.templates.reduce(
        (prevTemp: TemplateColors, currTemp, tempIdx) => {
          prevTemp[tempIdx] = currTemp.colorsOptions?.length
            ? currTemp.colorsOptions
            : defaultTemplateColors[tempIdx];
          return prevTemp;
        },
        [],
      );
      return prevImg;
    },
    [],
  );

  const imageIndex =
    config?.images?.findIndex(
      ({ src }) => src === config.fields?.image?.content,
    ) || 0;

  const imageColors = colorStore?.[imageIndex] || defaultTemplateColors;
  const templateColors = imageColors?.[template];
  const selectedColors =
    config.fields?.colors?.content?.[imageIndex]?.[template];

  const generateUpdatedColors = (colorOption: Colors) => {
    const colors = config?.fields?.colors?.content || [];
    const selectedTemplateColors = colors[imageIndex] || {};
    selectedTemplateColors[template] = colorOption;
    colors.splice(imageIndex, 1, selectedTemplateColors);
    return colors;
  };

  return {
    imageColors,
    imageIndex,
    templateColors,
    selectedColors,
    generateUpdatedColors,
  };
};

export const defaultTemplateColors: TemplateColors = {
  0: [
    {
      dark: "#0078d4",
    },
    {
      dark: "#3c308b",
    },
    {
      dark: "#572f69",
    },
    {
      dark: "#652546",
    },
  ],
  1: [
    {
      dark: "#241451",
      light: "#755dbb",
    },
    {
      dark: "#652546",
      light: "#eea6cb",
    },
    {
      dark: "#572f69",
      light: "#d29eea",
    },
    {
      dark: "#3c308b",
      light: "#c0baea",
    },
  ],
  2: [
    {
      light: "#909ca9",
      dark: "#38434e",
    },
    {
      dark: "#652546",
      light: "#eea6cb",
    },
    {
      dark: "#572f69",
      light: "#d29eea",
    },
    {
      dark: "#3c308b",
      light: "#c0baea",
    },
  ],
  3: [
    {
      dark: "#7158b9",
    },
    {
      dark: "#3c308b",
    },
    {
      dark: "#572f69",
    },
    {
      dark: "#652546",
    },
  ],
  4: [
    {
      dark: "#0078d4",
    },
    {
      dark: "#3c308b",
    },
    {
      dark: "#572f69",
    },
    {
      dark: "#652546",
    },
  ],
};
