import styled from "styled-components";
// import Joi from "@hapi/joi";
import { H4, P, Label } from "../melodies-source/Text";
import { Textarea } from "../Components/TextArea";
import { TextInput } from "../melodies-source/TextInput";
import { Button } from "../melodies-source/Button";
import { useEffect, useState } from "react";
import { addDoc, collection, getFirestore } from "firebase/firestore";
//import { validate } from "Utils";
//import { useUser } from "auth";
import { SvgBack } from "../melodies-source/Svgs/Back";
import { useNavigate } from "react-router-dom";
import { firebaseApp } from "../Components";

// const formSchema = Joi.object({
//   name: Joi.string().min(1).required(),
//   email: Joi.string().email({ tlds: false }).required(),
//   message: Joi.string().min(3).required(),
// });
interface FormProps {
  type: string;
  name: string;
  email: string;
  message: string;
  uid?: string;
}

export const Contact = (props: any) => {
  // const { user } = useUser();
  const [form, setForm] = useState<FormProps>({
    type: "contact",
    name: "",
    email: "",
    message: "",
    //  uid: user?.uid ?? "",
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (value: any, name: any) => {
    setForm({ ...form, [name]: value });
    //  delete errors[name];
    // setErrors(errors);
  };
  const db = getFirestore(firebaseApp);
  const handleSubmit = async (form: FormProps) => {
    //  const isError = validate(form, formSchema);
    setLoading(true);
    if (!!form) {
      setLoading(true);
      const docRef = await addDoc(collection(db, "setlive_feedback"), {
        ...form,
      });
      if (docRef?.id) {
        setSuccess(true);
      }
    } else {
      // setErrors(isError);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <FormWrapper>
        <BackButton onClick={() => navigate("/")}>
          <SvgBack /> BACK
        </BackButton>
        <H4>Contact Us</H4>
        {success ? (
          <FlexColumn style={{ marginTop: "40px" }}>
            <P>
              Thanks - We'll review your question and get back to you as soon as
              possible.
            </P>
          </FlexColumn>
        ) : (
          <FlexColumn>
            <P>
              We're here to help. Fill out the form and we will get back to you
              shortly.
            </P>
            <Form>
              <FlexColumn>
                <Label>Name</Label>
                <StyledInput
                  onChange={(value) => handleChange(value, "name")}
                  placeholder="First and last name..."
                  value={form?.name}
                  //  error={!!errors["name"]}
                />
                {/* <FieldMessage
                  active={!!errors["name"]}
                  content="This is a required field and cannot be blank."
                /> */}

                <Label>Email address</Label>
                <StyledInput
                  // leftIcon={<Icon form name="Email" />}
                  onChange={(value) => handleChange(value, "email")}
                  placeholder="john@email.com"
                  value={form?.email}
                  //  error={!!errors["email"]}
                />
                {/* <FieldMessage
                 active={!!errors["email"]}
                  content="This is a required field and cannot be blank."
                /> */}
              </FlexColumn>
              <Label>Message</Label>
              <StyledTextarea
                onChange={(value) => handleChange(value, "message")}
                placeholder="Comments..."
                value={form.message}
                //   error={!!errors["message"]}
              />
              {/* <FieldMessage
                active={!!errors["message"]}
                content="Please provide a short description of your reason for contacting us."
              /> */}

              <SubmitButton
                isDisabled={loading}
                onClick={() => handleSubmit(form)}
                style={{ marginTop: "40px" }}
              >
                {loading ? <LoaderInline /> : "Submit"}
              </SubmitButton>
            </Form>
          </FlexColumn>
        )}
      </FormWrapper>
    </Container>
  );
};

const StyledLabel = styled(Label)`
  margin-bottom: 4px;
`;
const Container = styled.div`
  background-color: #fff;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
`;
export const FlexColumn = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const FormWrapper = styled(FlexColumn)`
  background-color: #fff;
  margin: 0 auto;
  padding: 40px 24px;
  width: 100%;
  max-width: 700px;
  min-height: 100vh;
  flex-grow: 1;
  ${H4} {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  ${P} {
    font-size: 14px;
    font-weight: 300;
  }
  ${P} + ${StyledLabel} {
    margin-top: 24px;
    font-weight: 400;
  }
`;

const Form = styled(FlexColumn)`
  ${Label} {
    margin-top: 24px;
    margin-bottom: 4px;
  }
`;
const StyledTextarea = styled(Textarea)`
  width: 100%;
  margin-top: 5px;
`;
const StyledInput = styled(TextInput)`
  margin-top: 5px;
  input {
    box-shadow: 0 0 0px 0 rgb(0 0 0);
  }
`;
const BackButton = styled.button`
  color: grey;
  margin-bottom: 40px;
  font-size: 15px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-right: 7px;
    height: 17px;
  }
`;
const SubmitButton = styled(Button)`
  background: rgb(27, 0, 118);
`;
const LoaderInline = styled.div`
  &,
  &:after {
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
  margin-right: 12px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid #ffffff50;
  border-right: 2px solid #ffffff50;
  border-bottom: 2px solid #ffffff50;
  border-left: 2px solid gray;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
export default Contact;
