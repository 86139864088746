import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface CalendarIconProps extends React.HTMLAttributes<HTMLDivElement> {
  date: Timestamp | DateTime;
}

const ordinal = (n: number) => {
  var s = ["th", "st", "nd", "rd"];
  var v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};

export const CalendarIcon = ({ date, ...props }: CalendarIconProps) => {
  const { i18n } = useTranslation();
  let luxonDate: DateTime;
  if (date instanceof Timestamp) {
    luxonDate = DateTime.fromJSDate(date.toDate()).setLocale(
      i18n.resolvedLanguage!
    );
  } else if (date instanceof DateTime) {
    luxonDate = date.setLocale(i18n.resolvedLanguage!);
  } else {
    return null;
  }
  return (
    <Calendar {...props}>
      <DayOfWeek>{luxonDate.toFormat("cccc")}</DayOfWeek>
      <Date>
        <Month>{luxonDate.toFormat("LLL")}</Month>
        <Day>
          {luxonDate.toFormat("d")}
          {ordinal(luxonDate.day)}
        </Day>
      </Date>
    </Calendar>
  );
};

const Calendar = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 44px;
  margin-left: 12px;
  border-radius: 6px;
  overflow: hidden;
  /* ${({ theme }) => theme.mediaQueries.desktop} {
    width: 54px;
  } */
`;

const DayOfWeek = styled.div`
  height: 11px;
  background-color: #ffffff;
  color: #000000;
  font-family: "Lato", sans-serif;
  font-size: 7px;
  font-weight: 600;
  line-height: 11px;
  text-align: center;
  /* ${({ theme }) => theme.mediaQueries.desktop} {
    height: 13px;
    font-size: 9px;
    line-height: 13px;
  } */
`;

const Date = styled.div`
  background-color: var(--template-primary);
  text-align: center;
  padding: 2px 0 3px;
  /* ${({ theme }) => theme.mediaQueries.desktop} {
    padding: 4px 0 2px;
  } */
`;

const Month = styled.div`
  color: var(--template-secondary);
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  line-height: 16px;
  text-transform: capitalize;
`;

const Day = styled(Month)`
  font-size: 11px;
  font-weight: 800;
  line-height: 12px;
  /* ${({ theme }) => theme.mediaQueries.desktop} {
    font-size: 12px;
    line-height: 16px;
  } */
`;
