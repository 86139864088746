import { INVALID_MODULE, InputModule, InputModuleProps } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import { addRequiredValidation } from "./util";
import {
  RankedBuilderConfigModule,
  TranslateableModuleContent,
} from "../creator";

type RankedQuestionProps = InputModuleProps & {
  displayOrder?: "asc" | "desc";

  /**
   * the label to be appended to the "lowest" option.
   * eg. rankLow: "least", label for option 1 would be `1 (least)`
   */
  rankLow: TranslateableModuleContent;

  /**
   * the label to be appended to the "highest" option.
   * eg. rankHigh: "most", rankRange: 5, label for option 5 would be `5 (most)`
   */
  rankHigh: TranslateableModuleContent;

  /**
   * the maximum value for the ranked question
   */
  rankRange: number;
};
export class RankedQuestionModule extends InputModule {
  protected _type: ModuleType = "RankedQuestion";

  displayOrder: "asc" | "desc";
  rankLow: TranslateableModuleContent;
  rankHigh: TranslateableModuleContent;
  private _rankRange: number;

  constructor({
    displayOrder = "desc",
    rankLow,
    rankHigh,
    rankRange,
    ...rest
  }: RankedQuestionProps) {
    super(rest);

    if (rankRange !== 5 && rankRange !== 10) {
      throw new Error("ranked questions rankRange accepts values of 5 or 10");
    }

    this.displayOrder = displayOrder;
    this.rankLow = rankLow;
    this.rankHigh = rankHigh;
    this._rankRange = rankRange;
  }

  get rankRange(): number {
    return this._rankRange;
  }

  set rankRange(rankRange) {
    if (rankRange !== 5 && rankRange !== 10) {
      throw new Error("ranked questions rankRange accepts values of 5 or 10");
    }
    this._rankRange = rankRange;
  }

  validate(): this is Required<RankedQuestionModule> {
    return true;
  }

  toBuilderConfig(): RankedBuilderConfigModule {
    if (this.validate()) {
      const builderConfigModule: RankedBuilderConfigModule = {
        actionRequired: this.actionRequired,
        id: this.id,
        noBorder: this.noBorder,
        rankLow: this.rankLow,
        rankHigh: this.rankHigh,
        rankRange: this.rankRange,
        label: this.label,
        required: this.required,
        style: this.style,
        type: this.type,
      };
      if (this.tag) {
        builderConfigModule.tag = this.tag;
      }
      return builderConfigModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    if (this.validate()) {
      const { required, label, rankLow, rankHigh, ...base } =
        this.toBuilderConfig();
      const surveyConfigModule: SurveyConfigModule = {
        ...base,
        actionRequired: this.actionRequired,
        header: { children: label[this.languageCode] ?? "" },
        rankLow: rankLow[this.languageCode],
        rankHigh: rankHigh[this.languageCode],
        noBorder: this.noBorder,
      };
      if (required) {
        surveyConfigModule.validation = addRequiredValidation();
      }
      return surveyConfigModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  editProps() {
    return {
      id: this.id,
      type: this.type,
      rank: {
        low: this.rankLow[this.languageCode] ?? "",
        high: this.rankHigh[this.languageCode] ?? "",
        range: this.rankRange,
      },
      question: this.label[this.languageCode] ?? "",
      actionRequired: this.actionRequired,
    };
  }
}
