import { PaymentForm, RegisterForm, useEventConfig } from "../Components";
import {
  createContext,
  ProviderProps,
  useContext,
  useEffect,
  useState,
  lazy,
  Suspense,
} from "react";

import styled from "styled-components";
import { EventTypes } from "@max/common";
import { useUserActionTrackingContext } from "./UserActionTrackingContext";
import { TrackingEventType, ClickTrackingContext } from "@max/common/analytics";

const SocialFlyout = lazy(() => import("./SocialFlyout"));
const Terms = lazy(() => import("./Terms"));
const Privacy = lazy(() => import("./Privacy"));
const SweepstakesRules = lazy(() => import("./SweepstakesRules"));

export interface ActionInterface {
  handleAction: (
    arg: string,
    tracking?: { evt: TrackingEventType; ctx?: ClickTrackingContext },
  ) => Promise<void> | void;
  closing: boolean;
  showSweepstakesRules: () => void;
}

const animationDuration = 400;
const ActionContext = createContext<ActionInterface>({} as ActionInterface);

export const ActionProvider = ({
  children,
  isMock,
  ...props
}: JSX.IntrinsicAttributes &
  Omit<ProviderProps<ActionInterface>, "value"> & {
    eventId?: string;
    isMock?: boolean;
  }) => {
  const [visibleView, setVisibleView] = useState<string | null>();
  const [terms, setTerms] = useState<boolean>(false);
  const [privacy, setPrivacy] = useState<boolean>(false);
  const [sweepstakesRules, setSweepstakesRules] = useState<boolean>(false);
  const [closing, setClosing] = useState<boolean>(false);
  const { track } = useUserActionTrackingContext();
  const { type, register } = useEventConfig();

  const handleExternalLink = (action: string) => {
    track({
      event: "click",
      context: { type: "url", value: action },
    });
    window.open(action, "_blank", "noopener");
  };

  const handleAction = (action: string) => {
    if (action.startsWith("http")) {
      handleExternalLink(action);
      return;
    }

    if (["register", "social", "payment"].includes(action)) {
      if (action === "register") {
        track({
          event: "card",
          context: { label: "Check in form" },
        });
      } else if (action === "payment") {
        track({ event: "click", context: { type: "donate", value: "start" } });
      } else if (action === "social") {
        track({
          event: "click",
          context: { type: "streaming", value: "open" },
        });
      }

      setVisibleView(action);
      return;
    }

    if (action === "close") {
      setClosing(true);
      window.setTimeout(() => {
        setVisibleView(null);
        setClosing(false);
      }, animationDuration + 10);
      return;
    }
  };

  const showTerms = () => {
    setTerms(true);
  };

  const showPrivacy = () => {
    console.log("SHOW PRIVACY!");
    setPrivacy(true);
  };

  const showSweepstakesRules = () => {
    if (register?.customSweepstakesRules?.startsWith("http")) {
      handleExternalLink(register.customSweepstakesRules);
      return;
    }
    if (!isMock) {
      track({
        event: "click",
        context: { type: "register", value: "rules" },
      });
      setSweepstakesRules(true);
    }
  };

  useEffect(() => {
    if (!isMock) {
      document.addEventListener("setfan-show-terms", showTerms);
      document.addEventListener("setfan-show-privacy", showPrivacy);
      document.addEventListener(
        "setfan-show-sweepstakesrules",
        showSweepstakesRules,
      );
      return () => {
        document.removeEventListener("setfan-show-terms", showTerms);
        document.removeEventListener("setfan-show-privacy", showPrivacy);
        document.removeEventListener(
          "setfan-show-sweepstakesrules",
          showSweepstakesRules,
        );
      };
    }
  }, []);

  const value = {
    handleAction,
    closing,
    showSweepstakesRules,
  };

  return (
    <ActionContext.Provider {...props} value={value}>
      <EventRoot
        id={`event-root-${EventTypes[type].toLowerCase()}`}
        style={{
          ...(!!visibleView && {
            position: isMock ? "absolute" : "fixed",
            height: "100%",
          }),
        }}
      >
        {children}
        {visibleView === "register" && <RegisterForm />}
        {visibleView === "payment" && <PaymentForm />}
        <Suspense fallback={null}>
          {visibleView === "social" && <SocialFlyout />}
        </Suspense>
        <Suspense fallback={null}>
          {terms && <Terms onClose={() => setTerms(false)} />}
        </Suspense>
        <Suspense fallback={null}>
          {privacy && <Privacy onClose={() => setPrivacy(false)} />}
        </Suspense>
        <Suspense fallback={null}>
          {sweepstakesRules && (
            <SweepstakesRules onClose={() => setSweepstakesRules(false)} />
          )}
        </Suspense>
      </EventRoot>
    </ActionContext.Provider>
  );
};

export const useActionContext = () => useContext(ActionContext);

const EventRoot = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  position: relative;
`;
