import * as React from "react";
import { SVGProps } from "react";
export const SvgShirtAlt = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 6C8.33043 6 7.23188 4.66667 7 4L2.5 7.5L4.43 9.97L6 8.75V16H14V8.75L15.5742 9.97266L17.5 7.5L13 4C12.4435 5.6 10.6957 6 10 6Z"
      fill="currentColor"
    />
  </svg>
);
