import { Validation } from "../fanApp";

const HTTP_REGEX =
  /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

export function isValidUrl(url: string) {
  return HTTP_REGEX.test(url);
}

export const addRequiredValidation = (
  message = "This question is required",
): Validation => ({
  type: "required",
  message,
});

export const addRequiredCountValidation = ({
  min,
  max,
}: {
  min?: number;
  max?: number;
}) => {
  const validation: Validation = {
    type: "require_count",
    // this isn't actually being used currently, validation message will be generated on STS
    message: "This field is required",
  };

  if (typeof min === "number" && min > 0) {
    validation.min = min;
  }

  if (typeof max === "number" && max > 0) {
    validation.max = max;
  }

  return validation;
};

/**
 * Generate a UUID portably.  Slightly tweaked from the credited
 * implementation.
 *
 * Author: Abhishek Dutta, 12 June 2020
 * License: CC0 (https://creativecommons.org/choose/zero/)
 */
export function generateUUID(): string {
  const temp_url = URL.createObjectURL(new Blob([]));
  // originally returned of the form blob:identifier/{uuid} but in node can be blob:identifier:{uuid}
  // this handles both cases
  const uuid = temp_url.toString().split(/[:\/]/g).pop();
  URL.revokeObjectURL(temp_url);
  if (!uuid) {
    throw new Error("unable to create UUID");
  }
  return uuid;
}
