import styled, { css } from "styled-components";
import { useActionContext } from "./ActionContext";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { Button } from "../melodies-source/Button";
import { H4 } from "../melodies-source/Text";
import drawerMask from "../Images/DrawerMask.svg";
import Draggable, {
  ControlPosition,
  DraggableData,
  DraggableEvent,
  DraggableProps,
} from "react-draggable";

const animationDuration = 400;

interface DrawerProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
}

export const DrawerButton = styled(Button)<{ isSecondaryTextColor?: boolean }>`
  background-color: var(--template-primary);
  border-radius: var(--template-border-radius);
  color: #ffffff;
  font-family: var(--template-font-family);
  &:hover {
    background-color: var(--template-primary);
    color: #ffffff;
  }
  ${(p) =>
    p.isSecondaryTextColor &&
    css`
      color: var(--template-primary);
      background-color: var(--template-secondary);
      &:hover {
        color: var(--template-primary);
        background-color: var(--template-secondary);
      }
    `};
`;

export const Drawer = ({ children, title, subtitle }: DrawerProps) => {
  const [pos, setPos] = useState<ControlPosition>({ x: 0, y: 0 });
  const [easing, setEasing] = useState<boolean>(false);
  const { handleAction } = useActionContext();

  const close = () => {
    setEasing(true);
    setPos({ x: 0, y: window.innerHeight });
    handleAction("close");
  };

  const onStop = (e: DraggableEvent, data: DraggableData) => {
    setEasing(true);
    if (!data) return;
    const target = data.node as HTMLElement;
    const offsetTop = target?.parentElement?.offsetTop ?? 0;
    const swipePercentage =
      (data.lastY / (window.innerHeight - offsetTop)) * 100;
    if (swipePercentage > 40) {
      close();
    } else {
      setPos({ x: 0, y: 0 });
    }
  };

  const onStart = () => {
    setEasing(false);
  };

  const nodeRef = useRef(null);

  const config: Partial<DraggableProps> = {
    axis: "y",
    handle: ".drawer-handle",
    grid: [1, 1],
    scale: 1,
    onStart,
    bounds: { top: 0, left: 0, right: 0 },
    onStop,
    position: pos,
    nodeRef,
  };

  return (
    <PageWrapper>
      <FlexBox onClick={close} />
      <DrawerWrapper>
        <Draggable {...config}>
          <DrawerContainer easing={easing} ref={nodeRef}>
            <GrabArea className="drawer-handle" />
            <DrawerTop />
            <Spacer>
              <Handle />
            </Spacer>
            <InnerWrapper>
              <Content>
                {title && (
                  <Title style={{ marginBottom: !subtitle ? 24 : 0 }}>
                    {parse(title)}
                  </Title>
                )}
                {subtitle && (
                  <Subtitle style={{ marginBottom: 24 }}>
                    {parse(subtitle)}
                  </Subtitle>
                )}
                {children}
              </Content>
            </InnerWrapper>
          </DrawerContainer>
        </Draggable>
      </DrawerWrapper>
    </PageWrapper>
  );
};

const DrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  position: relative;
`;

const FlexBox = styled.div`
  display: flex;
  flex: 1 0 10%;
  width: 100%;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  z-index: 100;
  animation-name: entrance;
  animation-duration: ${animationDuration}ms;
  animation-timing-function: cubic-bezier(0.25, 0.49, 0.05, 0.84);
  animation-fill-mode: forwards;

  @keyframes entrance {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`;

const DrawerContainer = styled.div<{ easing?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  width: 100%;
  max-width: 600px;
  position: relative;

  ${(p) =>
    p.easing &&
    css`
      transition-duration: 150ms;
      transition-property: transform;
      transition-timing-function: ease-in;
    `};
`;

const GrabArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 5;
`;

const Handle = styled.div`
  display: flex;
  border-radius: 4px;
  background: ${(p) => p.theme.colors.black20};
  height: 4px;
  width: 50px;
`;

const Title = styled.div`
  color: #333333;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  font-family: var(--template-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const Subtitle = styled.div`
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
  font-family: var(--template-font-family);
`;

const DrawerTop = styled.div`
  display: flex;
  border-radius: 20px 20px 0 0;
  background: #fff;
  height: 30px;
  mask-image: url(${drawerMask});
  mask-position: center;
  mask-size: cover;
  position: relative;
  bottom: -1px;
  touch-action: none;
  z-index: 4;
`;

const Content = styled.div`
  background: ${(p) => p.theme.colors.gray2};
  padding: 24px 16px 20px;
  border-radius: 20px;
  position: relative;
  margin-top: -1px;
`;

const InnerWrapper = styled.div`
  background: #ffffff;
  padding: 0 10px 40px 10px;
  ${H4} {
    text-align: center;
    padding: 10px 0;
  }
`;

const Spacer = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 20px;
  margin-bottom: -1px;
  position: relative;
`;
