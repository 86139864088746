import React from "react";
import styled, { css } from "styled-components";
import { Body1, Caption, H3, P as ListItemBase } from "../Text";

type LiProps = {
  hasCaption?: boolean;
};
const Li = styled(ListItemBase)<LiProps>`
  line-height: 22px;
  ${(p) =>
    p.hasCaption &&
    css`
      line-height: 21px;
    `}
`;

type IconContainerProps = {
  isAvatar?: boolean;
  dimensions: number;
  hasCaption?: boolean;
};
const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${(p) => p.dimensions}px;
  max-width: ${(p) => p.dimensions}px;
  height: calc(${(p) => p.dimensions}px - 8px - 8px);
  color: ${(props) => props.theme.colors.black80};
  svg {
    height: 20px;
    width: 20px;
    ${(p) =>
      p.isAvatar &&
      css`
        height: 28px;
        width: 28px;
        ${p.hasCaption &&
        css`
          svg {
            height: 32px;
            width: 32px;
          }
        `}
      `}
  }
`;

type ListItemContainerProps = {
  onClick?: any;
};
const ListItemContainer = styled.div<ListItemContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) =>
    p.onClick &&
    css`
      :hover {
        cursor: pointer;
        background: ${(p) => p.theme.colors.gray2};
      }
    `}
  padding: 8px 5px;
  min-width: 180px;
  user-select: none;
`;

export const LIST_ITEM_PADDING_LEFT = "12px";

type ListItemProps = {
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  avatar?: JSX.Element;
  caption?: string;
  isLarge?: boolean;
  isSelected?: boolean;
  children: JSX.Element | string;
} & React.BaseHTMLAttributes<HTMLDivElement>;
export const ListItem = ({
  leftIcon,
  rightIcon,
  avatar,
  children,
  caption,
  isSelected = false,
  isLarge = false,
  ...props
}: ListItemProps) => {
  const leftUI = leftIcon || avatar;
  const pl = leftUI ? "0" : LIST_ITEM_PADDING_LEFT;

  if (!caption) {
    const minHeight = 44;
    return (
      <ListItemContainer
        style={{ minHeight: minHeight, paddingLeft: pl }}
        {...props}
      >
        <div
          style={{
            paddingLeft: "11px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          {leftUI && (
            <IconContainer dimensions={minHeight} isAvatar={!!avatar}>
              {leftUI}
            </IconContainer>
          )}
          <Li style={{ paddingRight: "3px" }}>{children}</Li>
        </div>
        {rightIcon && (
          <IconContainer dimensions={minHeight}>{rightIcon}</IconContainer>
        )}
      </ListItemContainer>
    );
  } else {
    if (!avatar) {
      const minHeight = 44;
      const textML = leftUI ? 7 : 0;

      return (
        <ListItemContainer
          style={{ minHeight: minHeight, paddingLeft: pl }}
          {...props}
        >
          <div>
            <div style={{ textAlign: "center" }}>
              {leftUI && (
                <IconContainer dimensions={minHeight}>{leftUI}</IconContainer>
              )}
              {isLarge ? (
                <div style={{ marginLeft: textML }}>
                  <H3>{children}</H3>
                </div>
              ) : (
                <Li hasCaption>{children}</Li>
              )}
            </div>
            <div style={{ marginLeft: leftUI ? minHeight : 0 }}>
              {isLarge ? (
                <Body1 style={{ marginTop: "4px", marginLeft: textML }}>
                  {caption}
                </Body1>
              ) : (
                <Caption>{caption}</Caption>
              )}
            </div>
          </div>
          {rightIcon && (
            <IconContainer dimensions={minHeight}>{rightIcon}</IconContainer>
          )}
        </ListItemContainer>
      );
    } else {
      const minHeight = 52;
      return (
        <ListItemContainer
          style={{ minHeight: minHeight, paddingLeft: pl }}
          {...props}
        >
          <div style={{ textAlign: "center" }}>
            <IconContainer
              dimensions={minHeight}
              isAvatar={true}
              hasCaption={true}
            >
              {avatar}
            </IconContainer>
            <div>
              <Li>{children}</Li>
              <Caption>{caption}</Caption>
            </div>
          </div>
          {rightIcon && (
            <IconContainer dimensions={minHeight}>{rightIcon}</IconContainer>
          )}
        </ListItemContainer>
      );
    }
  }
};
