import * as React from "react";
import { SVGProps } from "react";
export const SvgMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.5,2 C14.0522847,2 14.5,2.44771525 14.5,3 L14.5,17 C14.5,17.5522847 14.0522847,18 13.5,18 L6.5,18 C5.94771525,18 5.5,17.5522847 5.5,17 L5.5,3 C5.5,2.44771525 5.94771525,2 6.5,2 L13.5,2 Z M13.5,3 L6.5,3 L6.5,17 L13.5,17 L13.5,3 Z M10,4 C10.2761424,4 10.5,4.22385763 10.5,4.5 C10.5,4.77614237 10.2761424,5 10,5 C9.72385763,5 9.5,4.77614237 9.5,4.5 C9.5,4.22385763 9.72385763,4 10,4 Z M8,15 L12,15 C12.2761424,15 12.5,15.2238576 12.5,15.5 C12.5,15.7761424 12.2761424,16 12,16 L8,16 C7.72385763,16 7.5,15.7761424 7.5,15.5 C7.5,15.2238576 7.72385763,15 8,15 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
