import React from "react";
import styled, { css } from "styled-components";

type RadioContainerProps = {
  isSelected: boolean;
};
export const RadioContainer = styled.div<RadioContainerProps>`
  height: 16px;
  width: 16px;
  border: 1px solid ${(p) => p.theme.colors.black80};
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isSelected &&
    css`
      border: 1px solid ${(p) => p.theme.colors.navy};
    `}
`;

export const RadioSelect = styled.div`
  background: ${(p) => p.theme.colors.navy};
  border: 1px solid ${(p) => p.theme.colors.navy};
  height: 10px;
  width: 10px;
  border-radius: 100%;
  animation: grow 0.25s ease-in-out;
  @keyframes grow {
    0% {
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -o-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
    }

    50% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
`;

type Props = {
  isSelected: boolean;
};
export const RadioInput = ({ isSelected }: Props) => {
  return (
    <RadioContainer isSelected={isSelected}>
      {isSelected && <RadioSelect />}
    </RadioContainer>
  );
};
