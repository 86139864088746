import { DateTime } from "luxon";
import { BuilderConfigDocument } from "./index";
import { updateStringVars } from "./preregistration";

interface PostRegContent {
  checkInVote: "checkin" | "vote";
  voteType?: "song" | "vote";
  sweepstakes: boolean;
  postRegistration: "link" | "promo" | "thank";
  headline: string;
  body: string;
  ctalabel?: string;
  cta?: string;
}

export const generatePostRegText = <T>(
  config: Partial<BuilderConfigDocument<T | DateTime>>,
) => {
  const matchingContent = postRegTextContent.find(
    ({ checkInVote, voteType, postRegistration, sweepstakes }) =>
      checkInVote === config.fields?.checkInVote?.content &&
      (voteType || "") === config.fields?.voteType?.content &&
      postRegistration === config.fields?.postRegistration?.content &&
      sweepstakes === config.fields?.sweepstakes?.content,
  );

  const update = (v: string) => {
    return updateStringVars(v, [
      { key: "artist", value: config.artistName || "" },
      {
        key: "title",
        value: config.fields?.postRegistrationAlbum?.content?.name || "",
      },
    ]);
  };

  const updatedContent = {
    headline: update(matchingContent?.headline || ""),
    body: update(matchingContent?.body || ""),
    ctalabel: update(matchingContent?.ctalabel || ""),
    cta: update(matchingContent?.cta || ""),
  };

  return updatedContent;
};

export const postRegTextContent: PostRegContent[] = [
  {
    checkInVote: "checkin",
    sweepstakes: true,
    postRegistration: "promo",
    headline: "Thanks for checking in!",
    body: "Please take a minute to save {title} on your favorite streaming service.",
    ctalabel: "Save",
    cta: "social",
  },
  {
    checkInVote: "checkin",
    sweepstakes: false,
    postRegistration: "promo",
    headline: "Thanks for checking in!",
    body: "Please take a minute to save {title} on your favorite streaming service.",
    ctalabel: "Save",
    cta: "social",
  },
  {
    checkInVote: "vote",
    voteType: "song",
    sweepstakes: true,
    postRegistration: "promo",
    headline: "Thanks for your vote!",
    body: "Please take a minute to save {title} on your favorite streaming service.",
    ctalabel: "Save",
    cta: "social",
  },
  {
    checkInVote: "vote",
    voteType: "song",
    sweepstakes: false,
    postRegistration: "promo",
    headline: "Thanks for your vote!",
    body: "Please take a minute to save {title} on your favorite streaming service.",
    ctalabel: "Save",
    cta: "social",
  },
  {
    checkInVote: "vote",
    voteType: "vote",
    sweepstakes: true,
    postRegistration: "promo",
    headline: "Thanks for taking part in the show!",
    body: "Please take a minute to save {title} on your favorite streaming service.",
    ctalabel: "Save",
    cta: "social",
  },
  {
    checkInVote: "vote",
    voteType: "vote",
    sweepstakes: false,
    postRegistration: "promo",
    headline: "Thanks for taking part in the show!",
    body: "Please take a minute to save {title} on your favorite streaming service.",
    ctalabel: "Save",
    cta: "social",
  },
  {
    checkInVote: "checkin",
    sweepstakes: true,
    postRegistration: "link",
    headline: "Thanks for checking in!",
    body: "Hey check out this link",
    ctalabel: "Click Here",
    cta: "https://",
  },
  {
    checkInVote: "checkin",
    sweepstakes: false,
    postRegistration: "link",
    headline: "Thanks for checking in!",
    body: "Hey check out this link",
    ctalabel: "Click Here",
    cta: "https://",
  },
  {
    checkInVote: "vote",
    voteType: "song",
    sweepstakes: true,
    postRegistration: "link",
    headline: "Thanks for your vote!",
    body: "Hey check out this link",
    ctalabel: "Click Here",
    cta: "https://",
  },
  {
    checkInVote: "vote",
    voteType: "song",
    sweepstakes: false,
    postRegistration: "link",
    headline: "Thanks for your vote!",
    body: "Hey check out this link",
    ctalabel: "Click Here",
    cta: "https://",
  },
  {
    checkInVote: "vote",
    voteType: "vote",
    sweepstakes: true,
    postRegistration: "link",
    headline: "Thanks for taking part in the show!",
    body: "Hey check out this link",
    ctalabel: "Click Here",
    cta: "https://",
  },
  {
    checkInVote: "vote",
    voteType: "vote",
    sweepstakes: false,
    postRegistration: "link",
    headline: "Thanks for taking part in the show!",
    body: "Hey check out this link",
    ctalabel: "Click Here",
    cta: "https://",
  },
  {
    checkInVote: "checkin",
    sweepstakes: true,
    postRegistration: "thank",
    headline: "Thanks for checking in!",
    body: "You're now entered to win and will be the first to hear about {artist} news!",
  },
  {
    checkInVote: "checkin",
    sweepstakes: false,
    postRegistration: "thank",
    headline: "Thanks for checking in!",
    body: "You will now be the first to hear about {artist} news!",
  },
  {
    checkInVote: "vote",
    voteType: "song",
    sweepstakes: true,
    postRegistration: "thank",
    headline: "Thanks for your vote!",
    body: "The winning song will be announced later in the show!",
  },
  {
    checkInVote: "vote",
    voteType: "song",
    sweepstakes: false,
    postRegistration: "thank",
    headline: "Thanks for your vote!",
    body: "The winning song will be announced later in the show!",
  },
  {
    checkInVote: "vote",
    voteType: "vote",
    sweepstakes: true,
    postRegistration: "thank",
    headline: "Thanks for taking part in the show!",
    body: "You're now entered to win!",
  },
  {
    checkInVote: "vote",
    voteType: "vote",
    sweepstakes: false,
    postRegistration: "thank",
    headline: "Thanks for taking part in the show!",
    body: "Your participation is appreciated!",
  },
];
