import { SetLiveDarkLogo } from "../Images/Logos";
import styled from "styled-components";

const LogoImg = styled.img`
  width: 63px;
  height: 20px;
  flex-shrink: 0;
`;

export const LogoKnockout = (props: JSX.IntrinsicAttributes) => {
  return (
    <LogoImg
      src={`${process.env.PUBLIC_URL}/logo/light.svg`}
      alt="logo"
      {...props}
    />
  );
};

export const LogoDark = (props: JSX.IntrinsicAttributes) => {
  return (
    <LogoImg
      src={`${process.env.PUBLIC_URL}/logo/dark.svg`}
      alt="logo"
      {...props}
    />
  );
};
