import {
  addDoc,
  collection,
  DocumentData,
  DocumentReference,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { Button as ButtonBase } from "../melodies-source/Button";
import { TextInput } from "../melodies-source/TextInput";
import { lazy, Suspense, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import styled from "styled-components";
import { useActionContext } from "./ActionContext";
import { Drawer, DrawerButton } from "./Drawer";
import { useEventConfig } from "./EventConfig";
import { firebaseApp } from "./Firebase";
import { useAuth } from "./UserContext";
import { EventTypes } from "@max/common";

const PaymentInner = lazy(() => import("./PaymentInner"));

export const PaymentForm = () => {
  const db = getFirestore(firebaseApp);
  const { user } = useAuth();
  const { eventId, type } = useEventConfig();
  const { handleAction } = useActionContext();
  const [transaction, setTransaction] = useState<
    boolean | DocumentReference<DocumentData>
  >(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>("1");
  const [transactionDoc] = useDocument(
    transaction as DocumentReference<DocumentData>,
  );
  const parsed = parseInt(amount);
  const handleNext = async () => {
    setLoading(true);
    const payments = collection(db, `set_fresh_events/${eventId}/payments`);
    const transaction = await addDoc(payments, {
      uid: user!.uid,
      amount: parsed * 100,
      status: "draft",
    });
    setTransaction(transaction);
  };
  const handleComplete = () => {
    handleAction("close");
    setDoc(
      transaction as DocumentReference<DocumentData>,
      { status: "submitted" },
      { merge: true },
    );
  };
  const increaseInput = () => {
    setAmount((prev: string) => (parseInt(prev) + 1).toString());
  };
  const decreaseInput = () => {
    setAmount((prev: string) => {
      if (parseInt(prev) <= 1) {
        return (prev = "1");
      } else {
        return (parseInt(prev) - 1).toString();
      }
    });
  };
  return (
    <Drawer
      {...(!transactionDoc && {
        title: "Enter your donation amount",
      })}
    >
      {transactionDoc ? (
        <Suspense fallback={<div>Loading...</div>}>
          <PaymentInner doc={transactionDoc} handleComplete={handleComplete} />
        </Suspense>
      ) : (
        <>
          <InputWrap>
            <span onClick={decreaseInput}>–</span>
            <DonationInput
              type={"number"}
              min="1"
              pattern="[0-9]*"
              inputMode="numeric"
              value={amount}
              onChange={setAmount}
              leftIcon={<DollarSign>$</DollarSign>}
            />
            <span onClick={increaseInput}>+</span>
          </InputWrap>
          <DonateButton
            isDisabled={loading || !parsed}
            onClick={handleNext}
            isSecondaryTextColor={type === EventTypes.Round}
          >
            {loading
              ? "Loading..."
              : parsed > 0
              ? `Donate $${parsed}`
              : "Enter a dollar amount"}
          </DonateButton>
        </>
      )}
    </Drawer>
  );
};

const Button = styled(ButtonBase)`
  background-color: var(--template-primary);
  border-radius: var(--template-border-radius);
  color: var(--template-secondary);
  font-family: var(--template-font-family);
  &:hover {
    background-color: var(--template-primary);
    color: var(--template-secondary);
  }
`;
const DollarSign = styled.span`
  margin: 0 0 0 15px;
  display: inline-flex;
  height: 100%;
  color: #000;
  font-size: 25px;
  align-items: center;
  justify-content: center;
`;
const DonateButton = styled(DrawerButton)`
  margin-top: 20px;
`;
const Title = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;
const DonationInput = styled(TextInput)``;
const InputWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
  align-items: center;
  margin: 0 auto;

  & > span {
    border-radius: 8px;
    display: flex;
    height: 30px;
    padding: 0 7px 15px 7px;
    font-size: 30px;
    background: var(--template-primary);
    color: #fff;
    line-height: 30px;
  }
  ${DonationInput} {
    & > div {
      width: 100%;
      background: transparent;
    }
    input {
      background: transparent;
      box-shadow: none;
      border: 1px solid transparent;
      font-size: 30px;
      width: 110px;
      margin: 0 10px;
      text-align: center;
      &:focus {
        box-shadow: 1px 1px 4px #bfbfbf;
      }
    }
  }
`;
