import { firebaseApp } from "../Components";
import { doc, getFirestore, setDoc, Timestamp } from "firebase/firestore";
import {
  createContext,
  ProviderProps,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { demoConfig } from "../Configs";
import styled from "styled-components";

import { useUserActionTrackingContext } from "../Components/UserActionTrackingContext";

import { EventConfig as EventConfigBase, EventTypes } from "@max/common/event";

type EventConfig = EventConfigBase<Timestamp>;

const isDev = process.env.REACT_APP_ENV === "dev";

type AdditionalConfig = {
  eventId: string;
  handleNavigateHome: Function;
};

const defaultEvent: EventConfig & AdditionalConfig = {
  eventId: "default",
  type: EventTypes.Swiper,
  header: {
    artistName: "Loading",
    date: Timestamp.now(),
    image: "https://placekitten.com/800/800",
  },
  handleNavigateHome: () => {},
};

const EventConfigContext = createContext<EventConfig & AdditionalConfig>(
  defaultEvent,
);

export const MockEventConfigProvider = ({
  mock,
  eventId,
  ...props
}: {
  mock: EventConfig;
  eventId: string;
} & JSX.IntrinsicAttributes &
  Omit<ProviderProps<EventConfig>, "value">) => {
  const value = {
    ...mock,
    eventId,
    handleNavigateHome: () => {},
  };
  return <EventConfigContext.Provider value={value} {...props} />;
};

export const EventConfigProvider = ({
  children,
  ...props
}: JSX.IntrinsicAttributes & Omit<ProviderProps<EventConfig>, "value">) => {
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false);
  const db = getFirestore(firebaseApp);
  const { i18n } = useTranslation();
  const { eventId } = useParams() as { eventId: string };
  const [noResult, setNoResult] = useState(false);

  const docRef = doc(
    db,
    `/set_fresh_events/${eventId}/versions/${
      noResult ? "en" : i18n.resolvedLanguage
    }`,
  );
  const [config, l, e] = useDocumentData(docRef);

  const handleNavigateHome = () => {
    //@ts-ignore
    if (window?.IS_BRANDED) {
      window.location.assign(`${window.location.origin}/?noredirect`);
    } else {
      navigate("/");
    }
  };

  useEffect(() => {
    if (!l && !config) {
      setNoResult(true);
    }
  }, [l]);

  const { track } = useUserActionTrackingContext();

  useEffect(() => {
    if (!l && !config && noResult) {
      handleNavigateHome();
    }
  }, [config, l]);

  useEffect(() => {
    if (isLoaded) {
      track({ event: "visit" });
    }
  }, [eventId, track, isLoaded]);

  let saveConfig;

  if (isDev) {
    saveConfig = () => {
      setDoc(docRef, demoConfig);
    };
  }

  useEffect(() => {
    if (isLoaded && isDev) {
      window.location.reload(); //shitty hotreloading hack
    }
    setIsLoaded(true);
  }, [demoConfig]);

  const c = isDev ? demoConfig : config; //if you're in demo env you'll just load demoConfig.tsx

  const val: EventConfig = (c as EventConfig) || defaultEvent;

  if (val === defaultEvent) {
    return null;
  }

  return (
    <EventConfigContext.Provider
      {...props}
      value={{ ...val, eventId, handleNavigateHome }}
    >
      {children}
      {/* {isDev && (
        <Uploader onClick={saveConfig}>
          Save Config to /set_fresh_events/{eventId}/versions/{i18n.language}
        </Uploader>
      )} */}
    </EventConfigContext.Provider>
  );
};

const Uploader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: purple;
  color: orange;
  margin: 0 auto;
  z-index: 10;
`;

export const useEventConfig = () => useContext(EventConfigContext);
