import { isVisible, useDataContext } from "../../../../../Components";
import { useState } from "react";
import styled, { css } from "styled-components";
import { Card } from ".";
import { CardType } from "@max/common";

export const Cards = ({ cards }: { cards: CardType[] }) => {
  const { data } = useDataContext();
  const [view, setView] = useState<number>(0);
  const visibles = cards.reduce((acc, cur) => {
    if (isVisible(cur.visible, data)) {
      acc.push(cur);
    }
    return acc;
  }, [] as CardType[]);

  return (
    <Container>
      <NavBar singleTab={visibles.length === 1}>
        {visibles.map((c, idx) => (
          <NavItem
            onClick={() => setView(idx)}
            active={view === idx}
            key={`cards-tab-${idx}`}
          >
            {c.tab || ""}
          </NavItem>
        ))}
      </NavBar>
      <Card {...visibles[view]} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavBar = styled.div<{ singleTab?: boolean }>`
  color: #fff;
  border-radius: 0px;
  height: 36px;
  display: inline-flex;
  overflow: hidden;
  justify-content: center;
  margin: 0 20px 20px;
  flex-shrink: 0;
  ${({ singleTab }) =>
    singleTab &&
    css`
      display: none;
    `}
`;

const NavItem = styled.div<{ active?: boolean }>`
  padding: 8px 16px;
  border: 1.5px solid #fff;
  border-left: none;
  border-right: none;
  text-align: center;
  width: 100%;
  border-radius: 0px;
  font-family: var(--template-font-family);
  ${({ active }) =>
    active &&
    css`
      border: 1.5px solid var(--template-primary);
      color: #fff;
      background: var(--template-primary);
      font-weight: 400;
    `}
`;
