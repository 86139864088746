import { SurveyModule, INVALID_MODULE, SurveyModuleProps } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import {
  SubmitBuilderConfigModule,
  TranslateableModuleContent,
} from "../creator";

export class SubmitModule extends SurveyModule {
  protected _type: ModuleType = "submit";
  href?: TranslateableModuleContent;
  label: TranslateableModuleContent;
  complete: boolean;

  constructor({
    label = { en: "" },
    noBorder = true,
    complete = false,
    href,
    ...rest
  }: SurveyModuleProps & {
    href?: TranslateableModuleContent;
    label?: TranslateableModuleContent;
    complete?: boolean;
  } = {}) {
    super(rest);

    this.href = href;
    this.label = label;
    this.noBorder = noBorder;
    this.complete = complete;
  }

  validate(): this is Required<SubmitModule> {
    return true;
  }

  toBuilderConfig(): SubmitBuilderConfigModule {
    if (this.validate()) {
      const builderConfigModule: SubmitBuilderConfigModule = {
        id: this.id,
        style: this.style,
        complete: this.complete,
        label: this.label,
        type: this.type,
      };
      if (this.tag) {
        builderConfigModule.tag = this.tag;
      }
      if (this.href) {
        builderConfigModule.href = this.href;
      }
      return builderConfigModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    const surveyConfigModule: SurveyConfigModule = {
      id: this.id,
      style: this.style,
      children: this.label[this.languageCode],
      complete: this.complete,
      type: this.type,
    };
    // needed to ensure empty strings can be passed for the use of featuredCtas in the preview
    if (typeof this.href?.[this.languageCode] === "string") {
      surveyConfigModule.href = this.href[this.languageCode];
    }
    return surveyConfigModule;
  }

  editProps() {
    return undefined;
  }
}
