import { SvgHelpAlt } from "../../melodies-source/Svgs/HelpAlt";
import React, { useState } from "react";
import styled from "styled-components";
import { HelpModal } from "./HelpModal";

export const HelpIconModal = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {!window.IS_BRANDED && (
        <>
          <HelpIcon onClick={() => setIsOpen(true)} {...props} />
          <HelpModal open={isOpen} setOpen={setIsOpen} />
        </>
      )}
    </>
  );
};

const HelpIcon = styled(SvgHelpAlt)`
  width: 20px;
  height: 25px;
  color: #ffffff;
`;
