import React, { useEffect } from "react";

/**
 * Returns a fully formed URL for a SET subdomain based on the current env.
 * Example:
 *
 *    getSetUrl("backstage") // prod: backstage.set.live, stage: backstage.dev-set.live
 *
 */
const getSetUrl = (subdomain: string, path: string = "/") => {
  const domain =
    process.env.REACT_APP_ENV === "production" ? "set.live" : "dev-set.live";
  return new URL(path, `https://${subdomain}.${domain}`).toString();
};

export const Artist: React.FC = () => {
  useEffect(() => {
    window.location.href = getSetUrl("backstage");
  }, []);
  return null;
};
