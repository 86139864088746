import { isVisible, useDataContext } from "../../../../../Components";
import { Body2 } from "../../../../../melodies-source/Text";
import { useState } from "react";
import styled, { css } from "styled-components";
import { Card } from ".";
import { ReactComponent as TabCircle } from "./TabCircle.svg";
import { CardType } from "@max/common";

export const Cards = ({ cards }: { cards: CardType[] }) => {
  const { data } = useDataContext();
  const [view, setView] = useState<number>(0);

  const visibles = cards.reduce((acc, cur) => {
    if (isVisible(cur.visible, data)) {
      acc.push(cur);
    }
    return acc;
  }, [] as CardType[]);

  return (
    <Container>
      {visibles?.length > 1 ? (
        <NavBar>
          {visibles.map((c, i) => (
            <NavItem onClick={() => setView(i)} active={view === i} key={i}>
              <Body2>{c.tab || ""}</Body2>
              <TabCircle />
            </NavItem>
          ))}
        </NavBar>
      ) : (
        <Container style={{ flex: "0 0 20px" }} />
      )}
      <Card {...visibles[view]} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  overflow: hidden;
`;

const NavBar = styled.div`
  display: inline-flex;
  align-self: center;
  margin: 20px 20px 32px;
`;

const NavItem = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 24px;
  padding: 10px 16px;
  position: relative;

  & + & {
    margin-left: 12px;
  }

  ${Body2} {
    color: var(--template-text);
    font-family: var(--template-font-family);
    font-size: 15px;
    line-height: 28px;
  }

  svg {
    fill: none;
    stroke-width: 3;
    stroke-miterlimit: 10;
    stroke-dasharray: 338;
    stroke-dashoffset: 338;
    stroke-linecap: round;
    position: absolute;
    top: 40%;
    left: 50%;
    width: calc(100% + 20px);
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: stroke-dashoffset 0s 0.2s, opacity 0.2s;
    z-index: 3;
  }

  ${({ active }) =>
    active &&
    css`
      ${Body2} {
        color: var(--template-text);
      }
      svg {
        stroke: var(--template-primary);
        stroke-dashoffset: 0;
        opacity: 1;
        transition: opacity 0s,
          stroke-dashoffset 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    `}
`;
