import { isVisible, useDataContext } from "../../../../../Components";
import { Body2 } from "../../../../../melodies-source/Text";
import { useState } from "react";
import styled, { css } from "styled-components";
import { Card } from ".";
import { CardType } from "@max/common";

export const Cards = ({ cards }: { cards: CardType[] }) => {
  const { data } = useDataContext();
  const [view, setView] = useState<number>(0);
  const visibles = cards.reduce((acc, cur) => {
    if (isVisible(cur.visible, data)) {
      acc.push(cur);
    }
    return acc;
  }, [] as CardType[]);

  return (
    <Container>
      {visibles?.length > 1 ? (
        <NavBar>
          {visibles.map((c, i) => (
            <NavItem onClick={() => setView(i)} active={view === i} key={i}>
              <Body2>{c.tab || ""}</Body2>
            </NavItem>
          ))}
        </NavBar>
      ) : (
        <Container style={{ flex: "0 0 20px" }} />
      )}
      <Card {...visibles[view]} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 auto;
  overflow: hidden;
`;

const NavBar = styled.div`
  color: #ffffff;
  display: inline-flex;
  align-self: center;
  margin: 20px 20px 20px;
`;

const NavItem = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  border-color: var(--template-primary);
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  padding: 6px 16px;
  border-radius: 20px;

  & + & {
    margin-left: 12px;
  }

  ${Body2} {
    color: var(--template-primary);
    font-family: var(--template-font-family);
    font-size: 14px;
    font-weight: 600;
  }
  ${({ active }) =>
    active &&
    css`
      background-color: var(--template-primary);
      ${Body2} {
        color: #ffffff;
      }
    `}
`;
