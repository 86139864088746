import styled from "styled-components";
import mainImageSrc from "./MainImage.jpg";
import { SvgHelpAlt } from "../../../melodies-source/Svgs/HelpAlt";
import { Body2, H2, Subtitle1, Subtitle2 } from "../../../melodies-source/Text";
import { Button as ButtonBase } from "../../../melodies-source/Button";
import { SvgLocationAlt } from "../../../melodies-source/Svgs/LocationAlt";
import { SvgTicket } from "../../../melodies-source/Svgs/Ticket";
import { colors } from "../../../melodies-source/Theme";
import { SetLiveKnockoutLogo } from "../../../Images/Logos";
import { HelpIconModal } from "../../../Components/HelpModal";
import { useTranslation } from "react-i18next";

interface SearchBoxProps {
  children?: React.ReactNode;
  isLocationLoading: boolean;
  locationErrorMessage?: string;
  onLocationRequest: VoidFunction;
  location: boolean;
  noResults: boolean;
}

export const SearchBox = ({
  children,
  isLocationLoading,
  onLocationRequest,
  locationErrorMessage,
  location,
  noResults,
}: SearchBoxProps) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Head>
        <Logo />
        <HelpIconModal />
      </Head>
      <Content>
        <H2 style={{ marginBottom: "24px" }}>{t("Find Your Event")}</H2>
        {children}
        {location ? (
          noResults ? (
            <ErrorBox>
              <ErrorIcon />
              <div>
                We couldn’t find any shows near you. Please search in the field
                above.
              </div>
            </ErrorBox>
          ) : (
            <>
              <Divider>
                <Line />
                <DividerText>
                  <Subtitle1>or</Subtitle1>
                </DividerText>
                <Line />
              </Divider>
              <Button
                variant="tertiary"
                onClick={onLocationRequest}
                isDisabled={isLocationLoading}
                style={{ opacity: isLocationLoading ? 0.6 : 1 }}
              >
                {isLocationLoading ? (
                  <LoaderInline />
                ) : (
                  <SvgLocationAlt style={{ marginRight: 12 }} />
                )}

                {isLocationLoading
                  ? t("Finding Your Show")
                  : t("Use My Location")}
              </Button>
              <Subtitle2>
                {locationErrorMessage
                  ? t(locationErrorMessage)
                  : t("Please enable location services to find nearby shows.")}
              </Subtitle2>
            </>
          )
        ) : (
          <Spacer />
        )}
      </Content>
      <MaskedContainer>
        <TicketContainer>
          <TicketIcon />
        </TicketContainer>
        <GradientColor />
        <SmallGradient />
        <LargeGradient />
        <MainImage src={mainImageSrc} />
      </MaskedContainer>
    </Wrapper>
  );
};

const ErrorBox = styled.div`
  display: flex;
  background: rgba(255, 250, 232, 0.87);
  border: 1px solid #ffeca6;
  color: #000;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 6px;
  padding: 10px;
  align-items: center;
  margin: 20px 0 70px 0;
`;

const ErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    style={{ flexShrink: 0, marginRight: "10px" }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9811 3.60655L10.9219 3.50253C10.6229 3.03764 10.0153 2.86319 9.50961 3.11603C9.29734 3.22217 9.12523 3.39428 9.01909 3.60655L3.11603 15.4127L3.06834 15.5224C2.87584 16.0406 3.10086 16.6314 3.60655 16.8842C3.75885 16.9604 3.92679 17 4.09706 17H15.9032L16.0227 16.9936C16.5722 16.934 17 16.4686 17 15.9032C17 15.7329 16.9604 15.565 16.8842 15.4127L10.9811 3.60655ZM9.95682 4.01046L9.99393 4.00041C10.0316 3.99802 10.0688 4.01789 10.0867 4.05376L15.9898 15.8599L15.9974 15.8809L16 15.9032C16 15.9567 15.9567 16 15.9032 16H4.09706L4.07482 15.9974L4.05376 15.9898C4.00593 15.9659 3.98655 15.9077 4.01046 15.8599L9.91352 4.05376L9.93166 4.0286L9.95682 4.01046ZM10 13.5C10.4142 13.5 10.75 13.8358 10.75 14.25C10.75 14.6642 10.4142 15 10 15C9.58579 15 9.25 14.6642 9.25 14.25C9.25 13.8358 9.58579 13.5 10 13.5ZM10.5 12.5V8H9.5V12.5H10.5Z"
      fill="black"
    />
  </svg>
);

const Spacer = styled.div`
  height: 100px;
`;

const TicketContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 2;
`;

const TicketIcon = styled(SvgTicket)`
  color: #ffffff;
  width: 224px;
  height: 224px;
  transform: rotate(30deg);
  opacity: 0.08;
  position: absolute;
  right: -12%;
  top: calc(50% - 224px / 2 - 102px);
  ${({ theme }) => theme.mediaQueries.desktop} {
    width: 434px;
    height: 434px;
    right: -14%;
    top: calc(50% - 434px / 2 - 90px);
  }
`;

const Button = styled(ButtonBase)`
  border-radius: 22px;
  color: #ffffff;
  border: 2px solid #ffffff;
  line-height: 24px;
  &:hover {
    background: #ffffff1a;
    color: #ffffff;
  }
`;

const HelpIcon = styled(SvgHelpAlt)`
  width: 20px;
  height: 25px;
  color: #ffffff;
`;

const Logo = styled(SetLiveKnockoutLogo)`
  width: 16.15vw;
  height: auto;
  ${({ theme }) => theme.mediaQueries.desktop} {
    width: 100px;
    height: 32px;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0;
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  z-index: 10;
  ${({ theme }) => theme.mediaQueries.desktop} {
    height: 32px;
    padding: 0 32px;
    top: 32px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 56px 32px;
  position: relative;
  flex: 1 1 100%;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  z-index: 3;
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  ${H2}, ${Body2}, ${Subtitle2} {
    color: #ffffff;
    width: 100%;
    text-align: center;
  }
  ${H2} {
    font-weight: 700;
    text-transform: uppercase;
  }
  ${Body2} {
    margin-bottom: 16px;
    font-weight: 500;
  }
  ${Subtitle2} {
    opacity: 0.7;
    font-weight: 500;
    margin-top: 8px;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    ${H2} {
      font-size: 34px;
      line-height: 50px;
    }
    ${Body2} {
      font-size: 15px;
      line-height: 22px;
    }
    ${Subtitle2} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 616px;
  height: min(109.67vw, 658px);
  flex-shrink: 0;
  padding: 8px;
  position: relative;
  ${({ theme }) => theme.mediaQueries.desktop} {
    height: 658px;
  }
`;

const MaskedContainer = styled.div`
  display: flex;
  flex-direction: column;
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' preserveAspectRatio='none meet' viewBox='0 0 374 410'%3E%3Cpath fill='%23000' d='M0 16C0 7.163 7.163 0 16 0h342c8.837 0 16 7.163 16 16v344.466c0 8.243-6.262 15.137-14.467 15.927l-342 32.919C8.134 410.217 0 402.828 0 393.386V16Z'/%3E%3C/svg%3E");
  mask-repeat: no-repeat;
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  left: 8px;
`;

const GradientColor = styled.div`
  background: linear-gradient(
    47.52deg,
    #e71e3d 0.2%,
    rgba(231, 30, 61, 0.83) 19.05%,
    rgba(27, 0, 118, 0.73) 76.76%,
    #1b0076 96.25%
  );
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const LargeGradient = styled.div`
  background: linear-gradient(
    52.41deg,
    rgba(255, 255, 255, 0.03) 29.8%,
    rgba(255, 255, 255, 0.13) 67.19%
  );
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  clip-path: polygon(0 36.55%, 0% 100%, 113.6% 93.33%);
`;

const SmallGradient = styled.div`
  background: linear-gradient(320deg, #d9d9d90a 19%, #d9d9d975 65%);
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  clip-path: polygon(100% 65.26%, 76.47% 74.7%, 100% 86.5%);
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

const Line = styled.div`
  display: flex;
  flex: 1 1 100%;
  height: 1px;
  border-radius: 3px;
  background-color: #ffffff;
  opacity: 0.3;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 20px;
  width: 100%;
  margin: 16px 0;
  ${({ theme }) => theme.mediaQueries.desktop} {
    margin: 24px 0;
  }
`;

const DividerText = styled.div`
  display: flex;
  justify-content: center;
  flex: 0 0 48px;
  ${Subtitle1} {
    color: #ffffff;
  }
`;

const LoaderInline = styled.div`
  &,
  &:after {
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
  margin-right: 12px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 2px solid #ffffff50;
  border-right: 2px solid #ffffff50;
  border-bottom: 2px solid #ffffff50;
  border-left: 2px solid ${colors.gray4};
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Mask = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" preserveAspectRatio="none meet" viewBox="0 0 374 410">
<path fill="#000" d="M0 16C0 7.163 7.163 0 16 0h342c8.837 0 16 7.163 16 16v344.466c0 8.243-6.262 15.137-14.467 15.927l-342 32.919C8.134 410.217 0 402.828 0 393.386V16Z"/>
</svg>`;
