import { CardType, EventConfig, UserEventStates } from "@max/common";
import { Timestamp } from "firebase/firestore";

const demoCheckInCard: CardType = {
  visible: {
    states: [UserEventStates.draft],
  },
  tab: "Check In",
  headline: "Want a chance to win<br />some signed merch?",
  body: "Check in and enter for a chance to win<br />signed merch for Milky Chance",
  cta: "register",
  ctalabel: "Check In",
};

const demoVoteCard: CardType = {
  tab: "Fan Favorite",
  headline: "Vote for our Encore",
  songs: [
    { id: "fFXwYxvL", title: "Rerun", album: "Honey Revenge" },
    { id: "pXPmVyAr", title: "Distracted", album: "Honey Revenge" },
    { id: "wNKj3taS", title: "Miss Me", album: "Honey Revenge" },
    { id: "3NKjrt4S", title: "Song Me", album: "Honey Revenge" },
    { id: "1N55rtaS", title: "Another Me", album: "Honey Revenge" },
  ],
};

const demoCheckInCompleteCard: CardType = {
  visible: {
    states: [UserEventStates.draft],
    hide: true,
  },
  tab: "Check In",
  headline: "Thank You!",
  body: "You can save now my recent release",
  cta: "social",
  ctalabel: "Save",
  image: "https://placekitten.com/200/200",
};

const demoInfoCard: CardType = {
  headline: "Info Goes Here",
  image: "https://placekitten.com/200/200",
  body: "Some other cool info",
  cta: "https://google.com",
  ctalabel: "Go go!",
};

const demoPaymentCard: CardType = {
  headline: "Donate",
  icon: "donate",
  body: "Donating appreciated securities such as stocks or mutual funds is a smart and easy way to support Young Artists of America.",
  cta: "payment",
  ctalabel: "Donate!",
};

const demoMerchCard: CardType = {
  tab: "Merch",
  headline: "Merch",
  icon: "shirt",
  merch: [
    {
      name: "T-Shirt",
      photo: "https://placekitten.com/200/200",
      url: "https://google.com",
    },
    {
      name: "VIP Shirt",
      photo: "https://placekitten.com/200/240",
      url: "https://google.com",
    },
    {
      name: "Long Name Sweater",
      photo: "https://placekitten.com/200/200",
      url: "https://google.com",
    },
    {
      name: "Fur Gloves",
      photo: "https://placekitten.com/200/200",
      url: "https://google.com",
    },
    {
      name: "LongNameHat",
      photo: "https://placekitten.com/200/200",
      url: "https://google.com",
    },
  ],
};

const demoHtmlCard: CardType = {
  tab: "whatevs",
  html: `<div style="width: 200px;
  height: 200px;
  background: pink;
  flex-shrink: 0;
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;">kewl<span style="
  font-family: monospace;
  font-size: 20px;
  padding: 10px;
">html</span> content</div>`,
};

export const demoConfigCards: EventConfig<Timestamp> = {
  type: 2,
  header: {
    artistName: "Artist",
    image:
      "https://storage.googleapis.com/set-live.appspot.com/creator/default-image-square.jpg",
    date: Timestamp.now(),
    venue: "The Masquerade",
    location: "Atlanta, GA",
  },
  register: {
    title: "Register for a chance to win!",
    terms: `I agree to share the above information with <span style="font-weight: 800">Artist</span> and Set.Live.`,
    ctalabel: "Vote",
  },
  social: {
    title: "You can now save my album<br />on these platforms",
    spotify: "https://",
    apple: "https://",
    amazon: "https://",
    deezer: "https://",
    youtube: "https://",
  },
  socialLinks: {
    facebook: "#",
    twitter: "#",
    instagram: "#",
    spotify: "#",
  },
  cards: [
    // demoHtmlCard,
    // demoCheckInCard,
    // demoCheckInCompleteCard,
    demoVoteCard,
    demoPaymentCard,
    demoMerchCard,
  ],
};
