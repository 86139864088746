import styled, { createGlobalStyle } from "styled-components";
import {
  CalendarIcon as CalendarIconBase,
  LogoKnockout,
  SocialIcons,
  useEventConfig,
} from "../../../../Components";
import { Cards } from "./Components";
import { useEffect } from "react";
import { Theme, theme as mTheme } from "../../../../melodies-source/Theme";
import { Footer } from "../../Components/Footer";
import { HelpIconModal } from "../../../../Components/HelpModal";
import { useTranslation } from "react-i18next";

export const Boxy = () => {
  const config = useEventConfig();
  const { t } = useTranslation();

  const theme = {
    ...mTheme,
    fonts: {
      ...mTheme.fonts,
      families: ["Lato:wght@300;400;700;900"],
      poppins: "font-family: 'Lato', sans-serif;",
    },
  };
  const templateConfig = {
    primary: config?.colors?.dark || "#AA1818",
    secondary: "#ffffff",
    text: "#ffffff",
    fontFamily: "'Lato', sans-serif",
    borderRadius: "0px",
  };

  const GlobalStyle = createGlobalStyle`
  #event-root-boxy {
    --template-text: ${templateConfig.text};
    --template-primary: ${templateConfig.primary};
    --template-secondary: ${templateConfig.secondary};
    --template-font-family: ${templateConfig.fontFamily};
    --template-border-radius: ${templateConfig.borderRadius};
    --max-action: ${templateConfig.primary};
  }
  body {
    background: #000000;
  }
`;

  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#000000");
  }, []);
  return (
    //@ts-ignore
    <Theme theme={theme}>
      <GlobalStyle />
      <Container>
        <ColorWrap>
          <HeaderWrapper>
            <Header url={config?.header?.image}>
              <HeaderContainer>
                <LogoKnockout />
                <Text>
                  <span
                    style={{ display: "inline-block" }}
                    onClick={() => config?.handleNavigateHome()}
                  >
                    {t("Exit Show")}
                  </span>{" "}
                  <HelpIconModal style={{ marginLeft: 12 }} />
                </Text>
              </HeaderContainer>
              <DateContainer>
                <NameContainer>
                  <Text name={true}>{config?.header?.artistName}</Text>
                  <Text>{config?.header?.venue}</Text>
                </NameContainer>
                <CalendarIcon date={config.header.date} />
              </DateContainer>
            </Header>
          </HeaderWrapper>
          <Content>{!!config?.cards && <Cards cards={config.cards} />}</Content>
          <FooterContainer>
            <SocialIcons />
            <FooterStyled />
          </FooterContainer>
        </ColorWrap>
      </Container>
    </Theme>
  );
};

const FooterStyled = styled(Footer)`
  color: #999999;
  span {
    color: #cccccc;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  color: #fff;
  padding: 0 0 10px 0;
  background: #000;
`;
const ColorWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  background-color: #000;
  margin: 0 auto;
  max-width: 600px;
  width: 100%;
`;
const Content = styled.div`
  z-index: 10;
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-height: 450px;
`;

const HeaderWrapper = styled.div`
  padding-bottom: 100%;
  position: relative;
`;

const Header = styled.div<{ url?: string }>`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: space-between;
  font-family: var(--template-font-family);
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.2) 14%,
      rgba(0, 0, 0, 0) 21%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.1) 67%,
      rgba(0, 0, 0, 0.3) 72%,
      rgba(0, 0, 0, 0.75) 80%,
      rgba(0, 0, 0, 1) 91%
    ),
    url(${({ url }) => url});
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Text = styled.div<{ name?: boolean }>`
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  text-shadow: 1px 1px 3px #666;
  z-index: 10;
  font-family: var(--template-font-family);
  ${({ name }) =>
    name &&
    `
    font-size: 23px;
    margin-bottom: 5px;
  `};
`;
const DateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-right: 5px;
`;
const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-left: 5px;
`;
const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;
const CalendarIcon = styled(CalendarIconBase)`
  border-radius: 0px;
`;
