import { CardType } from "@max/common";
import { SvgFizz, SvgUserCheck } from "../Images/Icons";
import { SvgCharityAlt } from "../melodies-source/Svgs/CharityAlt";
import { SvgCheckLarge } from "../melodies-source/Svgs/CheckLarge";
import { SvgChecklist } from "../melodies-source/Svgs/Checklist";
import { SvgShirtAlt } from "../melodies-source/Svgs/ShirtAlt";
import styled from "styled-components";

interface CardIconProps {
  icon: CardType["icon"];
  customIcon?: string;
}

const icons = {
  checkmark: <SvgCheckLarge />,
  shirt: <SvgShirtAlt />,
  donate: <SvgCharityAlt />,
  checklist: <SvgChecklist />,
  user: <SvgUserCheck />,
  fizz: <SvgFizz />,
};

export const CardIcon = ({ icon, customIcon }: CardIconProps) => {
  if (customIcon?.startsWith("https://")) {
    return <Image src={customIcon} alt="Icon" />;
  }
  if (!icon) return null;
  return icons[icon];
};

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: 4px;
`;
