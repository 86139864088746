import { Routes as RouterRoutes, Route, useNavigate } from "react-router-dom";
import { Event } from "./Event";
import { Landing } from "./Landing";
import { Artist } from "./Artist";
import { Terms } from "../Components/Terms";
import { Privacy } from "../Components/Privacy";
import { Contact } from "./Contact";
import { Select } from "./Select";
import { useEffect } from "react";
import { usePageLoadingContext } from "../Components";
import { ArtistEvent } from "./ArtistEvent";
import { ArtistTerms } from "../Components/ArtistTerms";

export const Routes = () => {
  const { setLoading } = usePageLoadingContext();
  useEffect(() => {
    setLoading(false); //todo: consider leaving this up to views to control?
  }, []);
  return (
    <RouterRoutes>
      <Route path="/" index element={<Landing />} />
      <Route path="/artist/*" element={<Artist />} />
      <Route path="/group/:artistId" element={<ArtistEvent />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/artist-terms" element={<ArtistTerms />} />
      <Route path="/select" element={<Select />} />
      <Route path="/e/:eventId" element={<Event />} />
      <Route path="*" element={<Redirect />} />
    </RouterRoutes>
  );
};

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return null;
};
