import { TextVariant } from ".";
import { CSSServerProperties } from "../creator";

const PC = "profilecategory";
export const DEFAULT_PROFILING_CATEGORIES = [
  `${PC}.email`,
  `${PC}.core`,
  `${PC}.demographics`,
  `${PC}.fooddrink`,
  `${PC}.alcohol`,
  `${PC}.clothing`,
  `${PC}.vehicle`,
  `${PC}.entertainment`,
] as const;
export type ProfilingCategoryId = (typeof DEFAULT_PROFILING_CATEGORIES)[number];

export type ProfilingQuestionId =
  | "email"
  | "name"
  | "phone"
  | "countryCode"
  | "zip"
  | "profiling.yearborn"
  | "profiling.gender"
  | "profiling.musicsource"
  | "profiling.socialusage"
  | "profiling.eaten"
  | "profiling.groceries"
  | "profiling.drinks"
  | "profiling.foodpartner"
  | "profiling.alcoholchoice"
  | "profiling.alcoholpartner"
  | "profiling.buyclothing"
  | "profiling.artistclothing"
  | "profiling.beauty"
  | "profiling.nextvehicle"
  | "profiling.nextvehiclecost"
  | "profiling.vehiclepartner"
  | "profiling.sparetime"
  | "profiling.hobbybrands"
  | "profiling.watchproviders"
  | "profiling.watchtech"
  | `profilecategory.completed.${string}`;

type DefaultProfilingValidation<T extends ValidationType> = {
  type: T;
  message: string;
  min?: T extends "require_count" ? number | undefined : undefined;
  max?: T extends "require_count" ? number | undefined : undefined;
};

export type DefaultProfiling = DefaultProfilingCategory[][];

export type DefaultProfilingCategory = {
  id: ProfilingCategoryId;
  preface?: {
    content: string;
    type: "text";
    style?: CSSServerProperties;
  }[];
  questions: DefaultProfilingQuestion[];
  // fan profile calculations only
  group?: number;
};

type ProfilingQuestion<
  ID extends ProfilingQuestionId,
  T extends
    | "Autocomplete"
    | "CountryQuestion"
    | "EmailQuestion"
    | "MultipleSelectQuestion"
    | "NameQuestion"
    | "PhoneQuestion"
    | "SingleSelectQuestion"
    | "TextQuestion"
    | "YearBorn"
    | "ZipQuestion",
  VT = void | ValidationType,
  Opts = void | { id: string; label: string }[],
> = {
  id: ID;
  type: T;
  variant?: "no-country";
  header?: T extends "ZipQuestion" ? undefined : { children: string };
  options?: Opts extends void
    ? undefined
    : {
        id: string;
        label: string;
        // fan profile calculations only
        adj?: number;
        description?: string;
      }[];
  validation?: VT extends ValidationType
    ? DefaultProfilingValidation<VT>
    : undefined;
  // fan profile calculations only
  adj?: number;
};

// annoying but until we update to typescript 5.2, this will be an issue
// https://devblogs.microsoft.com/typescript/announcing-typescript-5-2/#easier-method-usage-for-unions-of-arrays
type DefaultProfilingQuestion =
  | ProfilingQuestion<"email", "EmailQuestion", "email">
  | ProfilingQuestion<"name", "NameQuestion", "name">
  | ProfilingQuestion<"countryCode", "CountryQuestion">
  | ProfilingQuestion<"phone", "PhoneQuestion", "required">
  | ProfilingQuestion<"zip", "ZipQuestion", "zip">
  | ProfilingQuestion<"profiling.yearborn", "YearBorn", "yearborn">
  | ProfilingQuestion<
      "profiling.gender",
      "SingleSelectQuestion",
      "required",
      [
        { id: "female"; label: string },
        { id: "male"; label: string },
        { id: "nonbinary"; label: string },
        { id: "notsay"; label: string },
      ]
    >
  | ProfilingQuestion<
      "profiling.musicsource",
      "MultipleSelectQuestion",
      "require_count",
      [
        { id: "radio"; label: string },
        { id: "vinyl"; label: string },
        { id: "tapes"; label: string },
        { id: "cd"; label: string },
        { id: "amazon"; label: string },
        { id: "apple"; label: string },
        { id: "pandorafree"; label: string },
        { id: "pandorapaid"; label: string },
        { id: "spotifyfree"; label: string },
        { id: "spotifypaid"; label: string },
        { id: "tidal"; label: string },
        { id: "yt"; label: string },
        { id: "ytm"; label: string },
        { id: "none"; label: string },
      ]
    >
  | ProfilingQuestion<
      "profiling.socialusage",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "bereal"; label: string },
        { id: "discord"; label: string },
        { id: "facebook"; label: string },
        { id: "instagram"; label: string },
        { id: "snapchat"; label: string },
        { id: "tiktok"; label: string },
        { id: "twitch"; label: string },
        { id: "twitter"; label: string },
        { id: "none"; label: string },
      ]
    >
  | ProfilingQuestion<
      "profiling.eaten",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "casual"; label: string },
        { id: "fine"; label: string },
        { id: "delivery"; label: string },
        { id: "mealkit"; label: string },
        { id: "fastfood"; label: string },
      ]
    >
  | ProfilingQuestion<
      "profiling.groceries",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "craving"; label: string },
        { id: "healthy"; label: string },
        { id: "price"; label: string },
        { id: "local"; label: string },
        { id: "deliver"; label: string },
      ]
    >
  | ProfilingQuestion<
      "profiling.drinks",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "dietsoft"; label: string },
        { id: "water"; label: string },
        { id: "tea"; label: string },
        { id: "juice"; label: string },
        { id: "sports"; label: string },
        { id: "soft"; label: string },
        { id: "coffee"; label: string },
        { id: "energy"; label: string },
        { id: "none"; label: string },
      ]
    >
  | ProfilingQuestion<"profiling.foodpartner", "TextQuestion", "required">
  | ProfilingQuestion<
      "profiling.alcoholchoice",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "beer"; label: string },
        { id: "lightbeer"; label: string },
        { id: "importedbeer"; label: string },
        { id: "wine"; label: string },
        { id: "vodka"; label: string },
        { id: "whiskey"; label: string },
        { id: "rum"; label: string },
        { id: "gin"; label: string },
        { id: "tequila"; label: string },
        { id: "premiumliquor"; label: string },
        { id: "cheapliquor"; label: string },
        { id: "cider"; label: string },
        { id: "seltzer"; label: string },
        { id: "none"; label: string },
      ]
    >
  | ProfilingQuestion<"profiling.alcoholpartner", "TextQuestion", "required">
  | ProfilingQuestion<
      "profiling.buyclothing",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "discount"; label: string },
        { id: "mainstream"; label: string },
        { id: "highend"; label: string },
        { id: "onlinemainstream"; label: string },
        { id: "walmart"; label: string },
        { id: "online"; label: string },
        { id: "subscription"; label: string },
      ]
    >
  | ProfilingQuestion<"profiling.artistclothing", "TextQuestion", "required">
  | ProfilingQuestion<
      "profiling.beauty",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "economical"; label: string },
        { id: "premium"; label: string },
        { id: "ethics"; label: string },
        { id: "natural"; label: string },
        { id: "subscription"; label: string },
      ]
    >
  | ProfilingQuestion<
      "profiling.nextvehicle",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "electric"; label: string },
        { id: "hybrid"; label: string },
        { id: "suv"; label: string },
        { id: "pickup"; label: string },
        { id: "car"; label: string },
        { id: "hatchback"; label: string },
        { id: "motorcycle"; label: string },
        { id: "none"; label: string },
      ]
    >
  | ProfilingQuestion<
      "profiling.nextvehiclecost",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "budget"; label: string },
        { id: "highend"; label: string },
        { id: "used"; label: string },
        { id: "new"; label: string },
        { id: "american"; label: string },
        { id: "Import"; label: string },
        { id: "none"; label: string },
      ]
    >
  | ProfilingQuestion<
      "profiling.vehiclepartner",
      "Autocomplete",
      "required",
      [
        { id: "Acura"; label: string },
        { id: "Alfa"; label: string },
        { id: "Aston Martin"; label: string },
        { id: "Audi"; label: string },
        { id: "Bentley"; label: string },
        { id: "BMW"; label: string },
        { id: "Buick"; label: string },
        { id: "Cadillac"; label: string },
        { id: "Chevy/Chevrolet"; label: string },
        { id: "Chrysler"; label: string },
        { id: "Dodge"; label: string },
        { id: "Ferrari"; label: string },
        { id: "FIAT"; label: string },
        { id: "Ford"; label: string },
        { id: "Genesis"; label: string },
        { id: "GMC"; label: string },
        { id: "Honda"; label: string },
        { id: "HUMMER"; label: string },
        { id: "Hyundai"; label: string },
        { id: "INFINITI"; label: string },
        { id: "Isuzu"; label: string },
        { id: "Jaguar"; label: string },
        { id: "Jeep"; label: string },
        { id: "Kia"; label: string },
        { id: "Lamborghini"; label: string },
        { id: "Land Rover"; label: string },
        { id: "Lexus"; label: string },
        { id: "Lincoln"; label: string },
        { id: "Maserati"; label: string },
        { id: "MAZDA"; label: string },
        { id: "Mercedes-Benz"; label: string },
        { id: "Mercury"; label: string },
        { id: "MINI"; label: string },
        { id: "Mitsubishi"; label: string },
        { id: "NISSAN"; label: string },
        { id: "Porsche"; label: string },
        { id: "RAM"; label: string },
        { id: "Rolls-Royce"; label: string },
        { id: "Saab"; label: string },
        { id: "Saturn"; label: string },
        { id: "Scion"; label: string },
        { id: "smart"; label: string },
        { id: "Subaru"; label: string },
        { id: "Suzuki"; label: string },
        { id: "Tesla"; label: string },
        { id: "Toyota"; label: string },
        { id: "Volkswagen"; label: string },
        { id: "Volvo"; label: string },
      ]
    >
  | ProfilingQuestion<
      "profiling.sparetime",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "teamsports"; label: string },
        { id: "bar"; label: string },
        { id: "socialmedia"; label: string },
        { id: "golf"; label: string },
        { id: "music"; label: string },
        { id: "makingmusic"; label: string },
        { id: "gaming"; label: string },
        { id: "camping"; label: string },
        { id: "crafts"; label: string },
        { id: "astrology"; label: string },
        { id: "volunteer"; label: string },
        { id: "createsocialmedia"; label: string },
        { id: "reading"; label: string },
        { id: "tuning"; label: string },
        { id: "offroad"; label: string },
        { id: "traveling"; label: string },
        { id: "home"; label: string },
        { id: "yoga"; label: string },
        { id: "cooking"; label: string },
        { id: "church"; label: string },
        { id: "biking"; label: string },
        { id: "concert"; label: string },
        { id: "tv"; label: string },
        { id: "movies"; label: string },
        { id: "cardio"; label: string },
        { id: "weights"; label: string },
        { id: "podcast"; label: string },
        { id: "hiking"; label: string },
        { id: "watchsports"; label: string },
        { id: "photography"; label: string },
        { id: "none"; label: string },
      ]
    >
  | ProfilingQuestion<"profiling.hobbybrands", "TextQuestion", "required">
  | ProfilingQuestion<
      "profiling.watchproviders",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "tv"; label: string },
        { id: "streaming"; label: string },
        { id: "yt"; label: string },
        { id: "shortform"; label: string },
        { id: "livestream"; label: string },
      ]
    >
  | ProfilingQuestion<
      "profiling.watchtech",
      "MultipleSelectQuestion",
      "required",
      [
        { id: "peloton"; label: string },
        { id: "crypto"; label: string },
        { id: "vr"; label: string },
        { id: "smartwatch"; label: string },
        { id: "smarthome"; label: string },
        { id: "smartspeaker"; label: string },
        { id: "console"; label: string },
        { id: "none"; label: string },
      ]
    >
  // when all other profiling questions have been answered
  | ProfilingQuestion<`profilecategory.completed.${string}`, "TextQuestion">;

const ValidationTypes = [
  "required",
  "require_count",
  "require_all",
  "name",
  "email",
  "yearborn",
  "zip",
] as const;
export type ValidationType = (typeof ValidationTypes)[number];

export type Validation = {
  type: ValidationType;
  message: string;
  min?: number;
  max?: number;
  all?: string[];
  // list of ids for user provided values
  userValues?: string[];
};

export const defaultProfiling: DefaultProfiling = [
  [
    {
      id: "profilecategory.email",
      questions: [
        {
          type: "EmailQuestion",
          id: "email",
          header: { children: "Enter your Email" },
          validation: {
            type: "email",
            message: "Please enter your email",
          },
        },
      ],
    },
  ],
  [
    {
      id: "profilecategory.core",
      questions: [
        {
          type: "NameQuestion",
          id: "name",
          header: { children: "Please enter your name" },
          validation: {
            type: "name",
            message: "Please enter your name",
          },
        },
        {
          type: "PhoneQuestion",
          id: "phone",
          header: { children: "Your Phone" },
          validation: {
            type: "required",
            message: "Please enter your phone",
          },
        },
        {
          type: "ZipQuestion",
          id: "zip",
          validation: {
            type: "zip",
            message: "Please enter your zip code",
          },
        },
      ],
    },
  ],
  [
    {
      id: "profilecategory.demographics",
      questions: [
        {
          type: "YearBorn",
          id: "profiling.yearborn",
          header: {
            children: "What year were you born?",
          },
          validation: {
            type: "yearborn",
            message: "Enter year as YYYY format (e.g. 1987)",
          },
        },
        {
          type: "SingleSelectQuestion",
          id: "profiling.gender",
          header: {
            children: "Are you?",
          },
          options: [
            { id: "female", label: "Female" },
            { id: "male", label: "Male" },
            { id: "nonbinary", label: "Non-binary" },
            { id: "notsay", label: "Prefer not to say" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.musicsource",
          header: {
            children: "How do you most often listen to music (Choose up to 3)?",
          },
          options: [
            { id: "radio", label: "Radio" },
            { id: "vinyl", label: "Vinyl/Records" },
            { id: "tapes", label: "Tapes" },
            { id: "cd", label: "CDs" },
            { id: "amazon", label: "Amazon Music Unlimited" },
            { id: "apple", label: "Apple Music" },
            { id: "pandorafree", label: "Pandora (Free)" },
            { id: "pandorapaid", label: "Pandora (Paid Subscription)" },
            { id: "spotifyfree", label: "Spotify (Free)" },
            { id: "spotifypaid", label: "Spotify (Paid Subscription)" },
            { id: "tidal", label: "Tidal" },
            { id: "yt", label: "YouTube (The Main Website/App)" },
            { id: "ytm", label: "YouTube (Music Streaming Service)" },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "require_count",
            max: 3,
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.socialusage",
          header: {
            children: "Which of these do you use daily?",
          },
          options: [
            { id: "bereal", label: "BeReal" },
            { id: "discord", label: "Discord" },
            { id: "facebook", label: "Facebook" },
            { id: "instagram", label: "Instagram" },
            { id: "snapchat", label: "Snapchat" },
            { id: "tiktok", label: "TikTok" },
            { id: "twitch", label: "Twitch" },
            { id: "twitter", label: "Twitter" },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
      ],
    },
  ],
  [
    {
      id: "profilecategory.fooddrink",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.eaten",
          header: {
            children: "Which of the following have you eaten in the last week?",
          },
          options: [
            {
              id: "casual",
              label:
                "A meal from a casual sit-down restaurant (dine-in or takeout)",
            },
            {
              id: "fine",
              label:
                "A meal from a fine dining restaurant (dine-in or takeout)",
            },
            {
              id: "delivery",
              label: "Restaurant food delivered via a service like UberEats",
            },
            { id: "mealkit", label: "A meal kit/service I bought" },
            {
              id: "fastfood",
              label:
                "A meal from a fast food restaurant (dine-in, drive-through or takeout",
            },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.groceries",
          header: {
            children:
              "When you’re getting groceries which are important to you?",
          },
          options: [
            {
              id: "craving",
              label: "What I’m craving",
            },
            {
              id: "healthy",
              label: "How healthy it is",
            },
            {
              id: "price",
              label: "What's on sale/affordable",
            },
            { id: "local", label: "What is local or organic" },
            { id: "deliver", label: "What can be delivered" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.drinks",
          header: {
            children: "Which of these do you drink on a regular basis?",
          },
          options: [
            {
              id: "dietsoft",
              label: "Diet soft drinks",
            },
            {
              id: "water",
              label: "Bottled/flavored water",
            },
            {
              id: "tea",
              label: "Tea",
            },
            { id: "juice", label: "Fruit or vegetable juice" },
            { id: "sports", label: "Sports drinks" },
            { id: "soft", label: "Soft drinks (non-diet)" },
            { id: "coffee", label: "Coffee/coffee drinks" },
            { id: "energy", label: "Energy drinks" },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.foodpartner",
          header: {
            children:
              "What food/drink brand or restaurant do you think would be a good partner for an upcoming tour?",
          },
          validation: {
            type: "required",
            message: "Please tell us a partner brand",
          },
        },
      ],
    },
    {
      id: "profilecategory.alcohol",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.alcoholchoice",
          header: {
            children:
              "When you’re drinking, which of these do you tend to drink (check all that apply)?",
          },
          options: [
            {
              id: "beer",
              label: "Regular (not light, not craft/import) beer ",
            },
            {
              id: "lightbeer",
              label: "Light beer",
            },
            {
              id: "importedbeer",
              label: "Imported or Craft Beer",
            },
            { id: "wine", label: "Wine" },
            { id: "vodka", label: "Vodka (or Vodka Mixed Drinks)" },
            {
              id: "whiskey",
              label: "Whiskey/Bourbon (or Whiskey/Bourbon Mixed Drinks)",
            },
            { id: "rum", label: "Rum (or Rum Mixed Drinks)" },
            { id: "gin", label: "Gin (or Gin Mixed Drinks)" },
            { id: "tequila", label: "Tequila (or Tequila Mixed Drinks)" },
            { id: "premiumliquor", label: "A premium liquor" },
            { id: "cheapliquor", label: "Budget/cheap liquor" },
            { id: "cider", label: "Hard Cider or Lemonade" },
            { id: "seltzer", label: "Hard Seltzer" },
            { id: "none", label: "I don’t drink alcohol" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          id: "profiling.alcoholpartner",
          type: "TextQuestion",
          header: {
            children:
              "What beer, wine or alcohol brands could you see {artist} partnering with?",
          },
          validation: {
            type: "required",
            message: "Please tell us a partner brand",
          },
        },
      ],
    },
    {
      id: "profilecategory.clothing",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.buyclothing",
          header: {
            children:
              "Which of these do you regularly buy clothes/accessories from?",
          },
          options: [
            {
              id: "discount",
              label: "Discount clothing stores",
            },
            {
              id: "mainstream",
              label: "Mainstream mall clothing stores",
            },
            {
              id: "highend",
              label: "High end fashion stores",
            },
            {
              id: "onlinemainstream",
              label: "The online versions of mainstream mall stores",
            },
            { id: "walmart", label: "A store like WalMart or Target" },
            {
              id: "online",
              label: "Online-only stores",
            },
            {
              id: "subscription",
              label: "Online subscription services",
            },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.artistclothing",
          header: {
            children:
              "What clothing, accessories or cosmetics brands would be a good fit for {artist}? This could be any brands you love or ones you associate with {artist}",
          },
          validation: {
            type: "required",
            message: "Please enter a response",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.beauty",
          header: {
            children:
              "Which of these are true for you in deciding on personal care and beauty products (like cosmetics, fragrances/cologne, teeth whitening, hair styling, shaving)?",
          },
          options: [
            {
              id: "economical",
              label: "I usually look for the most economical option",
            },
            {
              id: "premium",
              label: "I prefer to buy products from premium brands",
            },
            {
              id: "ethics",
              label:
                "The company’s values are an important factor in my decision",
            },
            {
              id: "natural",
              label:
                "I prefer cosmetics/personal care products that are natural or organic",
            },
            {
              id: "subscription",
              label:
                "I use a subscription service (like Dollar Shave Club or Birchbox)",
            },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
      ],
    },
    {
      id: "profilecategory.vehicle",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.nextvehicle",
          header: {
            children: "Which types of vehicle would you consider getting next?",
          },
          options: [
            { id: "electric", label: "Electric" },
            { id: "hybrid", label: "Hybrid" },
            { id: "suv", label: "SUV" },
            { id: "pickup", label: "Pickup Truck" },
            { id: "car", label: "Small Car" },
            { id: "hatchback", label: "Hatchback" },
            { id: "motorcycle", label: "Motorcycle" },
            { id: "none", label: "I don’t drive" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.nextvehiclecost",
          header: {
            children:
              "Which of these are likely to describe your next vehicle?",
          },
          options: [
            { id: "budget", label: "Budget/inexpensive" },
            { id: "highend", label: "Full-Featured/High-End Trim" },
            { id: "used", label: "Used" },
            { id: "new", label: "New" },
            { id: "american", label: "American" },
            { id: "Import", label: "Import" },
            { id: "none", label: "I don’t drive" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          id: "profiling.vehiclepartner",
          type: "Autocomplete",
          options: [
            {
              id: "Acura",
              label: "Acura",
            },
            {
              id: "Alfa",
              label: "Alfa",
            },
            {
              id: "Aston Martin",
              label: "Aston Martin",
            },
            {
              id: "Audi",
              label: "Audi",
            },
            {
              id: "Bentley",
              label: "Bentley",
            },
            {
              id: "BMW",
              label: "BMW",
            },
            {
              id: "Buick",
              label: "Buick",
            },
            {
              id: "Cadillac",
              label: "Cadillac",
            },
            {
              id: "Chevy/Chevrolet",
              label: "Chevy/Chevrolet",
            },
            {
              id: "Chrysler",
              label: "Chrysler",
            },
            {
              id: "Dodge",
              label: "Dodge",
            },
            {
              id: "Ferrari",
              label: "Ferrari",
            },
            {
              id: "FIAT",
              label: "FIAT",
            },
            {
              id: "Ford",
              label: "Ford",
            },
            {
              id: "Genesis",
              label: "Genesis",
            },
            {
              id: "GMC",
              label: "GMC",
            },
            {
              id: "Honda",
              label: "Honda",
            },
            {
              id: "HUMMER",
              label: "HUMMER",
            },
            {
              id: "Hyundai",
              label: "Hyundai",
            },
            {
              id: "INFINITI",
              label: "INFINITI",
            },
            {
              id: "Isuzu",
              label: "Isuzu",
            },
            {
              id: "Jaguar",
              label: "Jaguar",
            },
            {
              id: "Jeep",
              label: "Jeep",
            },
            {
              id: "Kia",
              label: "Kia",
            },
            {
              id: "Lamborghini",
              label: "Lamborghini",
            },
            {
              id: "Land Rover",
              label: "Land Rover",
            },
            {
              id: "Lexus",
              label: "Lexus",
            },
            {
              id: "Lincoln",
              label: "Lincoln",
            },
            {
              id: "Maserati",
              label: "Maserati",
            },
            {
              id: "MAZDA",
              label: "MAZDA",
            },
            {
              id: "Mercedes-Benz",
              label: "Mercedes-Benz",
            },
            {
              id: "Mercury",
              label: "Mercury",
            },
            {
              id: "MINI",
              label: "MINI",
            },
            {
              id: "Mitsubishi",
              label: "Mitsubishi",
            },
            {
              id: "NISSAN",
              label: "NISSAN",
            },
            {
              id: "Porsche",
              label: "Porsche",
            },
            {
              id: "RAM",
              label: "RAM",
            },
            {
              id: "Rolls-Royce",
              label: "Rolls-Royce",
            },
            {
              id: "Saab",
              label: "Saab",
            },
            {
              id: "Saturn",
              label: "Saturn",
            },
            {
              id: "Scion",
              label: "Scion",
            },
            {
              id: "smart",
              label: "smart",
            },
            {
              id: "Subaru",
              label: "Subaru",
            },
            {
              id: "Suzuki",
              label: "Suzuki",
            },
            {
              id: "Tesla",
              label: "Tesla",
            },
            {
              id: "Toyota",
              label: "Toyota",
            },
            {
              id: "Volkswagen",
              label: "Volkswagen",
            },
            {
              id: "Volvo",
              label: "Volvo",
            },
          ],
          header: {
            children: "What car brand would make a good tour partner?",
          },
          validation: {
            type: "required",
            message: "Please tell us a partner brand",
          },
        },
      ],
    },
    {
      id: "profilecategory.entertainment",
      questions: [
        {
          type: "MultipleSelectQuestion",
          id: "profiling.sparetime",
          header: {
            children:
              "Which of these are things you like to do in your spare time?",
          },
          options: [
            {
              id: "teamsports",
              label: "Playing team sports (basketball/soccer/softball, etc.)",
            },
            { id: "bar", label: "Going to a bar" },
            { id: "socialmedia", label: "Checking social media" },
            { id: "golf", label: "Golf" },
            { id: "music", label: "Listening to music" },
            {
              id: "makingmusic",
              label:
                "Playing or creating music (guitar, piano, sequencing, etc.)",
            },
            { id: "gaming", label: "Gaming (console or computer)" },
            { id: "camping", label: "Camping or Fishing" },
            { id: "crafts", label: "Crafts/Sewing/crocheting" },
            { id: "astrology", label: "Astrology" },
            { id: "volunteer", label: "Volunteer/charity work" },
            { id: "createsocialmedia", label: "Creating social media content" },
            { id: "reading", label: "Reading" },
            { id: "tuning", label: "Work on my car/car tuning" },
            { id: "offroad", label: "Off-roading/overlanding" },
            { id: "traveling", label: "Traveling" },
            { id: "home", label: "Home improvement" },
            { id: "yoga", label: "Yoga" },
            { id: "cooking", label: "Cooking/baking" },
            { id: "church", label: "Church/church activities" },
            { id: "biking", label: "Biking" },
            { id: "concert", label: "Going to a concert" },
            { id: "tv", label: "Watching TV/movies/streaming content at home" },
            { id: "movies", label: "Going to the movies" },
            { id: "cardio", label: "Running/doing cardio workouts" },
            { id: "weights", label: "Lifting weights" },
            { id: "podcast", label: "Listen to podcasts" },
            { id: "hiking", label: "Hiking" },
            { id: "watchsports", label: "Watch sports" },
            { id: "photography", label: "Photography" },
            { id: "none", label: "None of the above" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "TextQuestion",
          id: "profiling.hobbybrands",
          header: {
            children:
              "What brands related to your hobbies do you think would be a good sponsor for {artist}? Please share any favorite stores or brands that you think would be a fit!",
          },
          validation: {
            type: "required",
            message: "Please provide a response",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.watchproviders",
          header: {
            children:
              "You sit down to watch something, which are you likely to be watching?",
          },
          options: [
            { id: "tv", label: "Traditional TV/cable" },
            { id: "streaming", label: "Streaming Services" },
            { id: "yt", label: "YouTube" },
            { id: "shortform", label: "Short Form Video" },
            { id: "livestream", label: "Twitch or live video platform" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
        {
          type: "MultipleSelectQuestion",
          id: "profiling.watchtech",
          header: {
            children: "Which of the following do you have?",
          },
          options: [
            { id: "peloton", label: "A piece of “smart” gym equipment" },
            { id: "crypto", label: "A crypto wallet" },
            { id: "vr", label: "A VR headset" },
            { id: "smartwatch", label: "“Wearable” Tech" },
            { id: "smarthome", label: "“Smart Home” system" },
            { id: "smartspeaker", label: "Smart Speaker" },
            { id: "console", label: "Newest generation console" },
            { id: "none", label: "None of these" },
          ],
          validation: {
            type: "required",
            message: "Please select at least one item",
          },
        },
      ],
    },
  ],
];
