import { AutocompleteModule } from "./Autocomplete";
import { CardModule } from "./Card";
import { ContainerModule } from "./Container";
import { DropdownModule } from "./Dropdown";
import { HTMLModule } from "./Html";
import { ImageModule } from "./Image";
import { ImageQuestionModule } from "./ImageQuestion";
import { MultiSelectQuestionModule } from "./MultiSelectQuestion";
import { PageModule } from "./Page";
import { ProfilingModule } from "./Profiling";
import { ProgressModule } from "./Progress";
import { RankedQuestionModule } from "./RankedQuestion";
import { SingleSelectQuestionModule } from "./SingleSelectQuestion";
import { SocialMediaModule } from "./SocialMedia";
import { SongsQuestionModule } from "./SongsQuestion";
import { SubmitModule } from "./Submit";
import { TextModule } from "./Text";
import { TextQuestionModule } from "./TextQuestion";

import { builderToClass } from "./resolve";

type SurveyQuestionModule =
  | AutocompleteModule
  | CardModule
  | ContainerModule
  | DropdownModule
  | HTMLModule
  | ImageModule
  | ImageQuestionModule
  | MultiSelectQuestionModule
  | PageModule
  | ProfilingModule
  | ProgressModule
  | RankedQuestionModule
  | SingleSelectQuestionModule
  | SocialMediaModule
  | SongsQuestionModule
  | SubmitModule
  | TextModule
  | TextQuestionModule;

export {
  AutocompleteModule,
  CardModule,
  ContainerModule,
  DropdownModule,
  HTMLModule,
  ImageModule,
  ImageQuestionModule,
  MultiSelectQuestionModule,
  PageModule,
  ProfilingModule,
  ProgressModule,
  RankedQuestionModule,
  SingleSelectQuestionModule,
  SocialMediaModule,
  SongsQuestionModule,
  SubmitModule,
  SurveyQuestionModule,
  TextModule,
  TextQuestionModule,
  builderToClass,
};
