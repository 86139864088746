import styled from "styled-components";
import { Avatar } from "../Avatar";
import { Dropdown } from "../Dropdown";
import { SvgCheck } from "../Svgs/Check";
import { SvgDropdown } from "../Svgs/Dropdown";
import { ListItem } from "../ListItem";
import { Body1 } from "../Text";
import { Country } from "./countryList";

const Wrapper = styled.div`
  width: 140px;
  border: 1px solid #cccccc;
  border-right: none;
  border-radius: 6px 0 0 6px;
  padding: 10px 12px;
  background: #fff;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  selected?: Country;
  options: Country[];
  onChange: (country: Country) => void;
  onOpen: (v: boolean) => void;
  isOpen?: boolean;
};

export const CountrySelect = ({
  selected,
  options,
  onChange,
  onOpen,
  isOpen,
}: Props) => {
  const dropdownUI = (
    <Dropdown
      style={{ width: "280px", maxHeight: "250px", marginTop: "-22px" }}
      isOpen={isOpen}
      onClose={() => onOpen(false)}
    >
      {options.map((option, index) => (
        <div key={index}>
          <Option
            {...{ onChange, onOpen, ...option }}
            isSelected={selected?.value === option.value}
            value={option}
          />
        </div>
      ))}
    </Dropdown>
  );

  return (
    <>
      {dropdownUI}
      <Wrapper onClick={() => onOpen(true)}>
        <div style={{ display: "flex" }}>
          <Avatar
            style={{ width: "20px", height: "20px" }}
            imgUrl={options.find((c) => c.value === selected?.value)?.image}
          />
          <Body1 style={{ marginLeft: "10px" }}>{selected?.value}</Body1>
        </div>
        <SvgDropdown />
      </Wrapper>
    </>
  );
};

type OptionProps = {
  image: string;
  isSelected?: boolean;
  value: Country;
  onChange: any;
  onOpen: (v: boolean) => void;
};
const Option = ({
  image,
  isSelected,
  value,
  onChange,
  onOpen,
}: OptionProps) => {
  return (
    <ListItem
      leftIcon={
        <>
          {isSelected ? (
            <SvgCheck />
          ) : (
            <Avatar style={{ width: "20px", height: "20px" }} imgUrl={image} />
          )}
        </>
      }
      onClick={() => {
        onChange(value);
        onOpen(false);
      }}
    >
      {value.text}
    </ListItem>
  );
};
