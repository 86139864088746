import {
  ActionProvider,
  DataProvider,
  EventConfigProvider,
} from "../../Components";
import { Templates } from "./Components/Templates";
import { SharingMeta } from "./Components/SharingMeta";

export const Event = () => {
  return (
    <EventConfigProvider>
      <DataProvider>
        <SharingMeta />
        <ActionProvider>
          <Templates />
        </ActionProvider>
      </DataProvider>
    </EventConfigProvider>
  );
};
