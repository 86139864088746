import styled from "styled-components";
import {
  FacebookLogo,
  InstagramLogo,
  TikTokLogo,
  SpotifyLogo,
  YouTubeLogo,
  ShazamLogo,
  XLogo,
} from "../Images/Logos";
import { useEventConfig, useUserActionTrackingContext } from "../Components";

export const SocialIcons = () => {
  const { track } = useUserActionTrackingContext();
  const { socialLinks } = useEventConfig();
  if (!socialLinks) {
    return null;
  }
  const { facebook, instagram, tiktok, spotify, youtube, twitter, shazam } =
    socialLinks;
  const handleOpen = (service: string, serviceName: string) => {
    track({ event: "click", context: { type: "social", value: serviceName } });
    window.open(service, "_blank", "noopener");
  };
  return (
    <SocialContainer>
      <Container>
        {facebook && (
          <FacebookLogo onClick={() => handleOpen(facebook, "facebook")} />
        )}
        {instagram && (
          <InstagramLogo onClick={() => handleOpen(instagram, "instagram")} />
        )}
        {twitter && <XLogo onClick={() => handleOpen(twitter, "twitter")} />}
        {tiktok && <TikTokLogo onClick={() => handleOpen(tiktok, "tiktok")} />}
        {spotify && (
          <SpotifyLogo onClick={() => handleOpen(spotify, "spotify")} />
        )}
        {youtube && (
          <YouTubeLogo onClick={() => handleOpen(youtube, "youtube")} />
        )}
        {shazam && <ShazamLogo onClick={() => handleOpen(shazam, "shazam")} />}
      </Container>
    </SocialContainer>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;
  flex: 0 0 28px;
  svg {
    width: 28px;
    height: 28px;
    & + svg {
      margin-left: 20px;
    }
  }
`;

const SocialContainer = styled.div`
  margin-bottom: 20px;
  svg {
    width: 33px;
    height: 33px;
  }
`;
