import { INVALID_MODULE, SelectModule, SelectModuleProps } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import { addRequiredCountValidation, generateUUID } from "./util";
import {
  BuilderConfigOption,
  SongsQuestionBuilderConfigModule,
} from "../creator";

type SongsQuestionProps = SelectModuleProps & {
  requiredMax?: number;
};

export class SongsQuestionModule extends SelectModule {
  protected _type: ModuleType = "SongsQuestion";
  requiredMax?: number;

  constructor({ requiredMax, ...rest }: SongsQuestionProps) {
    super(rest);

    this.requiredMax = requiredMax;
  }

  validate(): this is Required<SongsQuestionModule> {
    return true;
  }

  toBuilderConfig(): SongsQuestionBuilderConfigModule {
    if (this.validate()) {
      const builderConfig: SongsQuestionBuilderConfigModule = {
        actionRequired: this.actionRequired,
        id: this.id,
        label: this.label,
        // albums
        options: this.options?.map((album) => {
          const albumBase: BuilderConfigOption = {
            id: album.id || generateUUID(),
            label: album.label,
            src: album.src,
            // songs
            options: album.options?.map((song) => {
              const songBase: BuilderConfigOption = {
                id: song.id,
                label: song.label,
                src: song.src,
              };
              if (song.altLabel) {
                songBase.altLabel = song.altLabel;
              }
              return songBase;
            }),
          };
          if (album.altLabel) {
            albumBase.altLabel = album.altLabel;
          }
          return albumBase;
        }),
        type: this.type,
        required: this.required,
      };
      if (this.requiredMax) {
        builderConfig.requiredMax = this.requiredMax;
      }
      return builderConfig;
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    const { label, requiredMax, ...base } = this.toBuilderConfig();
    const surveyConfigModule: SurveyConfigModule = {
      ...base,
      actionRequired: this.actionRequired,
      header: { children: label[this.languageCode] ?? "" },
      options: this.options.map((album) => ({
        id: album.id || generateUUID(),
        src: album.src?.[this.languageCode],
        label:
          album.altLabel?.[this.languageCode] ??
          album.label?.[this.languageCode],
        options: album.options?.map((track) => ({
          id: track.id || generateUUID(),
          src: track.src?.[this.languageCode],
          label:
            track.altLabel?.[this.languageCode] ??
            track.label?.[this.languageCode],
        })),
      })),
    };
    if (requiredMax) {
      surveyConfigModule.validation = addRequiredCountValidation({
        max: requiredMax,
      });
    }
    return surveyConfigModule;
  }

  editProps() {
    return {
      id: this.id,
      options: this.options.map((album) => ({
        id: album.id || generateUUID(),
        label:
          album.altLabel?.[this.languageCode] ??
          album.label?.[this.languageCode],
        src: album.src?.[this.languageCode],
        options: album.options?.map((song) => ({
          id: song.id || generateUUID(),
          label:
            song.altLabel?.[this.languageCode] ??
            song.label?.[this.languageCode],
          src: song.src?.[this.languageCode],
        })),
      })),
      type: this.type,
      question: this.label?.[this.languageCode] ?? "",
      setlist: !!this.requiredMax
        ? { requiredMax: this.requiredMax }
        : undefined,
      actionRequired: this.actionRequired,
    };
  }
}
