import {
  createContext,
  ProviderProps,
  useContext,
  useEffect,
  useState,
} from "react";

export interface PageLoadingContextInterface {
  setLoading: (arg: boolean) => void;
}
const PageLoadingContext = createContext<PageLoadingContextInterface>(
  {} as PageLoadingContextInterface
);

export const PageLoadingProvider = (
  props: JSX.IntrinsicAttributes &
    Omit<ProviderProps<PageLoadingContextInterface>, "value">
) => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (loading) {
      document.querySelector("body")?.classList.add("loading");
    } else {
      document.querySelector("body")?.classList.remove("loading");
    }
  }, [loading]);

  const value = { setLoading };

  return <PageLoadingContext.Provider {...props} value={value} />;
};

export const usePageLoadingContext = () => useContext(PageLoadingContext);
