import { INVALID_MODULE, SelectModule, SelectModuleProps } from "./base";
import { SurveyConfigModule, ModuleType } from "../fanApp";
import { addRequiredValidation, generateUUID } from "./util";
import {
  BuilderConfigOption,
  ImageQuestionBuilderConfigModule,
} from "../creator";

type ImageQuestionModuleProps = SelectModuleProps & {
  showLabels?: boolean;
};
export class ImageQuestionModule extends SelectModule {
  protected _type: ModuleType = "ImageQuestion";
  showLabels: boolean;

  constructor({ showLabels = true, ...rest }: ImageQuestionModuleProps) {
    super(rest);

    this.showLabels = showLabels;
  }

  validate(): this is Required<SelectModule> {
    return true;
  }

  toBuilderConfig(): ImageQuestionBuilderConfigModule {
    if (this.validate()) {
      return {
        actionRequired: this.actionRequired,
        id: this.id,
        options: this.options.map((opt) => {
          const option: BuilderConfigOption = {
            id: opt.id || generateUUID(),
            src: opt.src,
          };
          if (opt.label) {
            option.label = opt.label;
          }
          return option;
        }),
        required: this.required,
        showLabels: this.showLabels,
        label: this.label,
        type: this.type,
        noBorder: this.noBorder,
      };
    }
    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    const { required, label, ...base } = this.toBuilderConfig();
    const surveyConfigModule: SurveyConfigModule = {
      ...base,
      options: this.options.map((opt) => ({
        id: opt.id || generateUUID(),
        label: opt.label?.[this.languageCode],
        src: opt.src?.[this.languageCode],
      })),
      header: { children: label?.[this.languageCode] ?? "" },
    };
    if (required) {
      surveyConfigModule.validation = addRequiredValidation();
    }
    return surveyConfigModule;
  }

  editProps() {
    if (this.validate())
      return {
        id: this.id,
        options: this.options.map((opt) => ({
          id: opt.id || generateUUID(),
          label: opt.label?.[this.languageCode],
          src: opt.src?.[this.languageCode],
        })),
        type: this.type,
        question: this.label[this.languageCode] ?? "",
        actionRequired: this.actionRequired,
      };
  }
}
