import { Fragment, useState } from "react";
import styled from "styled-components";
import { Link } from "../../melodies-source/Link";
import { P } from "../../melodies-source/Text";
import {
  Modal,
  ModalWrapper,
  ModalContainer,
} from "../../melodies-source/Modal";
import { SvgFAQ } from "../../Images/Icons";

export const HelpModal = ({ open, setOpen }: any) => {
  const helpTopics = [
    {
      topicName: "About SET",
      topics: [
        {
          title: "What is SET.Live?",
          content:
            "SET.Live allows fans to engage with their favorite artists in real time. We offer the ability for artists to run unique digital experiences, sweepstakes, real time voting or just a place for fans to tell artists they made it to the show.",
        },
      ],
    },

    {
      topicName: "Finding Your Show",
      topics: [
        {
          title: "Why is the site asking to share my location?",
          content:
            "SET.Live uses your location to determine which show you're at. We only use location to let the artist know that you were at the show when you check in or vote, but otherwise, your location is not used.",
        },

        {
          title:
            "Is there another way to find my show other than sharing my location?",
          content:
            "Yes - search for the artist you're seeing or the venue you're at in the search bar.",
        },

        {
          title:
            "I'm hitting the 'Use my Location' button, but nothing is happening.",
          content:
            "If you have location services turned completely for your browser, you may not receive a prompt. To change this go to the location services menu in your phone's settings.",
        },

        {
          title: "I got directed to a show I’m not at. What do I do?",
          content:
            "Hit the “Exit Show” button and you’ll be able to search for the correct show.",
        },
      ],
    },
    {
      topicName: "About the Show Experience",
      topics: [
        {
          title: "How will I know if I won a sweepstakes?",
          content:
            "Each artist runs their own sweepstakes, but the SET software enables them to text winners directly to let them know they won if the artist chooses to use that feature. The show page itself will not update with the status of the sweepstakes.",
        },

        {
          title:
            "Where can I pick up merch I purchased from a link found on SET.Live?",
          content:
            "Merch featured on SET is generally from the artist's official merch store or official tour merch store. You can find their shipping and delivery rules on the exact page where you purchased the item.",
        },

        {
          title: "Why are you asking me to share my contact information?",
          content:
            "Artists want to be able to reach their fans directly, and when you register, they're gaining the ability to tell you about their next release, their next tour, or reach out to you about something that matters to them. We empower artists who in the past have been forced to pay social media companies or other third parties to reach their fans.",
        },

        {
          title: "What are you doing with my data?",
          content:
            "Our goal is to help artists communicate directly with their fans. When you register your contact info goes to the artist you've chosen to share it with. If a brand is sponsoring the show and you have opted in to share your contact info with that brand, we will also share it with them. We are *not* a data broker - we help artists speak with the fans and include brand sponsors in live shows to help artists with the expenses of touring. We have all the details in our privacy policy.",
        },
      ],
    },
    {
      topicName: "Artists & Brands",
      topics: [
        {
          title:
            "I’m an artist trying to see my artist view, but I keep getting directed to the fan view. How do I login?",
          content:
            "Hit “Exit Show” and click the “Artist Login” button at the bottom of the homepage or click this link. ",
        },
        {
          title: "I'm an artist - how do I use SET.Live at my show? ",
          content: "We'd love to get you on board, contact us here ",
        },
        {
          title: "I work for a brand that would love to sponsor a show.",
          content:
            "We'd be happy to help you be a part of an artist's live experience. Contact us here, and we'll help you find an artist that could be a good fit.",
        },
      ],
    },
  ];

  let helpReady = true;
  const [clicked, setClicked] = useState(-1);
  const [clickedTopic, setClickedTopic] = useState(-1);

  const toggle = (index: number, topic: number) => {
    if (clicked === index) {
      return setClicked(-1);
    }
    setClicked(index);
    setClickedTopic(topic);
  };
  return (
    <ModalStyle isOpen={open} onClose={() => setOpen(false)}>
      <SvgFAQ />
      {helpReady ? (
        <HelpTopics>
          {helpTopics ? (
            <Accordion>
              {helpTopics.map((item, mapIndex) => {
                return (
                  <Fragment key={`topic-${mapIndex}`}>
                    <P>{item?.topicName}</P>
                    {item.topics.map((topic, index) => {
                      return (
                        <Fragment key={`subtopic-${mapIndex}`}>
                          <Wrap
                            onClick={() => toggle(index, mapIndex)}
                            key={index}
                          >
                            <SubHeading
                              title={
                                clicked === index && clickedTopic === mapIndex
                                  ? true
                                  : false
                              }
                            >
                              {topic?.title}
                            </SubHeading>
                            <span>
                              {clicked === index && clickedTopic === mapIndex
                                ? "—"
                                : "+"}
                            </span>
                          </Wrap>
                          {clicked === index && clickedTopic === mapIndex ? (
                            <Dropdown>
                              <Body>{topic?.content}</Body>
                            </Dropdown>
                          ) : null}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              })}
            </Accordion>
          ) : (
            <NotFound>
              We were unable to locate any matches for your search criteria.
              Please try a new search or check out our
              <Link
                style={{ display: "inline-block" }}
                onClick={() => {
                  //  setHelpFilter("");
                  setOpen(false);
                }}
              >
                Frequently Asked Questions
              </Link>{" "}
              for more help.
            </NotFound>
          )}
        </HelpTopics>
      ) : (
        <div style={{ marginTop: "40px", textAlign: "center" }}>
          <P>Loading Help...</P>
        </div>
      )}
      <Block />
    </ModalStyle>
  );
};

const ModalStyle = styled(Modal)`
  position: absolute;
  top: 30px;
  right: 30px;
  padding-right: 10px;
  padding-bottom: 0;
  margin: 5.521vw 0;
  min-height: 100vh;
  border-radius: 10px;
  padding-left: 1em;

  ${ModalWrapper} {
    position: relative;
    background: #161c23;
    width: 90vw !important;
    max-width: 600px;
    padding: 20px 5px 20px 25px;
    overflow: hidden;
  }
  ${ModalContainer} {
    margin-top: 6vh;
  }
  svg {
    margin: 0 0 -10px -3px;
  }
`;
const Accordion = styled.div`
  ${P} {
    color: #838a92;
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 5px;
    margin: 20px 0 0px;
    border-bottom: 0.5px solid #2f343a;
  }
`;

const NotFound = styled.div`
  margin-top: 12px;
  color: #596266;
  font-size: 17px;
  line-height: 27px;
`;

const HelpTopics = styled.div`
  height: 90vh;
  max-height: 97%;
  overflow-y: scroll;
  margin-bottom: 40px;
  padding-bottom: 240px;
  display: block;
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: #ffffff87;
  ::-webkit-scrollbar {
    width: 2px;
    height: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #ffffff81;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;
const Wrap = styled.div`
  background: #161c23;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  h1 {
    padding: 2rem;
    font-size: 14px;
  }
  span {
    font-size: 20px;
    color: #fff;
    margin: 0 15px 0 0;
  }
`;

const Dropdown = styled.div`
  background: #161c23;
  color: #fff;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-right: 45px;

  p {
    font-size: 12px;
    font-weight: 300;
    width: 100%;
    line-height: 140%;
  }
  a {
    color: #007079;
  }
`;
const SubHeading = styled.h4<{ title?: any }>`
  font-size: 15px;
  font-weight: 400;
  line-height: 140%;
  color: #fff;
  border-bottom: 0.5px solid #2f343a;
  width: 100%;
  margin: 0px -10px 5px 0;
  padding: 15px 0;
  @media (max-width: 950px) {
    padding-right: 40px;
  }
  ${(props) => props.title && "border-bottom: none; "}
`;

const Body = styled.p`
  font-size: min(1.5vw, 12px);
  color: #fff;
  margin-bottom: 5px;
  margin-top: -5px;
  padding-bottom: 15px;
  border-bottom: 0.5px solid #2f343a;
`;
const Block = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #161c23;
  height: 25px;
`;
