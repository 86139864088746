import { INVALID_MODULE, SelectModule, SelectModuleProps } from "./base";
import { SurveyConfigModule, ModuleType, SurveyConfigOption } from "../fanApp";
import { addRequiredCountValidation, generateUUID } from "./util";
import {
  BuilderConfigOption,
  MultiSelectBuilderConfigModule,
} from "../creator";

type MultiSelectQuestionProps = SelectModuleProps & {
  requiredMin?: number;
  requiredMax?: number;
};

export class MultiSelectQuestionModule extends SelectModule {
  protected _type: ModuleType = "MultipleSelectQuestion";
  private _requiredMin?: number;
  private _requiredMax?: number;

  constructor({ requiredMin, requiredMax, ...rest }: MultiSelectQuestionProps) {
    super(rest);
    this._requiredMin = requiredMin;
    this._requiredMax = requiredMax;
  }

  get requiredMin(): number | undefined {
    return this._requiredMin;
  }

  set requiredMin(requiredMin) {
    if (typeof requiredMin !== "number" || requiredMin <= 0) {
      throw new Error("requiredMin must be greater than 0");
    }

    this._requiredMin = requiredMin;
  }

  get requiredMax(): number | undefined {
    return this._requiredMax;
  }

  set requiredMax(requiredMax) {
    if (typeof requiredMax !== "number" || requiredMax <= 0) {
      throw new Error("requiredMax must be greater than 0");
    }

    this._requiredMax = requiredMax;
  }

  validate(): this is Required<MultiSelectQuestionModule> {
    return true;
  }

  toBuilderConfig(): MultiSelectBuilderConfigModule {
    if (this.validate()) {
      const userValues: string[] = [];

      const { requiredMax, requiredMin, ...base } = buildBaseModule(
        this,
        userValues,
      );
      const builderConfigModule: MultiSelectBuilderConfigModule = {
        ...base,
        actionRequired: this.actionRequired,
      };

      if (typeof requiredMin === "number" && requiredMin >= 0) {
        builderConfigModule.requiredMin = this.requiredMin;
      }
      if (typeof requiredMax === "number" && requiredMax >= 0) {
        builderConfigModule.requiredMax = this.requiredMax;
      }

      return builderConfigModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  toSurveyConfig(): SurveyConfigModule {
    if (this.validate()) {
      const userValues: string[] = [];

      const { requiredMin, requiredMax, label, ...base } = buildBaseModule(
        this,
        userValues,
      );

      const surveyConfigModule: SurveyConfigModule = {
        ...base,
        actionRequired: this.actionRequired,
        header: { children: label[this.languageCode] ?? "" },
        options: this.options.map((opt) => {
          const option: SurveyConfigOption = {
            id: opt.id || generateUUID(),
            label: opt.label?.[this.languageCode],
          };
          if (opt.userDefined) {
            option.userDefined = true;
          }
          return option;
        }),
        noBorder: this.noBorder,
      };

      if (typeof requiredMin === "number" || typeof requiredMax === "number") {
        surveyConfigModule.validation = addRequiredCountValidation({
          min: this.requiredMin,
          max: this.requiredMax,
        });
      }

      if (userValues.length && surveyConfigModule.validation) {
        surveyConfigModule.validation.userValues = userValues;
      }

      return surveyConfigModule;
    }

    throw new Error(INVALID_MODULE(this));
  }

  editProps() {
    return {
      id: this.id,
      options: this.options.map((opt) => ({
        id: opt.id || generateUUID(),
        label: opt.label?.[this.languageCode],
        userDefined: opt.userDefined,
      })),
      type: this.type,
      question: this.label[this.languageCode] ?? "",
      actionRequired: this.actionRequired,
    };
  }
}

const buildBaseModule = (
  mod: MultiSelectQuestionModule,
  userValues: string[],
) => {
  const baseModule: MultiSelectBuilderConfigModule = {
    id: mod.id,
    label: mod.label,
    options: mod.options.map((opt) => {
      const option: BuilderConfigOption = {
        id: opt.id || generateUUID(),
        label: opt.label,
      };
      if (opt.userDefined) {
        userValues.push(opt.id || generateUUID());
        option.userDefined = true;
      }
      return option;
    }),
    requiredMax: mod.requiredMax,
    requiredMin: mod.requiredMin,
    type: mod.type,
  };

  if (mod.randomizeOptions) {
    baseModule.randomizeOptions = true;
  }

  return baseModule;
};
