import { CardType, EventConfig, UserEventStates } from "@max/common";
import { Timestamp } from "firebase/firestore";

const demoCheckInCard: CardType = {
  visible: {
    states: [UserEventStates.draft],
  },
  tab: "Check In",
  headline: "Want a chance to win some signed merch?",
  subtitle: "Heyyoo",
  icon: "user",
  body: "Check in and enter for a chance to win signed merch for Milky Chance",
  cta: "register",
  ctalabel: "Check In",
};

const demoVoteCard: CardType = {
  tab: "Fan Favorite",
  headline: "Vote for our Encore",
  subtitle: "Heyyoo",
  icon: "checklist",
  songs: [
    {
      id: "fFXwYxvL",
      title: "Rerun",
      album: "Honey Revenge",
    },
    { id: "pXPmVyAr", title: "Distracted", album: "Honey Revenge" },
    { id: "wNKj3taS", title: "Miss Me", album: "Honey Revenge" },
    { id: "3NKjrt4S", title: "Song Me", album: "Honey Revenge" },
    { id: "1N55rtaS", title: "Another Me", album: "Honey Revenge" },
    { id: "wNKj3taS", title: "Miss Me", album: "Honey Revenge" },
    { id: "3NKjrt4S", title: "Song Me", album: "Honey Revenge" },
    { id: "1N55rtaS", title: "Another Me", album: "Honey Revenge" },
  ],
};

const demoCheckInCompleteCard: CardType = {
  visible: {
    states: [UserEventStates.draft],
    hide: true,
  },
  tab: "Check In",
  headline: "Thank You!",
  subtitle: "Heyyoo",
  icon: "checkmark",
  body: "You can save now my recent release",
  cta: "social",
  ctalabel: "Save",
  image: "https://placekitten.com/200/200",
};

const demoInfoCard: CardType = {
  headline: "Info Goes Here",
  image: "https://placekitten.com/200/200",
  body: "Some other cool info",
  cta: "https://google.com",
  ctalabel: "Go go!",
};

const demoPaymentCard: CardType = {
  tab: "Donate",
  headline: "Donate",
  subtitle: "Heyyoo",
  donate: "",
  icon: "donate",
  body: "Donating appreciated securities such as stocks or mutual funds is a smart and easy way to support Young Artists of America.",
  cta: "payment",
  ctalabel: "Donate!",
};

const demoMerchCard: CardType = {
  tab: "Merch",
  headline: "Merch",
  icon: "shirt",
  merch: [
    {
      name: "T-Shirt",
      photo: "https://placekitten.com/200/200",
      url: "https://google.com",
    },
    {
      name: "VIP Shirt",
      photo: "https://placekitten.com/200/280",
      url: "https://google.com",
    },
    {
      name: "Long Name Sweater",
      photo: "https://placekitten.com/200/200",
      url: "https://google.com",
    },
    {
      name: "Fur Gloves",
      photo: "https://placekitten.com/200/200",
      url: "https://google.com",
    },
  ],
};

const demoHtmlCard: CardType = {
  tab: "Booking.com",
  //   html: `<div style="width: 200px;
  //   height: 200px;
  //   background: pink;
  //   flex-shrink: 0;
  //   margin: 40px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   border-radius: 50%;">kewl<span style="
  //   font-family: monospace;
  //   font-size: 20px;
  //   padding: 10px;
  // ">html</span> content</div>`,
  //   html: `<div style="margin:20px;min-width:300px;min-heigth:400px;border-radius:16px;overflow:hidden;background:#ffbb39;padding:20px;display:flex;justify-content:center;"><ins class="bookingaff" data-aid="2316057" data-target_aid="2316057" data-prod="nsb" data-width="250" data-height="200" data-lang="xu" data-df_num_properties="3">
  // <!-- Anything inside will go away once widget is loaded. -->
  //     <a href="//www.booking.com?aid=2316057">Booking.com</a>
  // </ins></div>`,
  //   script: `(function(d, sc, u) {
  //   var s = d.createElement(sc), p = d.getElementsByTagName(sc)[0];
  //   s.type = 'text/javascript';
  //   s.async = true;
  //   s.src = u + '?v=' + (+new Date());
  //   p.parentNode.insertBefore(s,p);
  //   console.log(d,sc,u);
  //   })(document, 'script', '//cf.bstatic.com/static/affiliate_base/js/flexiproduct.js');`,
  html: `<div style="min-width:300px;width:300px;margin-left:20px;margin-right:20px;background:var(--template-primary);padding:10px;border-radius:16px;"><div style="min-height:350px;" id="bookingAffiliateWidget_489700b9-51dc-48b7-94f6-b8511f79893c">&nbsp;</div></div>`,
  script: `(function() {
    if(!window.maxcustomloading1) {
      window.maxcustomloading1 = true;
      var script = document.createElement('script');
      script.src = 'https://www.booking.com/affiliate/prelanding_sdk';
      script.onload = function() {
        var BookingAffiliateWidget = new Booking.AffiliateWidget({
        "iframeSettings": {
          "selector": "bookingAffiliateWidget_489700b9-51dc-48b7-94f6-b8511f79893c",
          "responsive": true
        },
        "widgetSettings": {}
      });
      }
      document.head.appendChild(script);
    }
  })();
  `,
};

export const demoConfig: EventConfig = {
  type: 4,
  colors: {
    dark: "#4D4DB3",
    light: "yellow",
  },
  header: {
    artistName: "Ty Dolla Sign",
    image:
      "https://storage.googleapis.com/set-live-stage.appspot.com/artist-images/ty-dolla-sign-album.jpeg",
    date: Timestamp.now(),
    venue: "The Masquerade",
    location: "Atlanta, GA",
  },
  register: {
    title: "Register Now",
    subtitle: "",
    terms: `I agree to share the above information with <span style="font-weight: 800">Home Free</span> and Set.Live. Entering your information means we can add you to <span style="font-weight: 800">Home Free</span> email and text marketing lists
    <a style="color: #006ACC" onclick="(function(e){
      const event = new Event('setfan-show-terms', {bubbles: true});
      e.target.dispatchEvent(event);
      e.stopPropagation();
      return false;
    })(arguments[0]);return false;">Privacy Policy</a>`,
    customCheckbox: "mi special termz",
    ctalabel: "Vote",
    customTerms: "You best agree now!",
    customAgreement: "You really better be serious!",
    optionalPhone: false,
    hasSweepstakes: true,
  },
  social: {
    title: "You can now save my album on these platforms",
    subtitle: "",
    spotify: "https://",
    apple: "https://",
    amazon: "https://",
    deezer: "https://",
    youtube: "https://",
  },
  socialLinks: {
    facebook: "https://www.facebook.com/",
    instagram: "https://www.instagram.com/",
    tiktok: "https://www.tiktok.com/",
    spotify: "https://www.spotify.com/",
    youtube: "https://www.youtube.com/",
    twitter: "https://www.twitter.com/",
  },
  cards: [
    demoHtmlCard,
    demoCheckInCard,
    demoCheckInCompleteCard,
    demoVoteCard,
    demoPaymentCard,
    demoMerchCard,
  ],
};
