import { useEffect } from "react";

export const useScript = (script: string | undefined) => {
  useEffect(() => {
    let node: HTMLScriptElement;
    if (script) {
      const scriptEl = document.createElement("script");
      scriptEl.textContent = script;
      node = document.head.appendChild(scriptEl);
    }
    return () => {
      node?.remove();
    };
  }, [script]);
};
