import { useUserActionTrackingContext } from "../../../../../Components";
import styled, { css } from "styled-components";
import { SvgOpen } from "../../../../../melodies-source/Svgs/Open";
import { useState } from "react";
import { SvgCheckCircle } from "../../../../../melodies-source/Svgs/CheckCircle";
import { Merch as MerchType } from "@max/common";

export const Merch = ({ merch }: { merch: MerchType[] }) => {
  const [index, setIndex] = useState(0);
  const currMerchItem = merch[index];
  return (
    <Container>
      <MerchItem {...currMerchItem} />
      <Thumbnails>
        {merch.map((m, i) => (
          <ThumbnailContainer
            onClick={() => setIndex(i)}
            selected={index === i}
            key={i}
          >
            {index === i && <CheckMark />}
            <MerchThumbnail src={m.photo} />
          </ThumbnailContainer>
        ))}
      </Thumbnails>
    </Container>
  );
};

const CheckMark = styled(SvgCheckCircle)`
  position: absolute;
  color: #ffffff;
  z-index: 4;
  width: 28px;
  height: 28px;
`;

const Thumbnails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 8px;
  width: 100%;
  flex: 0 0 auto;
`;

const MerchThumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
`;

const ThumbnailContainer = styled.div<{ selected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  aspect-ratio: 1/1;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  overflow: hidden;
  ${(p) =>
    p.selected &&
    css`
      border: 2px solid #ffffff;
      ${MerchThumbnail} {
        opacity: 0.3;
      }
    `};
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: 100%;
  margin-top: 8px;
`;

const MerchItem = ({ name, photo, url }: MerchType) => {
  const { track } = useUserActionTrackingContext();
  return (
    <Item
      onClick={() => {
        track({
          event: "click",
          context: { type: "merch", value: name || "Unknown" },
        });
        window.open(url);
      }}
      url={photo}
    >
      <Name>
        {name}
        <SvgOpen />
      </Name>
    </Item>
  );
};
const Item = styled.div<{ url?: string }>`
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  aspect-ratio: 1/1;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  background-color: #ffffff;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 75%,
      rgba(0, 0, 0, 0.8) 100%
    ),
    url(${({ url }) => url});
`;

const Name = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  padding: 0 8px 6px 8px;
  color: var(--template-text);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  font-family: var(--template-font-family);
  svg {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
  }
`;
