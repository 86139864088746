import React from "react";
import { Helmet } from "react-helmet-async";
import { useEventConfig } from "../../../Components";

export const SharingMeta: React.FC = () => {
  const { eventId, header } = useEventConfig();

  if (!eventId) return null;

  const title = `${header.artistName} @ ${header.venue}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:description"
        content={`Join ${header.artistName} on Set.Live!`}
      />
      <meta property="og:image" content={header.image} />
    </Helmet>
  );
};
