import React from "react";
import styled, { css } from "styled-components";

const ButtonTypes = [
  "primary",
  "secondaryPrimary",
  "secondary",
  "tertiary",
  "destructive",
  "branded",
] as const;
export type ButtonType = typeof ButtonTypes[number];

const isDisabledCss = css`
  border: none;
  color: ${(p) => p.theme.colors.black40};
  background: ${(props) => props.theme.colors.gray3};
  pointer-events: none;
`;

type Styles = {
  [K in ButtonType]?: any;
};
const styles: Styles = {
  primary: css`
    background: ${(props) => props.theme.colors.navy};
    color: ${(props) => props.theme.colors.white};
    :hover {
      background: ${(props) => props.theme.colors.navy60};
    }
    ${(p: any) => p.isDisabled && isDisabledCss}
  `,
  secondaryPrimary: css`
    background: ${(props) => props.theme.colors.white};
    border: solid 1px ${(props) => props.theme.colors.navy};
    color: ${(props) => props.theme.colors.navy};
    :hover {
      border: solid 1px transparent;
      background: ${(props) => props.theme.colors.navy60};
      color: ${(p) => p.theme.colors.white};
    }
    ${(p: any) => p.isDisabled && isDisabledCss}
  `,
  secondary: css`
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black80};
    border: solid 1px ${(p) => p.theme.colors.black20};
    :hover {
      border: solid 1px transparent;
    }
    ${(p: any) => p.isDisabled && isDisabledCss}
  `,
  tertiary: css`
    background: transparent;
    color: ${(props) => props.theme.colors.black60};
    border: none;
    ${(p: any) =>
      p.isDisabled &&
      css`
        ${isDisabledCss};
        background: transparent;
        color: ${(p) => p.theme.colors.black40};
      `}
    :hover {
      color: ${(p) => p.theme.colors.black80};
      background: ${(p) => p.theme.colors.gray2};
      box-shadow: none;
    }
  `,
  destructive: css`
    background: ${(p) => p.theme.colors.red};
    color: ${(props) => props.theme.colors.white};
    ${(p: any) => p.isDisabled && isDisabledCss}
  `,
  branded: css`
    background: linear-gradient(45deg, #1b0076, #e71e3d);
    color: ${(props) => props.theme.colors.white};
    border-radius: 22px;
  `,
};

type MaxButtonProps = {
  variant?: ButtonType;
  isDisabled?: boolean;
};
export const MaxButton = styled.button<MaxButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border-radius: 4px;
  border: solid 1px transparent;
  user-select: none;
  padding: 10px 12px;
  :hover {
    cursor: pointer;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  }
  :focus {
    outline: none;
  }

  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;

  ${({ variant }) => styles[variant || "primary"]};
`;

type BtnProps = {
  onClick?: () => void;
  variant?: ButtonType;
  children: React.ReactNode;
  icon?: JSX.Element;
  isDisabled?: boolean;
  type?: "button" | "submit" | "reset";
  style?: any;
};

export const Button = ({
  children,
  onClick = () => {},
  isDisabled = false,
  icon,
  variant = "primary",
  type = "button",
  ...props
}: BtnProps) => {
  return (
    <InnerButton
      type={type}
      tabIndex={0}
      onKeyDown={({ code }) => {
        if (code === "Space" || (code === "Enter" && onClick)) onClick();
      }}
      {...{ variant, isDisabled, onClick, ...props }}
    >
      {icon && <Inner>{icon}</Inner>}
      {children}
    </InnerButton>
  );
};

const InnerButton = styled(MaxButton)`
  width: 100%;
  text-align: center;
`;

const Inner = styled.div`
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
