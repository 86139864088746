import * as React from "react";
import { SVGProps } from "react";
export const SvgTicket = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18,6 L18,8.26760632 C17.4021661,8.61342606 17,9.25974756 17,10 C17,10.7402524 17.4021661,11.3865739 18,11.7323937 L18,14 L2,14 L2.00033215,11.7322434 C2.59795034,11.3863868 3,10.7401452 3,10 C3,9.25985476 2.59795034,8.61361324 2.00033215,8.26775657 L2,6 L18,6 Z M13,8 L12,8 L12,7 L2.999,7 L3,7.765 L3.12333736,7.88039277 C3.62827441,8.38610897 3.94413367,9.06339855 3.99327037,9.79824574 L4,10 C4,10.8120399 3.67417778,11.5679168 3.12333736,12.1196072 L3,12.234 L2.999,13 L12,13 L12,12 L13,12 L13,13 L17,13 L17,12.235 L16.8769638,12.1198786 C16.3718571,11.6141491 16.0558861,10.9367659 16.006732,10.2017901 L16,10 C16,9.18781334 16.3259383,8.43182627 16.8769638,7.8801214 L17,7.764 L17,7 L13,7 L13,8 Z M13,9 L13,11 L12,11 L12,9 L13,9 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
