import styled from "styled-components";
import { Link } from "../../../../melodies-source/Link";
import { LogoDark, useEventConfig } from "../../../../Components";
import { HelpIconModal } from "../../../../Components/HelpModal";
import { useTranslation } from "react-i18next";

export const Menu = () => {
  const { handleNavigateHome } = useEventConfig();
  const { t } = useTranslation();
  return (
    <Wrapper style={{ padding: "0 20px" }}>
      <LogoDark />
      <Actions>
        <Link onClick={() => handleNavigateHome()}>{t("Exit Show")}</Link>
        <HelpIconModal style={{ color: "#000000" }} />
      </Actions>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: min-content;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Actions = styled(Wrapper)`
  justify-content: flex-end;
  ${Link} {
    color: #000000;
    font-size: 13px;
    font-weight: 600;
    margin-right: 12px;
  }
`;
