import { useUserActionTrackingContext } from "../../../../../Components";
import styled from "styled-components";
import { SvgOpen } from "../../../../../melodies-source/Svgs/Open";
import { Merch as MerchType } from "@max/common";

export const Merch = ({ merch }: { merch: MerchType[] }) => (
  <Container>
    {merch.map((m) => (
      <MerchItem {...m} />
    ))}
  </Container>
);
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  margin-left: 10px;
  height: 310px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent;
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

const MerchItem = ({ name, photo, url }: MerchType) => {
  const { track } = useUserActionTrackingContext();
  return (
    <Item
      onClick={() => {
        track({
          event: "click",
          context: { type: "merch", value: name || "Unknown" },
        });
        window.open(url);
      }}
    >
      <Photo src={photo} />
      <Product>
        <Name>{name}</Name>
        <SvgOpen />
      </Product>
    </Item>
  );
};

const Item = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 80px;
  margin: 0 15px 15px 0;
  background-size: cover;
`;

const Photo = styled.img`
  width: 80px;
  border-radius: 0px;
  object-fit: cover;
  object-position: center;
  background-color: #f2f5f7;
`;

const Name = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  padding: 0 13px;
  width: 100%;
  border-radius: 0px;
  font-family: var(--template-font-family);
`;
const Product = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: var(--template-primary);

  svg {
    margin: auto 15px;
  }
`;
