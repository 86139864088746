import {
  BuilderConfigModule,
  ImageBuilderConfigModule,
  RankedBuilderConfigModule,
  TextBuilderConfigModule,
} from "../creator";
import { AutocompleteModule } from "./Autocomplete";
import { DropdownModule } from "./Dropdown";
import { HTMLModule } from "./Html";
import { ImageModule } from "./Image";
import { ImageQuestionModule } from "./ImageQuestion";
import { MultiSelectQuestionModule } from "./MultiSelectQuestion";
import { RankedQuestionModule } from "./RankedQuestion";
import { SingleSelectQuestionModule } from "./SingleSelectQuestion";
import { SongsQuestionModule } from "./SongsQuestion";
import { TextModule } from "./Text";
import { TextQuestionModule } from "./TextQuestion";
import { SurveyModule } from "./base";

export function builderToClass(
  builderModule: BuilderConfigModule,
): SurveyModule {
  switch (builderModule.type) {
    case "Autocomplete":
    case "TypeaheadQuestion":
      return new AutocompleteModule(builderModule);
    case "Dropdown":
      return new DropdownModule(builderModule);
    case "Html":
      return new HTMLModule(builderModule);
    case "image":
      return new ImageModule(
        builderModule as Required<ImageBuilderConfigModule>,
      );
    case "ImageQuestion":
      return new ImageQuestionModule(builderModule);
    case "MultipleSelectQuestion":
      return new MultiSelectQuestionModule(builderModule);
    case "RankedQuestion":
      return new RankedQuestionModule(
        builderModule as Required<RankedBuilderConfigModule>,
      );
    case "SingleSelectQuestion":
      return new SingleSelectQuestionModule(builderModule);
    case "TextQuestion":
      return new TextQuestionModule(builderModule);
    case "text":
      return new TextModule(builderModule as Required<TextBuilderConfigModule>);
    case "SongsQuestion":
      return new SongsQuestionModule(builderModule);
    default:
      throw new Error(
        `unable to convert builder module ${builderModule.type} to class`,
      );
  }
}
